<div class="order">
  <div class="modal-header d-flex">
    <h3 class="text-primary cursorpointer" routerLink="/home"
      style="font-family: Jakob; font-weight: 600; font-size: 20px; position: absolute;">We The Chefs</h3>
    <button (click)="close()" aria-label="Close" class="close" type="button"><span
        aria-hidden="true">&times;</span>
    </button>
</div>
<br>
    <p style="font-size: 15px;" class="card-title">Your account has been registered! Please use this <a
        href="https://www.wethechefs.in/chef" target="_blank">Chef portal</a> to login into system.</p>
    <div class="">
      <br>
      <div style="text-align: center;display: flex;">
        <button type="button" class="btn btn-primary text-uppercase btn-block m-auto d-block w-75" (click)="confirmed()">OK</button>
      </div>
    </div>
  </div>

  <!-- <div class="card m-0">
  <div class="card-header">
    <span style="float: right;" class="ft-x font-medium-5" (click)="close()"></span>
    <h4 class="card-title">We The Chefs</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <p style="font-size: 15px;" class="card-title">Your account has been registered! Please use this <a
        href="https://wethechefs.in/admin" target="_blank">Chef portal</a> to login into system.</p>
      <div class="">
        <br>
        <div style="text-align: center;display: flex;">
          <button type="button" class="btn btn-danger btn-raised mr-1" (click)="confirmed()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div> -->
