import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfirmationModalComponent } from "../modals/confirmation-modal/confirmation-modal.component";
import { EmitEvent, Events, EventService } from "./event.service";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private eventService: EventService
  ) {
  }

  USER_PROFILE = "USER_PROFILE";
  USER_LOGIN = "USER_LOGIN";
  COGNITO_PROFILE = "COGNITO_PROFILE";
  SEND_CONFIRMATION = "SEND_CONFIRMATION";
  SIGNUP_USER_TYPE = "SIGNUP_USER_TYPE";
  LOCAL_CART = "LOCAL_CART";

  SharingData = new Subject();
  SharingAlertData = new Subject();
  SharingTagId = new Subject();
  SharingInvoice = new Subject();

  private tagId: string;

  setTagId(tagId: string) {
    this.tagId = tagId;
    // localStorage.setItem("Tag Id",tagId)
   
  }

  getTagId(): string {
    return this.tagId;
  }
  private localStorageKey = 'tag_Id';

  setChefId(id: string): void {
    localStorage.setItem(this.localStorageKey, id);
  }

  getChefId(): string | null {
    return localStorage.getItem(this.localStorageKey);
  }

  removeChefId(): void {
    localStorage.removeItem(this.localStorageKey);
  }
  printObj(obj) {
    // console.log('obj:-' + JSON.stringify(obj));
  }

  setItem(key, value) {
    window.localStorage?.setItem("WTC_" + key, value);
  }

  setSessionItem(key, value) {
    window.sessionStorage?.setItem(key, value);
  }

  getDateTimeByTime(time): any {
    var d = new Date(time);
    let date = this.properFormatNumber(d.getDate());
    let month = this.properFormatNumber(d.getMonth() + 1);
    let year = d.getFullYear();

    let hour = this.properFormatNumber(d.getHours());
    let min = this.properFormatNumber(d.getMinutes());
    let sec = this.properFormatNumber(d.getSeconds());

    // let finalDate = this.getDayName(day) + ' ' + date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
    // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
    // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min;
    let finalDate = date + '/' + month + '/' + year + ' ' + hour + ':' + min;

    // let finalDate = year + '-' + month + '-' + date;
    return finalDate;
  }
  getOrderDateTimeByTime(time): any {
    var d = new Date(time);
    d.setMinutes(d.getMinutes() -330)
    let date = this.properFormatNumber(d.getDate());
    let month = this.properFormatNumber(d.getMonth() + 1);
    let year = d.getFullYear();

    let hour = this.properFormatNumber(d.getHours());
    let min = this.properFormatNumber(d.getMinutes());
    let sec = this.properFormatNumber(d.getSeconds());

    // let finalDate = this.getDayName(day) + ' ' + date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
    // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
    // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min;
    let finalDate = date + '/' + month + '/' + year + ' ' + hour + ':' + min;

    // let finalDate = year + '-' + month + '-' + date;
    return finalDate;
  }

  getChefOrderPrice(price, commission) {
    return parseFloat((price - (price * (commission / 100))).toFixed(2));
  }

  getImageCDNURL(imageId) {
    return 'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/public';
  }

  getImageCDNURLVariant(imageId,width) {
    return 'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/w='+width;
  }

  getCDNMultipleResolutions(imageId){
    let str=
        'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/w=2160 2160w, '+
        'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/w=1440 1440w, '+
        'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/w=1080 1080w, '+
        'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/w=720 720w, '+
        'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/w=480 480w, '+
        'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/w=360 360w'
    ;
    return str;
  }


  getChefMultipleRes(chef_id,image){
    if(chef_id==76){
      return this.getCDNMultipleResolutions("2924f0a7-1f88-48be-3f01-40da92e18100");
    }else if(chef_id==64){
      return this.getCDNMultipleResolutions("7032eeab-929f-4ef6-d1bf-f16bacc7fd00");
    }else if(chef_id==74){
      return this.getCDNMultipleResolutions("0c1e1f84-8b6a-4600-7a9b-1000d8ce1800");
    }else if(chef_id==53){
      return this.getCDNMultipleResolutions("49eea94d-ffdb-4de4-57c8-25beb209ac00");
    }
  else if(chef_id==31){
    return this.getCDNMultipleResolutions("49eea94d-ffdb-4de4-57c8-25beb209ac00");
  }
  else{
      return image
    }
  }

  getDifferenceInHours(date1: Date, date2: Date) {
    return (date1.getTime() - date2.getTime()) / (1000 * 60 * 60);
  }

  getMonthName(month) {
    switch (month) {
      case '01':
        return 'Jaunurary';
      case '02':
        return 'February';
      case '03':
        return 'March';
      case '04':
        return 'April';
      case '05':
        return 'May';
      case '06':
        return 'June';
      case '07':
        return 'July';
      case '08':
        return 'August';
      case '09':
        return 'September';
      case '10':
        return 'October';
      case '11':
        return 'November';
      case '12':
        return 'December';
    }
    return 'Janurary';
  }

  getLocalCart() {
    if (this?.getItem(this.LOCAL_CART) != null && this?.getItem(this.LOCAL_CART) != undefined && this?.getItem(this.LOCAL_CART) != "") {
      return JSON.parse(this?.getItem(this.LOCAL_CART));
    } else {
      return [];
    }
  }

  // addToLocalCart(modalService,id, name, price, quantity, type) {
  addToLocalCart(modalService, id, name, chef_id, price, type) {
    let item = {
      id: id,
      name: name,
      price: price,
      chef_id: chef_id,
      // quantity: quantity,
      type: type
    }
    // console.log("item:-" + JSON.stringify(item, null, 4));
    let cart = this.getLocalCart();
    if (cart.length > 0) {

      if (item.type == 'event') {
        let cartItemType = cart[0].type;
        if (cartItemType == 'item') {
          const modalRef = modalService.open(ConfirmationModalComponent, { size: '' });
          modalRef.closed.subscribe((result) => {
            if (result.confirm) {
              this.deleteFullLocalCart()
              this.addToLocalCart(modalService, id, name, chef_id, price, type);
            }
          })
          modalRef.componentInstance.title = 'Replace cart item?';
                        modalRef.componentInstance.message = 'Your cart contains dishes from another Chef. Do you want to discard the selection and add new dishes?';
          return;
        } else {
          if (item.id == cart[0].id) {
            let found = false;
            for (let i = 0; i < cart.length; i++) {
              if (cart[i].id == item.id) {
                cart[i]['quantity'] = cart[i]['quantity'] + 1;
                found = true;
              }
            }
            if (!found) {
              item['quantity'] = 1;
              cart.push(item);
            }
          } else {
            const modalRef = modalService.open(ConfirmationModalComponent, { size: '' });
            modalRef.closed.subscribe((result) => {
              if (result.confirm) {
                this.deleteFullLocalCart()
                this.addToLocalCart(modalService, id, name, chef_id, price, type);
              }
            })
            modalRef.componentInstance.title = 'Replace cart item?';
                        modalRef.componentInstance.message = 'Your cart contains dishes from another Chef. Do you want to discard the selection and add new dishes?';
            return;
          }
        }

      } else if (item.type == 'item') {
        let cartItemType = cart[0].type;

        if (cartItemType == 'event') {
          const modalRef = modalService.open(ConfirmationModalComponent, { size: '' });
          modalRef.closed.subscribe((result) => {
            if (result.confirm) {
              this.deleteFullLocalCart()
              this.addToLocalCart(modalService, id, name, chef_id, price, type);
            }
          })
          modalRef.componentInstance.title = 'Replace cart item?';
                        modalRef.componentInstance.message = 'Your cart contains dishes from another Chef. Do you want to discard the selection and add new dishes?';
          return;
        } else {
          if (cart[0].chef_id != chef_id) {
            const modalRef = modalService.open(ConfirmationModalComponent, { size: '' });
            modalRef.closed.subscribe((result) => {
              if (result.confirm) {
                this.deleteFullLocalCart()
                this.addToLocalCart(modalService, id, name, chef_id, price, type);
              }
            })
            modalRef.componentInstance.title = 'Replace cart item?';
                        modalRef.componentInstance.message = 'Your cart contains dishes from another Chef. Do you want to discard the selection and add new dishes?';
            return;
          } else {
            let found = false;
            for (let i = 0; i < cart.length; i++) {
              if (cart[i].id == item.id) {
                cart[i]['quantity'] = cart[i]['quantity'] + 1;
                found = true;
              }
            }
            if (!found) {
              item['quantity'] = 1;
              cart.push(item);
            }
          }
        }
      }
    } else {
      item['quantity'] = 1;
      cart.push(item);
    }

    this?.setItem(this.LOCAL_CART, JSON.stringify(cart));
    this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    this.SharingAlertData.next(chef_id);
    // console.log(this.SharingAlertData,"Deeapk");
    
    // console.log("cart:-" + JSON.stringify(this.getLocalCart(), null, 4));
  }

  deleteFromCart(item) {
    let cart = this.getLocalCart();

    let found = false;

    let removeIndex = -1;

    for (let i = 0; i < cart.length; i++) {
      if (cart[i].id == item.id) {
        if (cart[i]['quantity'] == 1) {
          removeIndex = i;
        } else {
          cart[i]['quantity'] = cart[i]['quantity'] - 1;
          found = true;
        }
      }
    }

    if (removeIndex != -1) {
      cart.splice(removeIndex, 1);
    }
    this?.setItem(this.LOCAL_CART, JSON.stringify(cart));
    this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    // console.log("cart:-" + JSON.stringify(this.getLocalCart(), null, 4));
  }

  removeFromCart(item) {
    let cart = this.getLocalCart();

    let found = false;

    let removeIndex = -1;

    for (let i = 0; i < cart.length; i++) {
      if (cart[i].id == item.id) {
        removeIndex = i;
      }
    }

    if (removeIndex != -1) {
      cart.splice(removeIndex, 1);
    }
    this?.setItem(this.LOCAL_CART, JSON.stringify(cart));
    // console.log("cart:-" + JSON.stringify(this.getLocalCart(), null, 4));
  }

  deleteFullLocalCart() {
    let cart = [];
    this?.setItem(this.LOCAL_CART, JSON.stringify(cart));

    // console.log("cart:-" + JSON.stringify(this.getLocalCart(), null, 4));
  }

  properFormatNumber(number: number): string {
    let numString = '';
    if (number < 10) {
      numString = '0' + number;
    } else {
      numString = number + '';
    }
    return numString;
  }

  tConvert(time) {
    var timeString = time;
    var H = +timeString.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = (H < 12 || H === 24) ? "AM" : "PM";
    timeString = h + timeString.substr(2, 3) + ampm;

    return timeString;
  }

  getUserID() {
    if (this?.getItem(this.USER_PROFILE) != null && this?.getItem(this.USER_PROFILE) != undefined && this?.getItem(this.USER_PROFILE) != '' && this?.getItem(this.USER_PROFILE) != 'null') {
      let profile = JSON.parse(this?.getItem(this.USER_PROFILE));
      return profile.id;
    } else {
      return "";
    }
  }

  getFixed2Charges(charge: number) {
    try {
      let value = charge.toFixed(2);
      return value;
    } catch (e) {
      return charge.toFixed(2);
    }
  }

  getUserProfile() {
    if (this?.getItem(this.USER_PROFILE) != null && this?.getItem(this.USER_PROFILE) != undefined && this?.getItem(this.USER_PROFILE) != '' && this?.getItem(this.USER_PROFILE) != 'null') {
      let profile = JSON.parse(this?.getItem(this.USER_PROFILE));
      return profile;
    } else {
      return null;
    }
  }

  getTimerTimeLeft(millis) {
    // let secs = time / 1000;
    // let timeleft = (secs / 60) + ':' + (secs % 60);
    // return timeleft;
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return ((Number(minutes) < 10) ? '0' : '') + minutes + ':' + ((Number(seconds) < 10) ? '0' : '') + seconds;
  }

  getUserEmail() {
    if (this?.getItem(this.USER_PROFILE) != null && this?.getItem(this.USER_PROFILE) != undefined && this?.getItem(this.USER_PROFILE) != '' && this?.getItem(this.USER_PROFILE) != 'null') {
      let profile = JSON.parse(this?.getItem(this.USER_PROFILE));
      return profile.email;
    } else {
      return "";
    }
  }

  getUserType() {
    if (this?.getItem(this.USER_PROFILE) != null && this?.getItem(this.USER_PROFILE) != undefined && this?.getItem(this.USER_PROFILE) != '' && this?.getItem(this.USER_PROFILE) != 'null') {
      let profile = JSON.parse(this?.getItem(this.USER_PROFILE));
      return profile.type;
    } else {
      return "";
    }
  }

  isUserLoggedIn() {
    if (this?.getItem(this.USER_LOGIN) == '1') {
      return true;
    } else {
      return false;
    }
  }

  clearALLData() {
    window.localStorage.clear();
  }

  getFixedAmount(amount, fixed) {
    if (isNaN(amount)) {
      return amount;
    } else {
      return Number(Number(amount).toFixed(fixed));
    }
  }

  calculateTotal(amount1, amount2) {
    return Number(amount1) + Number(amount2);
  }


  printKeyValuePairs(url: string, postdata) {
    // console.log('url:-' + url);
    // console.log('--------------FORM DATA---------------');
    let data = '';
    postdata.forEach((value, key) => {
      // console.log(key + ':' + value)
      data += key + ':' + value + '\n';
    });
    // console.log(data);
    // console.log('--------------FORM DATA---------------');
  }

  getItem(key) {
    return window.localStorage?.getItem("WTC_" + key);
  }

  printUrlPostData(url: string, postdata) {
    // console.log('url:-' + url);
    // console.log('postData:-' + JSON.stringify(postdata, null, 4));

  }

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }

  calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }


  private subject = new Subject<void>();

  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  sharedData: string;

  formatReportName() {
    var d = new Date();
    let date = this.properFormatNumber(d.getDate());
    let month = this.properFormatNumber(d.getMonth() + 1);
    let year = d.getFullYear();

    let hour = this.properFormatNumber(d.getHours());
    let minutes = this.properFormatNumber(d.getMinutes() + 1);
    let sec = this.properFormatNumber(d.getSeconds() + 1);

    let finalDate = year + '-' + month + '-' + date + '-' + hour + ':' + minutes + ':' + sec;
    return finalDate;
  }
}
