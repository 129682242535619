<!-- <p>phoneverfication works!</p> -->

<div class="varification">
  <!-- The Modal -->

  <!-- Modal Header -->
  <button (click)="closeModal()" class="close" data-dismiss="modal" style="margin: 10px;" type="button">&times;
  </button>

  <br>
  <br>
  <div *ngIf="!submitted">
    <h4 class="modal-title font-weight-bolder">RESET PASSWORD</h4>

    <p>Oops! Uppercase, number, punctuation, blood group, sunsign - Yep, we forget our passwords too. Reset yours now.</p>
    <div style="margin: 20px;">
      <label style="text-align: start;float: left">Phone Number</label>
      <input [(ngModel)]="phone" class="form-control" placeholder="Enter registered phone number" required type="number">
    </div>
    <div style="text-align: left">
      <p *ngIf="!validPhone" class="error-label">{{validPhoneText}}</p>
    </div>
    <br>
    <button (click)="submitPhone()"
      class="btn btn-outline-primary btn-block text-uppercase font-weight-bolder w-75 m-auto d-block" style="">SUBMIT
    </button>
  </div>

  <div *ngIf="submitted&&!verified">
    <p style="text-align: center;">Please enter the code you received on your phone here</p>
    <br>

    <div style="margin: 20px;">
      <input [(ngModel)]="otp" class="form-control" placeholder="Enter OTP" required type="number">
      <p>{{utilService.getTimerTimeLeft(timeLeft)}}</p>
    </div>
    <br>
    <br>
    <button (click)="submitOTP()"
      class="btn btn-primary btn-block text-uppercase font-weight-bolder w-75 m-auto d-block" style="">SUBMIT
    </button>

    <div style="margin: 10px;"></div>
    <button (click)="submitPhone()" [disabled]="resend_disabled"
      class="btn btn-outline-primary btn-block text-uppercase font-weight-bolder w-75 m-auto d-block" style="">SEND CODE
      AGAIN
    </button>
  </div>

  <div *ngIf="submitted&&verified">
    <div style="margin: 20px;">
      <label style="text-align: start;float: left">New Password</label>
      <input [(ngModel)]="new_password" class="form-control" type="password" placeholder="Enter new password" required>
    </div>
    <br>
    <div style="margin: 20px;">
      <label style="text-align: start;float: left">Confirm Password</label>
      <input [(ngModel)]="confirm_password" class="form-control" type="password" placeholder="Enter confirm password"
        required>
    </div>
    <br>
    <br>
    <button (click)="resetPassword()"
      class="btn btn-primary btn-block text-uppercase font-weight-bolder w-75 m-auto d-block" style="">SUBMIT
    </button>
  </div>


  <br>

  <!--  <p class="text-uppercase">Edit Phone Number</p>-->
  <br>

</div>