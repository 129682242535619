import { HttpClient, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private spinner: NgxSpinnerService, private http: HttpClient, public apiService: APIService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf(this.apiService.BASE_URL + 'search/searchitems') === -1) {
      this.spinner.show();
      return next.handle(req).pipe(
        finalize(() => this.spinner.hide())
      );
    }
    if (req.url.indexOf(this.apiService.BASE_URL + 'search/searchitems') !== -1) {
      this.spinner.hide();
    }
    return next.handle(req);
  }
}
