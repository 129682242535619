import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {APIService} from "../../services/api.service";
import {UtilService} from "../../services/util.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-add-chef-review',
  templateUrl: './add-chef-review.component.html',
  styleUrls: ['./add-chef-review.component.scss']
})
export class AddChefReviewComponent implements OnInit {

  review = '';
  remark = '';
  currentRate = 0;
  anonymous = false;

  @Input() chefId;
  @Input() orderId;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private apiService: APIService,
    private utilService: UtilService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close({
      status: false
    });
  }

  saveRating() {

    if (this.remark == '') {
      this.toaster.error("please enter remark");
      return;
    }
    if (this.review == '') {
      this.toaster.error("please enter review");
      return;
    }
    if (this.currentRate == 0) {
      this.toaster.error("please select rating");
      return;
    }

    let show_user = 1;
    if (this.anonymous) {
      show_user = 0;
    }

    let postData = {
      chef_id: this.chefId,
      remark: this.remark,
      review: this.review,
      rating: this.currentRate,
      orderId: this.orderId,
      cus_id: this.utilService.getUserID(),
      show_user: show_user
    }

    // console.log("post:-" + JSON.stringify(postData));
    this.apiService.postAPI(this.apiService.BASE_URL + "chefRating/addChefRating", postData).then((result) => {
      if (result.status) {
        this.activeModal.close({
          status: true
        });
      } else {
        this.toaster.error(result.message);
      }
    }, (error) => {
      this.toaster.error("something went wrong");
    })
  }
}
