import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './allpages/navbar/navbar.component';
import { FooterComponent } from './allpages/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { PhoneverficationComponent } from './allpages/auth/phoneverfication/phoneverfication.component';
import { CartComponent } from './allpages/cart/cart.component';
import { CheckoutComponent } from './allpages/checkout/checkout.component';
import { OrderpopupComponent } from './allpages/orderpopup/orderpopup.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ViewdishpopupComponent } from './allpages/viewdishpopup/viewdishpopup.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AddUpdateAddressComponent } from './modals/add-update-address/add-update-address.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AddChefReviewComponent } from './modals/add-chef-review/add-chef-review.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { LocationEditorComponent } from './modals/location-editor/location-editor.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { VerifyEmailComponent } from './modals/verify-email/verify-email.component';
import { ToastrModule } from 'ngx-toastr';
import { InfomationComponent } from './modals/infomation/infomation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VerificationModalComponent } from './modals/verification-modal/verification-modal.component';
import { ChefInfoModalComponent } from './modals/chef-info-modal/chef-info-modal.component';
import { JourneyCustomerComponent } from './modals/journey-customer/journey-customer.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InterceptorService } from './services/interceptor.service';
import { RTOrderModule } from './allpages/r-t-order/r-t-order.module';
import { LoginModule } from './allpages/auth/login/login.module';
import { SignupModule } from './allpages/auth/signup/signup.module';
import { PromotionPageComponent } from './allpages/promotion-page/promotion-page.component';
import { VideoPopupComponent } from './modals/video-popup/video-popup.component';
// import { GuestUserModule } from "./allpages/auth/guest-user/guest-user.module";
import { SocialMediaPopupComponent } from './modals/social-media-popup/social-media-popup.component';
import { SharedModule } from '../app/shared.module';
import { provideAuth0 } from '@auth0/auth0-angular';
import { CouponPageComponent } from './modals/coupon-page/coupon-page.component';
// import { GoogleLoginComponent} from "./allpages/auth/google-login/google-login.component"





// import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    PhoneverficationComponent,
    CartComponent,
    AddUpdateAddressComponent,
    AddChefReviewComponent,
    OrderDetailComponent,
    LocationEditorComponent,
    ConfirmationModalComponent,
    InfomationComponent,
    ChefInfoModalComponent,
    JourneyCustomerComponent,
    VideoPopupComponent,
    SocialMediaPopupComponent,
    CouponPageComponent,
    // GoogleLoginComponent 
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    FormsModule,
    LazyLoadImageModule,
    HttpClientModule,
    GooglePlaceModule,
    NgxSpinnerModule,
    RTOrderModule,
    LoginModule,
    SignupModule,
    // GuestUserModule,
    SharedModule,
    // NgxSkeletonLoaderModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    BrowserAnimationsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, provideAuth0({
    domain: 'dev-pi3bps6fhyrgugtu.us.auth0.com',
    clientId: 'IQbtujPtTlQ2ehwuvKNZZFdXeV8ArW6W',
    authorizationParams: {
      redirect_uri: window.location.origin
    }
  }),],
  bootstrap: [AppComponent]
})
export class AppModule {
}
