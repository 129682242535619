<div class="modal-header p-1" style="align-items: center;">
  <div class="row w-100">
    <h6 class="pl-4 pt-2 font-weight-bolder">{{header}}</h6>
  </div>
  <button (click)="close()" aria-label="Close" class="close close-btn-m-0-p-0" style="outline: none;" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
  
    <div #gmap style="width:100%;height:100%;min-height:400px; background-color: aliceblue; border-radius: 10px;">
    </div>
  </div>
<div class="form-group border-1">
  <label>Location *</label>
<!--    <input (click)="selectLocation()" [(ngModel)]="google_location" class="form-control" placeholder="Select Location"-->
<!--           readonly="true" type="text">-->
  <input #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
         [(ngModel)]="google_location" class="form-control input"
         ngx-google-places-autocomplete placeholder="Search Location" type="text"/>
  <!--    <input #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" [(ngModel)]="google_location"-->
  <!--      class="form-control" ngx-google-places-autocomplete type="text" />-->
 
</div>

<p style="color: red; font-size: 12px;">Google Location may not always be accurate</p>
  <div class="form-group border-1 adrs-input">
    <label>Address *</label>
    <input [(ngModel)]="address" class="form-control" placeholder="Enter Address" type="text">
  </div>
  <div class="form-group border-1 mb-0">
    <label>Landmark *</label>
    <input [(ngModel)]="landmark" class="form-control" placeholder="Enter Landmark" type="text">
  </div>
  <!-- <div class="form-group border-1">
    <label>Address Type *</label>
  
    <select [(ngModel)]="address_type" class="form-control">
      <option>Home</option>
      <option>Office</option>
      <option>Other</option>
    </select>
  </div> -->

<div class="form-group">
  <div class="button-group button-add">
    <button [ngClass]="{ 'active': address_type === 'Home' }" (click)="address_type = 'Home'"><i class="fa fa-home"></i> Home</button>
    <button [ngClass]="{ 'active': address_type === 'Office' }" (click)="address_type = 'Office'"><i class="fa fa-building" aria-hidden="true"></i> Office</button>
    <button [ngClass]="{ 'active': address_type === 'Other' }" (click)="address_type = 'Other'"><i class="fa fa-map-marker" aria-hidden="true"></i> Other</button>
  </div>
  <input type="hidden" [(ngModel)]="address_type">
</div>
</div>
<div class="modal-footer">
  <button (click)="saveAddress()" class="btn btn-primary font-weight-bolder" type="button">Save</button>
</div>
