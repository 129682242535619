import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CognitoUser, CognitoUserAttribute, CognitoUserPool} from "amazon-cognito-identity-js";

const POOL_DATA = {
  UserPoolId: 'ap-south-1_JuetibjQP',
  ClientId: '51g3th5e1gqidc8fkpdflcpfsc'
};
const CHEF_POOL_DATA = {
  UserPoolId: 'ap-south-1_bFaQEMrl4',
  ClientId: '5ve7aqts5u3s2od5nke8v4l221'
};
const userPool = new CognitoUserPool(POOL_DATA);
const chefUserPool = new CognitoUserPool(CHEF_POOL_DATA);

@Injectable({
  providedIn: 'root'
})
export class APIService {

  // BASE_URL = 'https://wtcproduction.chickenkiller.com/api/';
  BASE_URL = 'https://wtc.api.oslo-labs.com/';
  // BASE_URL = 'http://localhost:3000/';
  // BASE_IMAGE_URL = 'https://wtcproduction.chickenkiller.com/api/';
  // BASE_IMAGE_URL = 'https://wtc.api.oslo-labs.com/';
  // BASE_URL = 'https://api.wethechefs.in/';
  // BASE_URL = 'https://wtc.api.oslo-labs.com/';
  // BASE_URL = 'http://localhost:3000/';
  BASE_IMAGE_URL = 'https://wtc.api.oslo-labs.com/';
  // BASE_IMAGE_URL = 'https://api.wethechefs.in/';



  constructor(private http: HttpClient) {

  }

  getAPI(url): Promise<any> {

    //console.log('url:-' + url);
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(result => {
        // loading.dismiss();
        try {
          //console.log('data:-' + JSON.stringify(result, null, 4));
          var parsedJSON = JSON.parse(JSON.stringify(result));
          resolve(parsedJSON);
        } catch (err) {
          reject(err);
        }
      }, error => {
        // loading.dismiss();
        //console.log('API Error', JSON.stringify(error, null, 4));
        error = JSON.parse(JSON.stringify(error));
        reject(error);
      });
    });
  }

  deleteAPI(url): Promise<any> {

    //console.log('url:-' + url);
    return new Promise((resolve, reject) => {
      this.http.delete(url).subscribe(result => {
        // loading.dismiss();
        try {
          //console.log('data:-' + JSON.stringify(result, null, 4));
          var parsedJSON = JSON.parse(JSON.stringify(result));
          resolve(parsedJSON);
        } catch (err) {
          reject(err);
        }
      }, error => {
        // loading.dismiss();
        //console.log('API Error', JSON.stringify(error, null, 4));
        error = JSON.parse(JSON.stringify(error));
        reject(error);
      });
    });
  }

  postAPI(url, postData): Promise<any> {
    //console.log('url:-' + url);
    //console.log('postData:-' + JSON.stringify(postData, null, 4));

    return new Promise((resolve, reject) => {
      this.http.post(url, postData).subscribe(result => {
        // loading.dismiss();
        try {
          // //console.log('data:-' + JSON.stringify(result, null, 4));
          var parsedJSON = JSON.parse(JSON.stringify(result));
          resolve(parsedJSON);
        } catch (err) {
          reject(err);
        }
      }, error => {
        // loading.dismiss();
        //console.log('API Error', JSON.stringify(error, null, 4));
        error = JSON.parse(JSON.stringify(error));
        reject(error);
      });
    });
  }

  putAPI(url, postData): Promise<any> {
    //console.log('url:-' + url);
    //console.log('postData:-' + JSON.stringify(postData, null, 4));


    return new Promise((resolve, reject) => {
      this.http.put(url, postData).subscribe(result => {
        // loading.dismiss();
        try {
          //console.log('data:-' + JSON.stringify(result, null, 4));
          var parsedJSON = JSON.parse(JSON.stringify(result));
          resolve(parsedJSON);
        } catch (err) {
          reject(err);
        }
      }, error => {
        // loading.dismiss();
        //console.log('API Error', JSON.stringify(error, null, 4));
        error = JSON.parse(JSON.stringify(error));
        reject(error);
      });
    });
  }

  uploadAttachment(url, postData, httpOptions): Promise<any> {
    //console.log('url:-' + url);
    // //console.log('postData:-' + JSON.stringify(postData, null, 4));

    return new Promise((resolve, reject) => {
      this.http.put(url, postData, httpOptions).subscribe(result => {
        // loading.dismiss();
        try {
          //console.log('data:-' + JSON.stringify(result, null, 4));
          var parsedJSON = JSON.parse(JSON.stringify(result));
          resolve(parsedJSON);
        } catch (err) {
          reject(err);
        }
      }, error => {
        // loading.dismiss();
        //console.log('API Error', JSON.stringify(error, null, 4));
        error = JSON.parse(JSON.stringify(error));
        reject(error);
      });
    });
  }

  signUp(username: string, email: string, password: string, phone: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = {
        username: email,
        email: email,
        password: password,
        phone_number: phone,
        given_name: username
      };
      const attrList: CognitoUserAttribute[] = [];
      const emailAttribute = {
        Name: 'given_name',
        Value: username
      };
      attrList.push(new CognitoUserAttribute(emailAttribute));
      userPool.signUp(user.username, user.password, attrList, null, (err, result) => {
        if (err) {
          // console.log('err:-' + JSON.stringify(err));
          reject(err);
        }
        // console.log('result:-' + JSON.stringify(result));
        resolve(result);
      });
    });
  }

  confirmUser(username, code): Promise<any> {
    // console.log('email:-' + username);
    // console.log('code:-' + code);
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username,
        Pool: userPool
      };
      const cognitUser = new CognitoUser(userData);
      cognitUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  }

  resendConfirmation(username): Promise<any> {
    // console.log('username:-' + username);
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username,
        Pool: userPool
      };
      const cognitUser = new CognitoUser(userData);
      cognitUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  }

  chefSignUp(username: string, email: string, password: string, phone: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = {
        username: email,
        email: email,
        password: password,
        phone_number: phone,
        given_name: username
      };
      const attrList: CognitoUserAttribute[] = [];
      const emailAttribute = {
        Name: 'given_name',
        Value: username
      };
      attrList.push(new CognitoUserAttribute(emailAttribute));
      chefUserPool.signUp(user.username, user.password, attrList, null, (err, result) => {
        if (err) {
          // console.log('err:-' + JSON.stringify(err));
          reject(err);
        }
        // console.log('result:-' + JSON.stringify(result));
        resolve(result);
      });
    });
  }

  chefConfirmUser(username, code): Promise<any> {
    // console.log('email:-' + username);
    // console.log('code:-' + code);
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username,
        Pool: chefUserPool
      };
      const cognitUser = new CognitoUser(userData);
      cognitUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  }

  chefResendConfirmation(username): Promise<any> {
    // console.log('username:-' + username);
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username,
        Pool: chefUserPool
      };
      const cognitUser = new CognitoUser(userData);
      cognitUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  }
}
