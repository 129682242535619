import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RTOrderComponent } from './r-t-order.component';
import { RTOrderRoutingModule } from './r-t-order-routing.module';

@NgModule({
  imports: [
    CommonModule,
    RTOrderRoutingModule,
  ],
  declarations: [RTOrderComponent],
  exports: [
    RTOrderComponent
  ]
})
export class RTOrderModule { }
