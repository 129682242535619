import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SchemaInjectorService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  injectSchema(schema: string): void {
    const scriptElement = this.renderer.createElement('script');
    scriptElement.type = 'application/ld+json';
    const scriptContent = this.renderer.createText(schema);
    this.renderer.appendChild(scriptElement, scriptContent);
    this.renderer.appendChild(document.head, scriptElement);
  }
}
