import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss']
})
export class VideoPopupComponent implements OnInit {
  @Input() message;

  controllerSrc;

  constructor(private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,) { }


  ngOnInit(): void {
    const url=this.message;
           this.controllerSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    
  }
  closeModal() {
    this.activeModal.close();
  }
  
}
