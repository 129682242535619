import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RTOrderComponent } from './r-t-order.component';
const routes: Routes = [
    { path: '', component: RTOrderComponent }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RTOrderRoutingModule { }  