<!-- <p>login works!</p> -->
<!-- <p>signup works!</p> -->
<div class="modal-header p-1 pr-3 pt-2" style="border-bottom: none;">
  <!-- <h4 class="modal-title">Hi there!</h4> -->
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-4 pt-0" *ngIf="!submitted">
  <h4 class="m-0 font-weight-bolder">Welcome to We The Chefs!</h4>
  <p style="font-size: 14px; cursor: pointer;" class="m-0" (click)="opensignin()">Looks like you are new here. Please fill in the details to <span
      class="text-primary">Create a new account</span></p>
  <br>

  <div>
    <!-- <label>Email</label>
    <input type="text" [(ngModel)]="email" class="form-control" placeholder="Enter your Email" required>
    <div style="text-align: left">
      <p *ngIf="!validEmail" class="error-label">{{validEmailText}}</p>
    </div> -->
    <label>Phone</label>
    <input type="number" [(ngModel)]="phone" class="form-control" placeholder="Enter your Phone Number" required>
    <div style="text-align: left">
      <p *ngIf="!validPhone" class="error-label">{{validPhoneText}}</p>
    </div>
    <!-- <div class="d-flex justify-content-end">
      <label class="mr-auto">Password</label>
    </div>
    <input type="password" [(ngModel)]="password" class="form-control" placeholder="Enter your password" required>
    <div style="text-align: left">
      <p *ngIf="!validPassword" class="error-label">{{validPasswordText}}</p>
    </div> -->

    <!-- <div class="d-flex">
      <p class="my_checkbox" style="">
        <label><input type="checkbox" />Remember me</label>
      </p>
      <a class="ml-auto text-primary" style="font-size: 12px; margin-top: 5px;cursor: pointer;" (click)="forgotPassword()">Forgot Password</a>
    </div> -->
    <!-- <div class="form-check" style="border: solid 1px black;">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style="border: solid 1px red;">
      <p style="font-size: 14px; margin-left: 5px; margin-top: 5px; border: solid 1px blue;" class="form-check-label"
        for="flexCheckDefault">
        Remember me
      </p>
    </div> -->
  </div>

  <br>
  <!-- <div *ngIf="false">
    <p class="text-center">or</p>

    <button class="btn btn-block" style="border: solid 1px black; font-size: 13px;">
      <i aria-hidden="true" class="fa fa-google"></i> &nbsp;Sign in with Google
    </button>
    <button class="btn btn-block" style="border: solid 1px black; font-size: 13px;">
      <i aria-hidden="true" class="fa fa-facebook"></i> &nbsp;Sign in with Facebook
    </button>

    <br>
  </div> -->

  <button (click)="login()" class="btn btn-primary font-weight-bolder btn-block text-uppercase">
    Login
  </button>
  <!-- <button (click)="auth.loginWithRedirect()">Log in</button> -->
  <hr>
   <app-google-login></app-google-login>
</div>
  <!-- <hr>
  <div>
    <button (click)="openGuestin()">Continue as guest</button>
  </div> -->
   
   <!-- <button (click)="loginWithGoogle2()">Login with Google</button>
   <button (click)="loginWithFacebook()">Login with Facebook</button> -->

  
  <div *ngIf="submitted&&!verified">
    <p style="text-align: center;">Please enter the code you received on your phone here</p>
    <br>

    <div style="margin: 20px;">
      <input [(ngModel)]="otp" class="form-control" placeholder="Enter OTP" required type="number">
      <p style="text-align: center;">{{utilService.getTimerTimeLeft(timeLeft)}}</p>
    </div>
    <br>
    <br>
    <button (click)="submitOTP()"
    
      class="btn btn-primary btn-block text-uppercase font-weight-bolder w-75 m-auto d-block" style="">SUBMIT
    </button>

    <div style="margin: 10px;"></div>
    <button (click)="login()" [disabled]="resend_disabled"
      class="btn btn-outline-primary btn-block text-uppercase font-weight-bolder w-75 m-auto d-block" style="">SEND CODE
      AGAIN
    </button>
    <br>

    <!--  <p class="text-uppercase">Edit Phone Number</p>-->
    <br>
  </div>

