import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  search;
  type;
  // chefId;

  constructor() {
  }

  // setChefId(chefId) {
  //   this.chefId = chefId;
  // }
  //
  // getChefId() {
  //   return this.chefId;
  // }

  setValues(search) {
    // console.log("value:-" + search);
    this.search = search;
  }

  getValues() {
    return this.search;
  }

  setType(type) {
    this.type = type;
  }

  getType() {
    return this.type;
  }
}
