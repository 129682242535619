import {Component, OnInit} from '@angular/core';
import {OrderService} from "../../services/order.service";

declare var Razorpay: any;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  form: any = {};
  paymentId: string;
  error: string;

  constructor(private orderService: OrderService) {

  }

  successCallback = (success) => {
    alert('payment_id: ' + success.razorpay_payment_id)
    var orderId = success.razorpay_order_id
    var signature = success.razorpay_signature
  }

  payWithRazorpay() {
    // console.log("paying razorpay");
    const options: any = {
      key: 'rzp_test_nOpoMazrOFihin',
      amount: 1000, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: "Java Chinna",
      description: "Web Development",
      image: './assets/customer-profile-images/Logo.png',
      "prefill": {
        "name": "Sunil Vishwakarma",
        "email": "vishwakarmasunil68@gmail.com",
        "contact": "9873738969"
      },
      // order_id: "1234", // order_id created by you in backend
      theme: {
        color: '#E26812'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      // console.log(response);
      alert("Payment successfull & your order id is "+response.razorpay_payment_id);
    });

    var rzp1 = new Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', function (response) {
      // console.log("failed response:-" + JSON.stringify(response))
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
  }

  ngOnInit(): void {
  }
}
