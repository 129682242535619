<!-- <p>r-t-order works!</p> -->
<div class="bg-primary p-2 ready_bottom_tape">
    <div class="d-flex w-100">
      <!-- <h6 class="text-white text-uppercase font-weight-bolder text-nowrap m-auto w-30">Ready to order?</h6> -->
      <!-- <h6 class="text-white text-uppercase font-weight-bolder text-nowrap m-auto w-30
      d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block
      ">Just a couple of taps away</h6> -->
      <!-- <button class="btn bg-white  font-weight-bolder text-uppercase m-auto w-30" routerLink="/search-page">book your
        order
      </button> -->
      <button class="btn bg-white  font-weight-bolder text-uppercase m-auto" routerLink="/search-cuisine/book-your-order">book your
        order
      </button>
    </div>
  </div>