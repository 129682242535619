<!-- <p>tandc works!</p> -->
<!-- <p>signup works!</p> -->
<div class="modal-header p-1 pr-3 pt-0" style="border-bottom: none; margin-bottom: -10px;">
    <!-- <h4 class="modal-title">Hi there!</h4> -->
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body mr-0 pt-0">
    <h5 class="mt-0 pt-0 font-weight-bolder">TERMS AND CONDITIONS FOR USAGE OF WEBSITE AND MOBILE APP</h5>
    <div class="content">
        <p>This Technology Platform / Website (the 'Website') and the Mobile App “<b>WETHECHEFS</b>”, is owned and operated by
            We the Chefs Food Solutions Private Limited (“<b>WETHECHEFS</b>”), a Company incorporated under the Indian
            Companies Act. <b>WETHECHEFS</b> is an
            E-Commerce Technology platform which facilitates the meeting of end users with selected specialized chefs
            through its App “<b>WETHECHEFS</b>” or the website <a href="https://www.wethechefs.in"
                target="_blank">www.wethechefs.in</a>. Users are neither in control of <b>WETHECHEFS</b> nor
            <b>WETHECHEFS</b> is the owner of such services or provides such services. The entire purpose of the mobile
            app/platform is to make end users/foodies meet the selected home chefs and place orders by using the mobile
            app <b>WETHECHEFS</b>. <b>WETHECHEFS</b> has also partnered with various aggregators for providing student accommodation
            globally.</p>
        <p>Your usage of the Website/Mobile App, creation of an account as well as the services offered on this
            website/Mobile App are strictly subject to your acceptance of and without modification of all the terms,
            conditions, and notices set forth below (collectively, the 'Terms of Use' or 'Agreement').</p>
        <p>Please read the terms of Use carefully. By accessing or using this Website or downloading the Software/Mobile
            Application and creating a log in account, you agree to the Terms of Use in force. If you do not agree to
            the Terms of Use, please do not use this Website/Mobile Application or avail any services through this
            Website/Mobile Application. You also confirm that by accessing or using this website or downloading the
            software/mobile application and creating a log in account you agree to the Terms of Use in force of the
            current and future service providers of <b>WETHECHEFS</b>. Also, you accept that any changes made by any of the
            service providers of <b>WETHECHEFS</b> at any time are acceptable to you.</p>
        <p><b>WETHECHEFS</b> reserves its rights to change these Terms of Use and your continued use of this Website/Mobile
            Application is conditioned upon acceptance of the updated Terms of Use.</p>
        <p>You understand that <b>WETHECHEFS</b> is only a Technology Platform and has partnered with curated and specially
            selected chefs (“Service Providers”) from across the country and acts only as an aggregator. To assist the
            clients and chefs, WETHESCHEF is responsible only to the extent of billing service provider and for
            providing end to end delivery services. It neither takes responsibility of the quality of the product, taste
            of the product or the workmanship of the chefs or their timely performance.</p>

        <p><b>WETHECHEFS</b> shall only facilitate in making you meet the aggregator or Service Provider with whom <b>WETHECHEFS</b>
            has partnered. As a customer, you can use our Mobile App <b>WETHECHEFS</b> or the website or any social media
            platform for identifying the chefs, accessing their menu, placing order, payment for the order, and for
            update on the delivery services. You understand that <b>WETHECHEFS</b> does not have any control on the product
            including quality nor does it make any representation on the quality of the end product i.e. food.</p>

        <p>AS A CHEF (Service Provider), you can log in and access our Mobile App/Website or other platforms for
            updating your menu, your terms and conditions for providing services to end users and timely delivery of the
            products. You shall also be responsible for quality of the products.</p>

        <h6 class="mt-0 pt-0 font-weight-bolder">CONDITIONS PRECEDENT FOR USE OF THE WEBSITE</h6>

        <p class="font-weight-bolder">FOR CUSTOMERS</p>
        <p>As a condition of your use of this Website, you warrant that:</p>
        <ul>
            <li>You have attained the legal age of 18 years and eligible to execute any agreement or a binding contract.
            </li>
            <li>You hereby agree to use this Website in accordance with the Terms of Use.</li>
            <li>You hereby acknowledge and confirm that you shall be solely responsible for your safety and for the
                belongings. You hereby waive all claims against <b>WETHECHEFS</b> on any mishappening while your stay at the
                chosen accommodation.</li>
            <li>You will use this Website only to avail the services that may be provided by our partner websites or
                aggregators.</li>
            <li>You confirm that you shall not indulge in any illegal, immoral act.</li>
            <li>You will inform such other persons about the Terms of Use that apply to the orders you have made on
                their behalf, including all rules and restrictions applicable thereto.</li>
            <li>All information supplied by you on this Website is true, accurate, current and complete, and.</li>
            <li>If you have an online account with this Website, you will safeguard your account information and
                will supervise and be completely responsible for any use of your account by you and anyone other than
                you.</li>
            <li>You shall not share the Password as well as other log in details of your account on this website and
                shall also not use the credit cards or other such plastic money or payment methods, for which you have
                not been authorized.</li>
            <li>You shall not damage, destroy, pilfer, steal or misappropriate any property at the time of usage of
                the premises.</li>
            <li>You agree that in case your account is suspended/locked by <b>WETHECHEFS</b> for any breach of the terms of
                this agreement, then in such event you shall be liable for any loss including the statutory liability
                and <b>WETHECHEFS</b> shall forfeit all money lying in your <b>WETHECHEFS</b> wallet.</li>
        </ul>
        <p class="font-weight-bolder">FOR SERVICE PROVIDERS</p>
        <p>As a condition of your use of the Website, you warrant that:</p>
        <ul>
            <li>You shall use the website for log in and keep your log in account safe, including the passwords.</li>
            <li>You shall update the accurate and correct details of the services provided by you and other
                information related to your services. <b>WETHECHEFS</b> disclaims the accuracy and correctness of all such
                information/details provided by you.</li>
            <li>You are responsible for providing the accurate and correct details of your services including
                description, photos, ingredients and its possible behavior or side effects on human body. <b>WETHECHEFS</b>
                disclaims the accuracy and correctness of all such information/details provided by you.</li>
            <li>You acknowledge to keep <b>WETHECHEFS</b> indemnified from all claims or liabilities for the services
                provided by you.</li>
            <li>You shall maintain privacy of Customers that may seek your services through <b>WETHECHEFS</b> Websites. You
                shall be responsible to maintain the information securely in your server deploying the latest method of
                security.</li>
            <li><b>WETHECHEFS</b> retains the right in its sole discretion to deny access to anyone to this Technology
                Platform/Website and the services it offers, at any time without notice and for any reason, including,
                but not limited to, for violation of these Terms of Use.</li>
        </ul>

        <h6 class="mt-0 pt-0 font-weight-bolder">DETAILS REQUIRED FOR CREATING A LOG IN ACCOUNT</h6>
        <p>While creating a log in account on the Website, you shall provide your complete name, telephone numbers,
            mobile number, credit card details or other such payment services, age, address. You hereby warrant that the
            details provided by you are correct in all respect without any manipulation or fraud. Any usage of services
            offered by this Website is subject to the creation of log in account in your name. Before creating a login
            account in your name, you must read the terms and conditions for use of Website and agree to the same.</p>

        <h6 class="mt-0 pt-0 font-weight-bolder">ACTIVITIES PROHIBITED ON USE OF WEBSITE</h6>

        <p>The content and information on this Website (including, but not limited to, price and availability of
            services), as well as the infrastructure used to provide such content and information, is proprietary to us
            or our aggregators/partners service providers. You agree not to otherwise modify, copy, distribute,
            transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or
            re-sell any information, software, products, or services obtained from or through this Website.
            Additionally, you agree not to:</p>

        <ul>
            <li>Use this Website or its contents for any commercial purpose.</li>
            <li>Make any speculative, false, or fraudulent order or any reservation in anticipation of demand.</li>
            <li>Access, monitor or copy any content or information of this Website using any robot, spider, scraper
                or other automated means or any manual process for any purpose without our express written permission.
            </li>
            <li>Violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent
                other measures employed to prevent or limit access to this Website.</li>
            <li>Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately
                large load on our infrastructure.</li>
            <li>deep-link to any portion of this Website for any purpose without our express written permission; or
            </li>
            <li>'frame', 'mirror' or otherwise incorporate any part of this Website into any other website without
                our prior written authorization.</li>
        </ul>

        <h6 class="mt-0 pt-0 font-weight-bolder">APPLICABILITY OF THIRD PARTY & SUPPLIER RULES AND RESTRICTIONS</h6>
        <p>You agree that <b>WETHECHEFS</b> is only the facilitator connecting you and Aggregator/Service Provider i.e. Chefs.
            The Chefs have been selected by <b>WETHECHEFS</b> on the basis of stringent selection process including
            recommendation. The Chefs selected by <b>WETHECHEFS</b> have agreed to provide services to you on certain
            conditions
            including advance order placement and advance payment by using the mobile App <b>WETHECHEFS</b>. You understand
            that
            unless and order has been placed on <b>WETHECHEFS</b> app in accordance to the terms and conditions of the Chefs
            and
            payments have been made in advance, such order shall not be confirmed.</p>
        <p>As an end user, you also understand that the chefs selected by <b>WETHECHEFS</b> provide regional national as well
            as
            international cuisine and preparation thereof shall be dependent on the availability of the ingredients,
            weather condition as well as other local factors.</p>
        <p><b>WETHECHEFS</b> will only act as an intermediary between you i.e. end user and the service providers i.e. the
            Chefs, and you agree that the contractual relationship will be directly between you and that service
            provider as far as quality of the food items are concerned. <b>WETHECHEFS</b> Does not have any role in your
            selection of the chef or the menu, by the service provider. Payments for all Bookings will be in accordance
            with the terms and conditions set out by the Service Provider (“Service Provider’s Terms”). You agree to be
            bound by the Service Provider’s Terms, which could include (but not limited to) the Service Provider’s rules
            regarding use of service of Service Provider. </p>

        <h6 class="mt-0 pt-0 font-weight-bolder">PRIVACY POLICY</h6>

        <p><b>WETHECHEFS</b> has a strict Privacy Policy, which you may access from the following link as well as clicking
            Privacy Policy on the website or mobile application. Before creating an account on <b>WETHECHEFS</b> Website, it is
            mandatory that you read the Privacy Policy. By creating the Log in Account on <b>WETHECHEFS</b> Website you have
            agreed to the Privacy Policy of <b>WETHECHEFS</b>. <b>WETHECHEFS</b>’s software and Mobile Application may access your
            location, upon your consent for certain services and information around you, availability of taxis, weather
            conditions, places of interest etc. </p>

        <h6 class="mt-0 pt-0 font-weight-bolder">USAGE OF PERSONAL INFORMATION BY <b>WETHECHEFS</b></h6>
        <p>You hereby grant permission to <b>WETHECHEFS</b> to access and use your personal information such as Name, Address,
            Phone Number, Email in relation to the services of <b>WETHECHEFS</b> and also to disclose the same to the Service
            Provider or its Partners in relation to the services that you may obtain by visiting our Partner’s websites.
            <b>WETHECHEFS</b> may use your personal information only for the purpose of Newsletters, or promotional messaging
            in
            relation to <b>WETHECHEFS</b> products, however, you shall have a right to unsubscribe such Newsletters or
            Promotional Messages.</p>

        <h6 class="mt-0 pt-0 font-weight-bolder">PAYMENTS</h6>
        <p>You understand that <b>WETHECHEFS</b> collects payments from you on behalf of the Chefs. We have partnered with
            payment gateways and wallets for making payments for the products offered by the Chefs on our website. When
            you make the payment, we do not store the credit card details or your banking details or your wallet
            details. Your data may be accessed by our payment partners i.e. payment gateways, banks, wallet service
            providers.</p>
        <p>You agree that making payment does not confirm the delivery of food items on the designated time unless the
            Chefs have confirmed the same and <b>WETHECHEFS</b> communicates through email or messaging services.</p>

        <h6 class="mt-0 pt-0 font-weight-bolder">CANCELLATION & REFUND</h6>
        <p>You understand <b>WETHECHEFS</b> does not provide any services except making you meet the aggregator/service
            provider. All cancellation shall be subject to the terms and conditions of the Service Provider i.e. the
            Chefs. Once, the Chef has confirmed the order and delivery thereof, no order can be cancelled. <b>WETHECHEFS</b>
            shall not be responsible for any refund. You shall keep <b>WETHECHEFS</b> indemnified from all such claims or
            liabilities. Any cancellation shall be entertained only within the first 1 hour of the payment or prior to
            confirmation by the Chefs, whichever is earlier.</p>


        <h6 class="mt-0 pt-0 font-weight-bolder">LIMITATION OF LIABILITY AND DISCLAIMER</h6>
        <p><b>WETHECHEFS</b> has taken adequate care and precaution to provide error free and updated Information, Software,
            Products and Services published on this Website. However, there may be instances wherein include
            inaccuracies or errors may have crept in. <b>WETHECHEFS</b> does not guarantee the accuracy of and disclaim all
            liability for any errors or other inaccuracies relating to the information and description displayed on this
            Website.</p>
        <p>Ratings, if any displayed on this Website are intended as only general guidelines, and the <b>WETHECHEFS</b> does
            not
            guarantee the accuracy of the ratings. The <b>WETHECHEFS</b> and its suppliers make no guarantees about the
            availability of specific products and services. <b>WETHECHEFS</b> and its suppliers may make improvements and/or
            changes on the Website at any time including the pricing, terms and conditions, rules and regulations etc.
        </p>
        <p><b>WETHECHEFS</b> and its suppliers make no representations about the suitability of the information, software,
            products and services contained on this Website for any purpose, and the inclusion or offering of any
            products or services on this Website does not constitute any endorsement or recommendation of such products
            or services by <b>WETHECHEFS</b>. All such information, software products, and services are provided “as is”
            without
            warranty of any kind. <b>WETHECHEFS</b> and its suppliers disclaim all warranties and conditions that this Website,
            its servers or any email sent from the <b>WETHECHEFS</b> and/or its suppliers are free of viruses or other harmful
            components. <b>WETHECHEFS</b> and its suppliers hereby disclaim all warranties and conditions whether express or
            implied, with regard to this information, software, products and services, including all implied warranties
            and conditions of merchantability, satisfactory quality, fitness for a particular purpose, compliance with
            any description, title and non-infringement, including that this Website shall be free from infringement of
            any copyright, trademark or other intellectual property rights of third parties.</p>
        <p><b>WETHECHEFS</b> shall not be liable for the acts, errors, omissions, representations, warranties, breaches or
            negligence of any products supplied by the Chefs, which may result in any personal injuries, death, damage,
            or other damages or expenses resulting therefrom. <b>WETHECHEFS</b> shall not be liable for any costs, expenses,
            claims or damages arising out of any services to recover any of the foregoing, including without limitation
            relocation costs.</p>
        <p>Under no circumstances <b>WETHECHEFS</b> shall be liable for any direct, indirect, punitive, incidental, special or
            consequential damages arising out of, or in any way connected with, your access to, display of or use of
            this Website or with the delay or inability to access, display or use this Website (including, but not
            limited to, your reliance upon opinions appearing on this Website; any computer viruses, information,
            software, linked sites, products and services obtaining through this Website; or otherwise arising out of
            the access to, display of or use of this Website).</p>
        <p>Under no circumstances <b>WETHECHEFS</b>’s liability shall exceed Rs. 1,000 provided </p>
        <p><b>WETHECHEFS</b> is guilty of any willful negligence or default </p>


        <h6 class="mt-0 pt-0 font-weight-bolder">INDEMNIFICATION</h6>
        <p>As a Chef you agree to defend and indemnify <b>WETHECHEFS</b> and its partners and any of their officers, directors,
            employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages,
            fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable
            legal and accounting fees, brought by third parties as a result of your:</p>
        <ul>
            <li>Quality of food and preparation thereof including any adverse reaction on consumption.</li>
            <li>any legal non-compliance or violation of law which may arise on account of incomplete / inaccurate
                information submitted by you.</li>
            <li>Unauthorized usage of the website including your sharing of Login details.</li>
        </ul>


        <h6 class="mt-0 pt-0 font-weight-bolder">THIRD PARTY LINKS </h6>
        <p>This Website may contain hyperlinks to websites operated by parties other than <b>WETHECHEFS</b>. Such hyperlinks
            are
            provided for your reference only. We do not control such websites and are not responsible for their contents
            or the privacy or other practices of such websites. Further, it is up to you to take precautions to ensure
            that whatever links you select or software you download (whether from this Website or other websites) is
            free of such items as viruses, worms, trojan horses, defects and other items of a destructive nature. Our
            inclusion of hyperlinks to such websites does not imply any endorsement of the material on such websites or
            any association with their operators.</p>

        <h6 class="mt-0 pt-0 font-weight-bolder">SOFTWARE AVAILABLE ON THIS WEBSITE </h6>
        <p>Any software that <b>WETHECHEFS</b> may make available to download from this Website ('Software') or through your
            mobile application store, including the mobile application “<b>WETHECHEFS</b>” (the “Mobile Application”), is the
            copyrighted work of <b>WETHECHEFS</b>. Your use of such Software is governed by the terms of this Agreement. Before
            installing the Software, you shall be asked to read the Privacy Agreement as well as this Terms &
            Conditions, which you must accept to the terms and conditions after reading and only then you shall install
            the software. For any Software made available for download on this Website not accompanied by a License
            Agreement, we hereby grant to you, the user, a limited, personal, non-exclusive nontransferable license to
            download, install and use the Software and/or the Mobile Application for viewing and otherwise using this
            Website and/or accessing the content and information available within the Mobile Application (including,
            without limitation, price and availability of travel services) in accordance with these Terms of Use and for
            no other purpose.</p>

        <p>Please note that all Software, including, without limitation, all HTML code and Active X controls contained
            on this Website, is owned by <b>WETHECHEFS</b> or its Partner Websites and is protected by copyright laws and
            international treaty provisions. Any reproduction or redistribution of the Software is expressly prohibited
            and may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent
            possible.</p>

        <p><b>WETHECHEFS</b> holds the Trademark Rights over “<b>WETHECHEFS</b>” and any person found to be using the same in any
            manner
            and without express permission of <b>WETHECHEFS</b> shall be liable for Infringement action. Names of Service
            Provider are the Trademarks of Service Providers.</p>

        <p>WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR
            FURTHER REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PROHIBITED. THE SOFTWARE IS WARRANTED, IF AT ALL, ONLY
            ACCORDING TO THE TERMS OF THE LICENSE AGREEMENT.</p>

        <p>Your mobile device must be connected to the internet for the Mobile Application to function correctly. You
            are responsible for making all arrangements necessary for your device to have internet connectivity and are
            responsible for all sums your service provider may charge you arising out of the Mobile Application
            transmitting and receiving data (including but not limited to data roaming charges). As further described in
            our Privacy Policy, the Mobile Application will automatically transfer a small amount of data as part of its
            normal operation, including how you use the Mobile Application, Location Services, Content you access, and
            technical errors or problems which the Application may encounter while being used. By using the Mobile
            Application, you acknowledge, agree and consent to the automatic collection of this information.</p>

        <h6 class="mt-0 pt-0 font-weight-bolder">ACCOUNT TERMINATION </h6>
        <p><b>WETHECHEFS</b> may in certain cases terminate your login account in its sole discretion, or limit your access to
            the Website and/or terminate the accounts if you are found to be violating any law, or found to be in breach
            of the terms and conditions of usage of this website, or for any fraudulent activity through your Login
            Account, or if <b>WETHECHEFS</b> receives any complaint or information from any person that through your Login
            Account any fraudulent activity has taken place. In certain cases, <b>WETHECHEFS</b> may also inform the police and
            file appropriate complaint against you. </p>

        <h6 class="mt-0 pt-0 font-weight-bolder">JURISDICTION </h6>
        <p>This Agreement is governed by the Laws of India and is only subject to the Jurisdiction of Delhi Court.</p>




    </div>
    <div class="pt-2"></div>
    <button class="btn btn-primary p-1 font-weight-bolder btn-block w-25 text-uppercase"
        (click)="activeModal.dismiss('Cross click')">
        OK
    </button>

</div>