import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueItem'
})
export class UniqueItemPipe implements PipeTransform {

  transform(Items: any, element:string) {
    
    if(Items != undefined && Items != null)
    {
      if(element == "cuisines")
      {
        var tempItems = [];
        Items.forEach(item => {
          if(tempItems.length > 0)
          {
            tempItems = tempItems.concat(item[element].split(","));
          }
          else 
          {
            tempItems = item[element].split(",");
          }
        });
        tempItems = tempItems.map(x => {return x.trim()})
      }
      else if(element == "person_service")
      {
        var tempItems = [];
        Items.forEach(item => {
          item.item.forEach(x => {
            if(x[element] != null && x[element] != "" && x[element] != "0" && x[element] != 0)
              tempItems.push(x[element]);
          });
        });
      }
      else if(element == "diet_type" || element == "meal_type")
      {
        var tempItems = [];
        Items.forEach(item => {
          item.item.forEach(x => {
            if(x[element] != null && x[element] != "" && x[element] != "0" && x[element] != 0)
            {
              x[element].split(',').forEach(dietId => {
                if(dietId != null && dietId != "" && dietId != "0" && dietId != 0)
                tempItems.push(dietId);
              });
            }
          });
        });
      }
      else if(element == "avg")
      {
        var tempItems = [];
        Items.forEach(item => {
          tempItems.push(item[element]);
        });
        
      }

      if(element != "")
      {
        if (!tempItems) return [];
        var flags = [], output = [], l = tempItems.length, i

        for( i=0; i<l; i++) {
            if( flags[tempItems[i]]) continue;
            flags[tempItems[i]] = true;
            output.push(tempItems[i]);
        }
        return output
      }
      else
      {
        if (!tempItems) return [];
        var flags = [], output = [], l = tempItems.length, i

        for( i=0; i<l; i++) {
            if( flags[tempItems[i]]) continue;
            flags[tempItems[i]] = true;
            output.push(tempItems[i]);
        }
        return output
      }
    }
  }

}
