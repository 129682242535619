<!-- <div style="background-color: transparent;">
    <button (click)="closeModal()"  style="margin: 10px;" type="button">&times;
    </button>

   
      <div class="col-sm-12">
        <iframe [src]="controllerSrc"></iframe>
    </div>
</div>
   -->
<div >
    <button (click)="closeModal()" class="iframe-icon close" type="button">&times;
    </button>
</div>

<div class="modal-body">
    <iframe  
    [src]="controllerSrc"  class="iframe-350" ></iframe>
</div>