import {Component, Input, OnInit,ElementRef,ViewChild } from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {APIService} from "../../services/api.service";
import {UtilService} from "../../services/util.service";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {LocationEditorComponent} from "../location-editor/location-editor.component";
import {ToastrService} from "ngx-toastr";
declare var google: any;
@Component({
  selector: 'app-add-update-address',
  templateUrl: './add-update-address.component.html',
  styleUrls: ['./add-update-address.component.scss']
})
export class AddUpdateAddressComponent implements OnInit {

  @Input() userAddress;
  header = 'Add delivery address';

  address_type = '';
  address = '';
  landmark = '';
  google_location = '';
  selectedLocation = null;
  currentLat = 28.5272803;
  currentLng = 77.0688997;
  geofence;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private apiService: APIService,
    private utilService: UtilService,
    private toaster: ToastrService
  ) {
  }
  @ViewChild('gmap', {static: false}) gmapElement: ElementRef;
  map: any;
 
  ngAfterViewInit() {
    const mapOptions = {
      zoom: 17,
      center: new google.maps.LatLng(28.468673012294833, 77.29727001278401)
      // center: new google.maps.LatLng(28.468673012294833, 77.29727001278401)
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
    
    this.showMarkerOnMap(Number(this.userAddress.lat), Number(this.userAddress.lng), 'cus');
  

  }
  ngOnInit(): void {
    // console.log("Edit address:-" + JSON.stringify(this.userAddress));
    if (this.userAddress != null && this.userAddress != undefined) {
      this.address_type = this.userAddress.address_type;
      this.address = this.userAddress.address;
      this.landmark = this.userAddress.landmark;
      
      this.google_location = this.userAddress.formatted_address;
      // console.log(this.google_location,"46"); 
      //  console.log(this.userAddress.formatted_address,"47");
      
      this.selectedLocation = {
        place_id: this.userAddress.place_id,
        formatted_address: this.userAddress.formatted_address,
        location: {
          lat: this.userAddress.lat,
          lng: this.userAddress.lng
        }
      };
      if (this.selectedLocation) {
        const marker = new google.maps.Marker({
          position: this.selectedLocation.location,
          map: this.map,
          title: this.selectedLocation.formatted_address
        });
      
        this.map.setCenter(marker.getPosition());
      }
      this.map.addListener('click', (mapsMouseEvent) => {
        let latlng = mapsMouseEvent.latLng;
        // console.log(latlng.lat() + ',' + latlng.lng());
        // this.getReverseGeocodingData(latlng.lat(), latlng.lng());
    });
      // console.log("check address 56:-",this.selectedLocation);
      this.showMarkerOnMap(Number(this.userAddress.lat), Number(this.userAddress.lng), 'cus');
      // this.showMarkerOnMap(address.geometry.location.lat(), address.geometry.location.lng(), false)
    } else {
      this.header = 'Add Address';
    }
  }

  public handleAddressChange(address: Address) {
    // console.log('address add:-' + JSON.stringify(address));

    this.selectedLocation = {
      place_id: address.place_id,
      formatted_address: address.formatted_address,
      location: {
        lat: address.geometry.location.lat(),
        lng: address.geometry.location.lng()
      }
    }
    // this.showMarkerOnMap(address.geometry.location.lat(), address.geometry.location.lng(), false)
    this.showMarkerOnMap(Number(address.geometry.location.lat()), Number(address.geometry.location.lng()), 'cus');
  }
//   getLocation() {
//     if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition((position) => {
//                 if (position) {
//                     console.log('Latitude: ' + position.coords.latitude +
//                         'Longitude: ' + position.coords.longitude);
                    
//                     this.currentLat = position.coords.latitude;
//                     this.currentLng = position.coords.longitude;
//                     if (this.map != null) {
//                         this.showMarkerOnMap(this.currentLat, this.currentLng, true);
//                     }
//                 }
//             },
//             (error) => {
//                 console.log(error);
//             });
//     }
// }
marker;
    chefMarker;

    showMarkerOnMap(lat, lng, type) {
        const myLatLng = {lat: lat, lng: lng}

        let marker = new google.maps.Marker({
            position: myLatLng,
            title: 'current location',
        });

        marker.setMap(this.map);
        // marker.setTitle(name);

        if (type == 'chef') {
            let chefInfo = new google.maps.InfoWindow({
                // content: name,
                maxWidth: 200
            });
            chefInfo.open(this.map, marker);
        } else if (type == 'cus') {

            let markerInfo = new google.maps.InfoWindow({
                // content: name,
                // maxWidth: 200
            });
            // markerInfo.open(this.map, marker);
        }

        marker.setMap(this.map);
        if (type == 'cus') {
            this.map.setCenter(myLatLng);
            this.marker = marker;
        } else if (type == 'chef') {
            this.chefMarker = marker;
        }

        if (this.marker != null && this.chefMarker != null) {
            // var lat = marker.getPosition().lat();
            // var lng = marker.getPosition().lng();
            var bounds = new google.maps.LatLngBounds();
            bounds.extend(this.marker.getPosition());
            bounds.extend(this.chefMarker.getPosition());
            this.map.fitBounds(bounds);
        }
    }
//   showMarkerOnMap(lat, lng, searchAddress) {
//     if (this.geofence != null) {
//         this.geofence.setMap(null);
//     }
//     const myLatLng = {lat: lat, lng: lng}
//     this.map.setCenter(myLatLng);
//     const geofence = new google.maps.Circle({
//         strokeColor: '#FF0000',
//         strokeOpacity: 0.8,
//         strokeWeight: 2,
//         fillColor: '#FF0000',
//         fillOpacity: 0.35,
//         center: myLatLng,
       
//     });

//     geofence.setMap(this.map)

//     this.geofence = geofence;
// }
  validateAddress() {
    let isValid = true;
    if (this.address_type == '') {
      this.toaster.error('Please select address type');
      isValid = false;
    }

    if (this.address == '') {
      this.toaster.error('Please enter address');
      isValid = false;
    }

    if (this.landmark == '') {
      this.toaster.error('Please enter landmark');
      isValid = false;
    }

    if (this.selectedLocation == null) {
      this.toaster.error('Please select google location');
      isValid = false;
    }

    return isValid;
  }

  saveAddress() {
    if (!this.validateAddress()) {
      return;
    }
    if (this.userAddress != null && this.userAddress != undefined) {
      this.updateAddress()
      return;
    }

    let postData = {
      user_id: this.utilService.getUserID(),
      address: this.address,
      landmark: this.landmark,
      formatted_address: this.selectedLocation.formatted_address,
      place_id: this.selectedLocation.place_id,
      lat: this.selectedLocation.location.lat,
      lng: this.selectedLocation.location.lng,
      address_type: this.address_type
    }
    // console.log(postData,"Address");
    
    this.apiService.postAPI(this.apiService.BASE_URL + "user/addUserAddress", postData).then((result) => {
      if (result.status) {
        this.activeModal.close();
      } else {
        this.toaster.error(result.message);
      }
    }, (error) => {
      // console.log("something went wrong");
    })
  }

  updateAddress() {
    let postData = {
      user_id: this.utilService.getUserID(),
      address: this.address,
      landmark: this.landmark,
      formatted_address: this.selectedLocation.formatted_address,
      place_id: this.selectedLocation.place_id,
      lat: this.selectedLocation.location.lat,
      lng: this.selectedLocation.location.lng,
      address_type: this.address_type,
      id: this.userAddress.id
    }

    this.apiService.postAPI(this.apiService.BASE_URL + "user/updateUserAddress", postData).then((result) => {
      if (result.status) {
        this.activeModal.close();
      } else {
        alert(result.message);
      }
    }, (error) => {
      // console.log("something went wrong");
    })
  }

  close() {
    this.activeModal.close();
  }

  selectLocation() {
    const modalRef = this.modalService.open(LocationEditorComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
      windowClass: "mobileClass"
    });
    modalRef.closed.subscribe((result) => {
      if (result != undefined) {
        // console.log('result:-' + JSON.stringify(result));
        let location = result.location;
        // console.log('result location:-' ,location);
        this.google_location = location.formatted_address;
        // console.log('result google_location', this.google_location);
        this.selectedLocation = location;
        // console.log('result selectedLocation', this.selectedLocation);
      }
    })
    modalRef.componentInstance.address = this.address;
  }
}
