import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mealFilter',
  pure: false
})
export class MealFilterPipe implements PipeTransform {

  transform(items: any, element:string, value:any) {
    if(items != null && items != undefined && items.length > 0 && value != undefined && value.length > 0)
    {
      if(items != undefined )
      {
        if (value.length > 0) 
        {
          return this.filterMeal(items, value, element);
        }
        else
        {
          return items;
        }
      }
    }
    else
    {
      return items;
    }
    return items;
  }

  filterMeal(mainItems, value, element)
  {
    let finalItems = [];
    mainItems.filter((mainItem:any) => {
      mainItem.item.forEach(item => {
        if(item[element] != null && value != null && value.length > 0)
        {
          item[element].split(',').forEach(mealId => {
            if(value.filter(x => mealId.indexOf(x) != -1).length > 0)
            {
              finalItems.push(mainItem);
            }         
          });
        }
      });
     
    });
    return finalItems.filter(function(item, index) { return finalItems.indexOf(item) >= index;});
  }

}
