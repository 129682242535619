<!-- <p>journeycustomer works!</p> -->
<!-- <p>signup works!</p> -->
<div class="modal-header p-1 pr-3 pt-2" style="border-bottom: none; margin-bottom: -10px;">
    <!-- <h4 class="modal-title">Hi there!</h4> -->
    <!-- <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button> -->
    <h6 class="mt-0 pt-0 font-weight-bolder ml-auto d-block" style="margin: 8px 0px 0px 0px;">Welcome! We would love to know more about you.</h6>
    <!-- <a (click)="closeModal()" class="ml-auto d-block"
    style="width: fit-content; color: black; margin: 8px 0px 0px 0px;cursor:pointer;">
   X
 </a> -->
 <button (click)="closeModal()" aria-label="Close" class="close" type="button">
  <span aria-hidden="true">&times;</span>
</button>
  </div>
  
  <div class="modal-body p-4 pt-0">
    
    
    <div class="mt-1 bg-cdf5e9" *ngIf="coupon">
      <div class="text-center">
        <p class="m-0 mb-2">{{coupon.coupon_desc}}</p>
        <!-- <p class="m-0 mb-2"><b><span *ngIf="coupon.type == 'fixed'">RS </span>{{coupon.coupon_value}} <span *ngIf="coupon.type == 'percent'">%</span><span> off</span></b></p> -->
     <p class="m-0 mb-2">{{coupon.coupon_code}} <i 
      (click)="copyCouponCode(coupon.coupon_code)" 
      class="copy-icon" 
      [ngClass]="{ 'copied': copied }" 
      aria-label="Copy coupon code"
      style="cursor: pointer;">
      {{ copied ? '✔️' : '📋' }}
    </i></p>
     <!-- <p class="m-0 mb-2">{{coupon.coupon_desc}}</p> -->
      </div>
     
    </div>
  <div *ngIf="this.userlogin != 1">
    <app-google-login></app-google-login>
  </div>
    
  
  </div>
  