<!-- <p>journeycustomer works!</p> -->
<!-- <p>signup works!</p> -->
<div class="modal-header p-1 pr-3 pt-2" style="border-bottom: none; margin-bottom: -10px;">
  <!-- <h4 class="modal-title">Hi there!</h4> -->
  <!-- <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div class="modal-body p-4 pt-0">
  <h6 class="mt-0 pt-0 font-weight-bolder">Welcome! We would love to know more about you.</h6>

  <div class="mt-1">
    <label>Name</label>
    <input [(ngModel)]="name" class="form-control" placeholder="Enter name" required type="text">
    <div style="text-align: left">
      <p *ngIf="!validName" class="error-label">{{validNameText}}</p>
    </div>

    <label>Email</label>
    <input [(ngModel)]="email" class="form-control" placeholder="Enter email" required type="text">
    <div style="text-align: left">
      <p *ngIf="!validEmail" class="error-label">{{validEmailText}}</p>
    </div>

    <label>Phone Number</label>
    <input [(ngModel)]="phone" class="form-control" placeholder="Enter phone" required type="number">
    <div style="text-align: left">
      <p *ngIf="!validPhone" class="error-label">{{validPhoneText}}</p>
    </div>
  </div>

  <button (click)="Submit()" class="btn btn-primary font-weight-bolder btn-block" style="margin: 10px 0px 0px 0px;">
    Submit
  </button>

  <a (click)="closeModal()" class="ml-auto d-block"
     style="width: fit-content; color: #E26812; margin: 8px 0px 0px 0px;cursor:pointer;">
    Skip
  </a>

</div>
