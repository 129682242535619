import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ratingFilter',
  pure: false
})
export class RatingFilterPipe implements PipeTransform {

  transform(items: any, element:string, value:any) {
    if(items != null && items != undefined && items.length > 0 && value != undefined && value.length > 0)
    {
      if(items != undefined )
      {
        if (value.length > 0) 
        {
          return this.filterRating(items, value, element);
        }
        else
        {
          return items;
        }
      }
    }
    else
    {
      return items;
    }
    return items;
  }

  filterRating(mainItems, ratingitems, element)
  {
    let finalItems = [];
    mainItems.filter((mainItem:any) => {
      if(ratingitems.filter(x => mainItem[element] == x).length > 0)
        {
          finalItems.push(mainItem);
        }     
    });
    return finalItems.filter(function(item, index) { return finalItems.indexOf(item) >= index;});
  }

}
