<!-- <p>footer works!</p> -->
<!-- <p>footer works!</p> -->
<!-- <div class="ninth_div bg-white" style="background: #FFFFFF; height: fit-content;
box-shadow: 0px -6px 15px rgba(196, 196, 196, 0.15); padding: 2% 4% 2% 4%;">
  <div class="container-fluid">
    <div class="row font-weight-bolder">
      <div class="col-xl-3 col-lg-3 col-sm-6 col-6 align-self-center ">
        <div class="" style="width: fit-content; margin: auto 2%;">
          <img alt="" src="./assets/icons/Logo.svg" class="img-fluid m-auto d-block"
            style="width: 50px; height: 50px; border-radius: 50%;">

          <div class="d-flex social_icons mt-2" style="width: fit-content;">
            <img alt="" src="./assets/icons/linkdein.svg" class="img-fluid" style="width: 20px; height: 20px; margin: auto 5px;">
          <img alt="" src="./assets/icons/facebook.svg" class="img-fluid" style="width: 20px; height: 20px; margin: auto 5px;">
          <img alt="" src="./assets/icons/instagram.svg" class="img-fluid" style="width: 20px; height: 20px; margin: auto 5px;">
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-sm-6 col-6 align-self-center ">
        <p>OUR CHEFS</p>
        <p>OUR STORY</p>
        <p>HOW IT WORKS</p>
      </div>
      <div class="col-xl-3 col-lg-3 col-sm-6 col-6 align-self-center ">
        <p>HELP</p>
        <p>CONTACT US</p>
        <p>FAQ</p>
      </div>
      <div class="col-xl-3 col-lg-3 col-sm-6 col-6 align-self-center ">
        <p>BECOME A CHEF</p>
        <p>JOIN</p>
      </div>
    </div>
  </div>

  <br>

  <div class="row flex-column-reverse flex-sm-row align-bottom">
    <div class="col-auto">
      <p class="font-weight-bolder">Company Name © {{currentYear}} All Rights Reserved</p>
    </div>
    <div class="col-auto d-lg-flex ml-lg-auto ml-sm-auto">
      <p class="mr-lg-1 font-weight-bolder">Privacy Policy</p>
      <p class="font-weight-bolder">Terms & Conditions</p>
    </div>
  </div>
</div> -->

<!-- <div class="ninth_div bg-white
d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block ml-auto
" style="background: #FFFFFF; height: fit-content;
box-shadow: 0px -6px 15px rgba(196, 196, 196, 0.15); padding: 2% 4% 2% 4%;">
  <div class="container-fluid">
    <div class="row font-weight-bolder">
      <div class="col-xl-3 col-lg-3 col-sm-6 col-6 align-self-center ">
        <div class="" style="width: fit-content; margin: auto 2%;">
          <img alt="" src="./assets/icons/Logo.svg" class="img-fluid m-auto d-block"
            style="width: 70px; height: 70px; border-radius: 50%;">

          <div class="d-flex social_icons mt-3" style="width: fit-content;">

            <a href="https://www.linkedin.com/company/we-the-chefs" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
            <a href="https://www.instagram.com/wethechefs.in/" target="_blank"><i class="fa fa-instagram instagram_logo" aria-hidden="true"></i></a>
            <a href="https://www.facebook.com/WeTheChefsIndia" target="_blank"><i class="fa fa-facebook"
                aria-hidden="true"></i></a>
            <a href="https://www.pinterest.co.uk/WTCWeTheChefs" target="_blank"><i class="fa fa-pinterest-p"
                aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl col-lg col-sm col align-self-center" style="margin-top: 40px;">
        <div class="d-flex justify-content-around">
          <p routerLink="/become-a-chef" (click)="openMeetChefs()">OUR CHEFS</p>
          <p routerLink="/our-story">OUR STORY</p>
          <p (click)="openHowItWorks()" routerLink="/home">HOW IT WORKS</p>
          <p routerLink="/contact-us">CONTACT US</p>
          <p (click)="openFAQ()" routerLink="/home">FAQ</p>

          <p routerLink="/become-a-chef">BECOME A CHEF</p>
        </div>
        <div class="d-flex justify-content-start">
        </div>
        <div class="d-flex mr-lg-5 ml-5 mt-4" style="font-style: italic;">
          <p class="font-weight-bolder mr-auto mt-2">WETHECHEFS FOOD SOLUTIONS (OPC) PVT LTD © {{currentYear}} All Rights Reserved
          </p>
          <p class="mr-lg-5 font-weight-bolder mt-2 text-nowrap ml-md-2" routerLink="/privacy-policy">Privacy Policy</p>
          <p class="font-weight-bolder ml-4 mt-2 text-nowrap" (click)="opentermsandconditions()">Terms & Conditions</p>
        </div>
      </div>
    </div>
  </div>
  <br>
</div> -->

<div class="py-20-40" style="background:#E2E2E2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-4 jutfy-center">
        <div class="pt-2 ft-logo-section pb-0">
        <div class="ft-logo-img">
          <img alt="" src="./assets/icons/new_icons/new_logo_wtc.svg" class="img-fluid m-auto d-block"style="width: 85px; height: 92px; border-radius: 50%;">
            </div>
          <p class="pb-0 mb-0">We The Chefs deliver authentic home-made Indian and international cuisines to food lovers across India.</p>
      </div>
    </div>
      <div class="col-sm-4 jutfy-center">
        <div class="py-2 ft-menu-link">
          <h4 class="mb-15-px" style="cursor: default;">Menu Link</h4>
<ul>
  <li class="cursorpointer" (click)="openSameChefDay()">Same-Day Delivery</li>
  <li class="cursorpointer" [routerLink]="['/search-cuisine/book-your-order']">Pre-Order</li>
  <li class="cursorpointer" (click)="openCurated()">Events</li>
  <li class="cursorpointer" [routerLink]="['/our-story']">About Us</li>
  <li class="cursorpointer" routerLink="/become-a-chef">Become a Chef</li>
  <li class="cursorpointer" (click)="openBlog()">Blog</li>

  <!-- <li class="mb-2" routerLink="/become-a-chef">BECOME A CHEF</li> -->
</ul>
        </div>
      </div>
      <div class="col-sm-4 jutfy-center">

        <div class="py-2 ft-connect-us">
          <h4 class="mb-15-px mobile-mt-0" style="cursor: default;">Connect with us</h4>
          <ul>
          <li><a href="https://wa.me/918800299840?text=Hi" target="_blank">WhatsApp</a></li>
          <li><a href="https://www.instagram.com/wethechefs.in/" target="_blank">Instagram</a></li>
          <li><a href="https://www.facebook.com/WeTheChefsIndia" target="_blank">Facebook</a></li>
          <li><a href="https://www.linkedin.com/company/wethechefs" target="_blank">LinkedIn</a></li>
          <li><a href="https://www.pinterest.co.uk/WTCWeTheChefs" target="_blank">Pinterest</a></li>
          </ul>
        </div>

      </div>
    </div>
    <div class="row pd-29 mb-p-0">
      <div class="pb-2 col-lg-12 pt-0 hr-mb">
        <hr class="m-2-43px hr-line-margin">
      </div>
      <div class="col-sm-2">
        <p class="pb-10-px mr-auto m-0 text-footer-p px-0-43 mb-p-0" routerLink="/privacy-policy">Privacy Policy
        </p>
      </div>
      <div class="col-sm-3">
        <p class="pb-10-px m-0 text-nowrap text-footer-p" (click)="opentermsandconditions()">Terms & conditions</p>
      </div>
      <div class="col-sm-3">
        <p class="pb-10-px m-0 text-nowrap text-footer-p" ><b class="mr-5-px">©</b>Wethechefs, Inc. {{currentYear}}.All Rights Reserved.</p>
      </div>
    </div>
  </div>
</div>


<!-- <div class="d-sm-block d-xs-block d-lg-none d-md-block d-xl-none bg-white p-2">
  <div class="text-center pt-5">
    <p class="font-weight-bolder mb-2" routerLink="/become-a-chef" (click)="openMeetChefs()">OUR CHEFS</p>
    <p class="font-weight-bolder mb-2" routerLink="/our-story">OUR STORY</p>
    <p class="font-weight-bolder mb-2" (click)="openHowItWorks()" routerLink="/become-a-chef">HOW IT WORKS</p>

    <p class="font-weight-bolder mb-2" routerLink="/contact-us">CONTACT US</p>
    <p class="font-weight-bolder mb-2" (click)="openFAQ()" routerLink="/home">FAQ</p>

    <p class="font-weight-bolder mb-2" routerLink="/become-a-chef">BECOME A CHEF</p>
  </div>
  <div class="" style="width: fit-content; margin: auto;">
    <img alt="" src="./assets/icons/Logo.svg" class="img-fluid m-auto d-block"
      style="width: 70px; height: 70px; border-radius: 50%;">

    <div class="d-flex social_icons mt-3" style="width: fit-content;">
      <a href="https://www.linkedin.com/company/we-the-chefs" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
            <a href="https://www.instagram.com/wethechefs.in/" target="_blank"><i class="fa fa-instagram instagram_logo" aria-hidden="true"></i></a>
            <a href="https://www.facebook.com/WeTheChefsIndia" target="_blank"><i class="fa fa-facebook"
                aria-hidden="true"></i></a>
            <a href="https://www.pinterest.co.uk/WTCWeTheChefs" target="_blank"><i class="fa fa-pinterest-p"
                aria-hidden="true"></i></a>
    </div>
  </div>
  <br>
  <div class="d-flex justify-content-around">
    <p class="font-weight-bolder mb-2 text-nowrap" routerLink="/privacy-policy">Privacy Policy</p>
    <p class="font-weight-bolder mb-2 text-nowrap" (click)="opentermsandconditions()">Terms & Conditions</p>
  </div>
  <p class="font-weight-bolder m-auto" style="text-align: center;">WETHECHEFS FOOD SOLUTIONS (OPC) PVT LTD © {{currentYear}} All
    Rights Reserved</p>
  <br>
  <br>
</div> -->

<!-- <div class="ninth_div bg-white" style="background: #FFFFFF; height: fit-content;
box-shadow: 0px -6px 15px rgba(196, 196, 196, 0.15); padding: 2% 4% 2% 4%;">
  <div class="container-fluid">
    <div class="row font-weight-bolder">
      <div class="col-xl-auto col-lg-auto col-sm-auto col-auto">
        <div class="" style="width: fit-content; margin: auto 2%;">
          <img alt="" src="./assets/icons/Logo.svg" class="img-fluid m-auto d-block"
            style="width: 80px; height: 80px; border-radius: 50%;">
        </div>
      </div>
      <div class="col-xl col-lg col-sm col">
        <div class="d-flex justify-content-around">
            <p>OUR CHEFS</p>
            <p>OUR STORY</p>
            <p>HOW IT WORKS</p>

            <p>HELP</p>
            <p>CONTACT US</p>
            <p>FAQ</p>

            <p>BECOME A CHEF</p>
            <p>JOIN</p>
        </div>
        <div class="d-flex justify-content-around">
          <p class="font-weight-bolder">Company Name © {{currentYear}} All Rights Reserved</p>
          <p class="mr-lg-1 font-weight-bolder">Privacy Policy</p>
          <p class="font-weight-bolder">Terms & Conditions</p>
          <div class="d-flex justify-content-around social_icons" style="width: 20%;">
            <p>
              <img alt="" src="./assets/icons/linkdein.svg" class="img-fluid"
              style="width: 20px; height: 20px; ">
            </p>
            <p>
              <img alt="" src="./assets/icons/facebook.svg" class="img-fluid"
              style="width: 20px; height: 20px; ">
            </p>
            <p>
              <img alt="" src="./assets/icons/instagram.svg" class="img-fluid"
              style="width: 20px; height: 20px; ">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br>
</div> -->
