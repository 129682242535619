import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'servingFilter',
  pure: false
})
export class ServingFilterPipe implements PipeTransform {


  transform(items: any, element:string, value:any) {
    if(items != null && items != undefined && items.length > 0 && value != undefined && value.length > 0)
    {
      if(items != undefined )
      {
        if (value.length > 0) 
        {
          return this.filterServing(items, value, element);
        }
        else
        {
          return items;
        }
      }
    }
    else
    {
      return items;
    }
    return items;
  }

  filterServing(mainItems, servingitems, element)
  {
    let finalItems = [];
    mainItems.filter((mainItem:any) => {
      mainItem.item.forEach(serve => {
        if(servingitems.filter(x => serve[element].indexOf(x) != -1).length > 0)
        {
          finalItems.push(mainItem);
        }         
      });
    });
    return finalItems.filter(function(item, index) { return finalItems.indexOf(item) >= index;});
  }

}
