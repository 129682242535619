<!-- <p>authmodal works!</p> -->
<div class="order">
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" data-dismiss="modal">&times;</button>
    <br>
    <img src="./assets/icons/Logo.svg" style="width: 100px; height: 100px; border-radius: 50%;" alt="">
    <br>
    <h4 class="modal-title">Login / Sign Up</h4>
    <!-- <p>Your order will be accepted by the chef and<br> will head right to you.</p> -->

    <br>

    <button class="btn btn-primary text-uppercase btn-block m-auto d-block w-75 rounded-0" (click)="login()">Login</button>
    <div style="margin: 5px;"></div>
    <button class="btn btn-primary text-uppercase btn-block m-auto d-block w-75 rounded-0" (click)="signup()">Signup</button>
</div>

