import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tandc',
  templateUrl: './tandc.component.html',
  styleUrls: ['./tandc.component.scss']
})
export class TandcComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

}
