import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {APIService} from "../services/api.service";
import {UtilService} from "../services/util.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  @Input() orderId;

  orderDetail = null;
  eventDetail = null;
  splitGST = false;
  cart_quantity = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private apiService: APIService,
    public utilService: UtilService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.cart_quantity = 0;
    this.apiService.getAPI(this.apiService.BASE_URL + "order/getOrderById/" + this.orderId).then((result) => {
      if (result.status) {
        if (result.result.event_id != null) {
          this.eventDetail = result.result;
          this.cart_quantity += this.eventDetail.total_items;
          if (this.eventDetail.order_address.formatted_address.toLowerCase().includes("haryana")) {
            this.splitGST = true;
          } else {
            this.splitGST = false;
          }
        } else {
          this.orderDetail = result.result;
          for (let i = 0; i < this.orderDetail.orderItems.length; i++) {
            this.cart_quantity += this.orderDetail.orderItems[i].quantity;
          }

          if (this.orderDetail.order_address.formatted_address.toLowerCase().includes("haryana")) {
            this.splitGST = true;
          } else {
            this.splitGST = false;
          }
        }
      } else {
        this.toaster.error(result.message);
      }
    }, (error) => {
      // console.log("error:");
    })
  }

  dismiss() {
    this.activeModal.close(OrderDetailComponent);
  }

}
