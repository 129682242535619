import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {UtilService} from "../../services/util.service";
import {APIService} from "../../services/api.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-coupon-page',
  templateUrl: './coupon-page.component.html',
  styleUrls: ['./coupon-page.component.scss']
})
export class CouponPageComponent implements OnInit {
  coupon;
userlogin;
copied: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    public utilService: UtilService,
    private apiService: APIService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.getCoupon();
    this.userlogin = localStorage.getItem("WTC_USER_LOGIN")
    console.log(this.userlogin,"lskdf")
  }
  

  copyCouponCode(couponCode: string) {
    navigator.clipboard.writeText(couponCode).then(() => {
      this.copied = true;
      setTimeout(() => {
        this.copied = false; // Reset the copied state after a delay
      }, 2000); // Change duration as needed
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }
 
getCoupon(){
  this.apiService.getAPI(this.apiService.BASE_URL + "chefstatus/getBroadCastActiveCoupon").then((result) => {
    if (result.status) {
      this.coupon = result.result;
    }
  }, (error) => {
    console.log("error");
  })
}
  closeModal() {
    this.activeModal.close();
  }
}
