import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../login/login.component';
import {SignupComponent} from '../signup/signup.component';

@Component({
  selector: 'app-authmodal',
  templateUrl: './authmodal.component.html',
  styleUrls: ['./authmodal.component.scss']
})
export class AuthmodalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private router: Router, private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  login() {
    // this.activeModal.close(AuthmodalComponent);


    const modalRef = this.modalService.open(LoginComponent, {size: ''});
    modalRef.componentInstance.name = 'World';
  }

  signup() {
    const modalRef = this.modalService.open(SignupComponent, {size: ''});
    modalRef.componentInstance.name = 'customer';
  }
}
