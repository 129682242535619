<!-- <p>checkout works!</p> -->
<!-- <app-navbar></app-navbar> -->

<section class="main-box">
  <div class="m-0">
  
    <div class="row w-100 p-0 m-0">

      <div class="col-lg-8 col-sm-12 col-12">
      
        <div class="select-time">
          <div class="row p-lg-2">
            <h3 class="chk-out">Checkout</h3>
            
         
            <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="m-auto d-block" style="width: fit-content;">
                <h5 class="font-weight-bolder mt-1 mb-1" style="text-align: center;">SELECT TIME</h5>
                <div class="select_buttons">
                  <button *ngIf="chefProfile?.min_time !=12" (click)="getTime('11')" [disabled]="!btn_9_en" class="btn" [class]="btn_11_class" id="btn0">
                    11AM - 2PM
                  </button>
                  <button *ngIf="chefProfile?.min_time !=12" (click)="getTime('18')" [disabled]="!btn_12_en" class="btn" [class]="btn_18_class" id="btn1">
                    6PM - 9PM
                  </button>
                  <button *ngIf="chefProfile?.min_time==12" (click)="getTime('19')" [disabled]="!btn_7_en" [class]="btn_7_class" class="btn"  id="btn2">
                    Same Day Delivery By 7PM 
                  </button>
                </div>
              </div>
            </div> -->


<div class="col-lg-12 col-md-12  col-12">
  <div class="flex">
    <p class="log-content">Logged in as <span> {{profile_name}}</span></p>
<p class="log-content">Order Date <span>{{showDate}}</span></p>
  </div>
  <div [ngClass]="addDisabled == false ? 'doted-bdr unactive' : 'doted-bdr active'">
  <div class="delivery-time pdt-0 mrg-top-20">

    <div class="m-auto d-block">
      <span [ngClass]="addDisabled == false ? 'time-icon' : 'time-icon' " class="" >
        <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
        <img src="../../../assets/icons/new_icons/new_icons/time-active-white.png" alt="">
      </span>
      <h5 class="devry-text mt-1 mb-1 ">Delivery Date and Time</h5>
<div class="row">
  <div class="col-xl-5 col-lg-6 col-md-6  col-12 pd-all-0">
              
    <div class="m-auto d-block">
      <!-- <h5 class="font-weight-bolder mt-1 mb-1" style="text-align: center;">SELECT </h5> -->
      <ngb-datepicker #dp (dateSelect)="onDateSelected()" [startDate]="startDate" (navigate)="date = $event.next" [(ngModel)]="model"
        [minDate]="minDate"></ngb-datepicker>
    </div>
  </div>
  <div class="col-xl-7 col-lg-6 col-md-6 col-12 pd-all-0 chkbtn-pd">
    <div class="select_buttons mt-0 ">
      <button *ngIf="chefProfile?.min_time !=12 && chefProfile?.id !=732" (click)="getTime('11','1')" [disabled]="!btn_9_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_11_class" id="btn0">
        11AM - 2PM
      </button>
      <button *ngIf="chefProfile?.min_time !=12 && chefProfile?.id !=732" (click)="getTime('18','2')" [disabled]="!btn_12_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_18_class" id="btn1">
        6PM - 9PM
      </button>
      <!-- <button *ngIf="chefProfile?.min_time==12 && chefProfile?.id !=732 && chefProfile?.id !=549"  (click)="getTime('19','3')" [disabled]="!btn_7_en" [class]="btn_7_class" class="btn slect-btn  col-md-12 col-lg-12 col-12 "  id="btn2">
        Same Day Delivery By 7PM
      </button> -->
      <button *ngIf="chefProfile?.min_time==12 && chefProfile?.id !=732 && chefProfile?.id !=549" (touchstart)="getTime('13','16')"  (click)="getTime('13','16')" [disabled]="!btn_20_en" [class]="btn_20_class" class="btn slect-btn  col-md-12 col-lg-12 col-12 "  id="btn2">
        1pm - 3pm
      </button>  <button *ngIf="chefProfile?.min_time==12 && chefProfile?.id !=732 && chefProfile?.id !=549" (touchstart)="getTime('15','17')"  (click)="getTime('15','17')" [disabled]="!btn_21_en" [class]="btn_21_class" class="btn slect-btn  col-md-12 col-lg-12 col-12 "  id="btn20">
        3pm - 7pm
      </button>
      <button *ngIf="chefProfile?.min_time==12 && chefProfile?.id !=732 && chefProfile?.id !=549" (touchstart)="getTime('19','18')"  (click)="getTime('19','18')" [disabled]="!btn_22_en" [class]="btn_22_class" class="btn slect-btn  col-md-12 col-lg-12 col-12 "  id="btn21">
      7pm - 10pm
    </button>
      <!-- <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getDTime('15:30')" [disabled]="!btn_3_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_3_class" id="btn3">
        3:30 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getTime('16','4')" [disabled]="!btn_4_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_4_class" id="btn4">
        4:00 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getDTime('16:30')" [disabled]="!btn_4_3_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_4_3_class" id="btn5">
        4:30 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getTime('17','5')" [disabled]="!btn_5_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_5_class" id="btn6">
        5:00 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getDTime('17:30')" [disabled]="!btn_5_3_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_5_3_class" id="btn7">
        5:30 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getTime('18','6')" [disabled]="!btn_6_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_6_class" id="btn8">
        6:00 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getDTime('18:30')" [disabled]="!btn_6_3_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_6_3_class" id="btn9">
        6:30 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getTime('19','7')" [disabled]="!btn_07_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_07_class" id="btn10">
        7:00 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getDTime('19:30')" [disabled]="!btn_7_3_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_7_3_class" id="btn11">
        7:30 PM
      </button>
      <button *ngIf="chefProfile?.id ==732 && chefProfile?.min_time==12" (click)="getTime('20','8')" [disabled]="!btn_8_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_8_class" id="btn12">
        8:00 PM
      </button>
      <button *ngIf="chefProfile?.id ==549 && chefProfile?.min_time==12" (click)="getTime('14','9')" [disabled]="!btn_13_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_13_class" id="btn13">
        1 PM - 2 PM
      </button>
      <button *ngIf="chefProfile?.id ==549 && chefProfile?.min_time==12" (click)="getTime('15','10')" [disabled]="!btn_14_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_14_class" id="btn14">
        2 PM - 3 PM
      </button>
      <button *ngIf="chefProfile?.id ==549 && chefProfile?.min_time==12" (click)="getTime('16','11')" [disabled]="!btn_15_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_15_class" id="btn15">
        3 PM - 4 PM
      </button>
      <button *ngIf="chefProfile?.id ==549 && chefProfile?.min_time==12" (click)="getTime('17','12')" [disabled]="!btn_16_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_16_class" id="btn16">
        4 PM - 5 PM
      </button>
      <button *ngIf="chefProfile?.id ==549 && chefProfile?.min_time==12" (click)="getTime('18','13')" [disabled]="!btn_17_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_17_class" id="btn17">
        5 PM - 6 PM
      </button>
      <button *ngIf="chefProfile?.id ==549 && chefProfile?.min_time==12" (click)="getTime('19','14')" [disabled]="!btn_18_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_018_class" id="btn18">
        6 PM - 7 PM
      </button>
      <button *ngIf="chefProfile?.id ==549 && chefProfile?.min_time==12" (click)="getTime('20','15')" [disabled]="!btn_19_en" class="btn slect-btn  col-md-12 col-lg-12 col-12 " [class]="btn_19_class" id="btn19">
        7 PM - 8 PM
      </button> -->
      <button (click)="getActive(true)" *ngIf="addDisabled==false"
      class="col-lg-12  col-md-12 col-12 btn btn-primary text-white btn-b lock font-weight-bolder">Continue
    </button>
    </div>
  </div>
</div>



     
     
    </div>

  </div>
  </div>
<div [ngClass]="addPaymentDisabled == false ? 'doted-bdr2 unactive' : 'doted-bdr2 active'">
<div class="delivery-adrs">

<div class="m-auto">

  <!-- <span [ngClass]="addDisabled == false ? 'true-time-icon' : 'time-icon'"  ><i class="fa fa-map-marker"></i></span> -->
  <span *ngIf="addDisabled == false" class="true-time-icon" ><img src="../../../assets/icons/new_icons/new_icons/food-delivery-grey.png" alt=""></span>
  <span *ngIf="addDisabled == true" class="time-icon" > <img src="../../../assets/icons/new_icons/new_icons/food-delivery-white.png" alt=""></span>
  <h5 class="devry-text mt-1 mb-1 " [ngClass]="addDisabled == false ? 'disabBTn' : ''">Select Delivery Address  </h5>
  <div class="row" *ngIf="addDisabled==true">
        <div *ngFor="let address of userAddresses;let i=index;" class="col-lg-4  col-md-6  col-sm-6 col-12 pl-0 pd-rght-15" >
          <div class="" [ngClass]="{
            'btnd hom-adrs': true,  
            'btn-selectedd': address.selected 
          }" (click)="onAddressSelected(i)">
          <!-- <div class="check-boxx pl-2">
            <input (ngModelChange)="onAddressSelected(i)" [(ngModel)]="address.selected" class="form-check-input"
              type="checkbox">
          </div> -->
          <div class="check-fig">
            <h5 class="mb-0 adrs-txt"><i class="fa fa-home" *ngIf="address.address_type == 'Home'"></i>
              <i class="fa fa-building" aria-hidden="true" *ngIf="address.address_type == 'Office'"></i>
              <i class="fa fa-map-marker" aria-hidden="true" *ngIf="address.address_type == 'Other'"></i>
              {{address.address_type}} <span (click)="editAddress(address)" style="font-size: 10px; padding-left: 10px;" class="cursorpointer flt-rght"><i class="fa fa-edit fs-14-px"></i></span>
              <span (click)="deleteAddress(address)" style="font-size: 10px;margin-left: 10px; padding-right: 10;" class="cursorpointer flt-rght"><i class="fa fa-trash fs-14-px" aria-hidden="true"></i></span></h5>
            <!-- <p class="mb-0 indnt-25 minheight-adrs" *ngIf="viewMore==false" (click)="viewMoreBtn()">

              {{(address.address.length > 34) ? (address.address | slice:0:34) + '...' : (address.address)}}
              <span *ngIf="address.address.length > 34" style="font-weight: bold;">Read More</span>
            </p>
            <p class="mb-0 indnt-25 minheight-adrs" *ngIf="viewMore==true" (click)="viewMoreBtn()">

              {{address.address}} <b>Read Less</b>
            </p> -->
            <p class="mb-0 indnt-25 minheight-adrs" *ngIf="!address.expanded" (click)="viewMoreBtn(address)">
              {{ (address.address.length > 34) ? (address.address | slice: 0:34) + '...' : address.address }}
              <span *ngIf="address.address.length > 34" style="font-weight: bold;">Read More </span>
            </p>
            <p class="mb-0 indnt-25 minheight-adrs" *ngIf="address.expanded" (click)="viewMoreBtn(address)">
              {{ address.address }} <b>Read Less</b>
            </p>
          </div>
          <div class="pd-btn-delvry">
            <button
            [ngClass]="{
              'btnButton btn btn-outline-primary  add-new-btn btn_add': true,  
              'btn-selectedButton': address.selected 
            }"
            
          >
          Deliver here
          </button>
          </div>
        </div>
      </div>
  
    <div class=" col-lg-4  col-md-6 col-sm-6 col-12 pl-0 pdr-0 pd-rght-15">
      <div class="new-adrs">
<div class="py-0">
  <h3 class="adrs-txt"><i class="fa fa-map-marker"></i> Add new address</h3>
  <p class="adrs-pera minheight-adrs">Earlier located address</p>
</div>

        <div class="pd-btn-new">
          <button (click)="addNewAddress()" class="btn btn-outline-primary add-new-btn btn_add">Add New 
        </button>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</div>
<div class="payment-mthd">
  <!-- <div [ngClass]="addPaymentDisabled == false ? 'doted-bdr2 unactive' : 'doted-bdr2 active'"> -->
<div class="payment-methd">
  <div class="m-auto d-block">
    <!-- <span [ngClass]="addPaymentDisabled == false ? 'true-time-icon' : 'time-icon'"><i class="fa fa-money"></i></span> -->
    <span *ngIf="addPaymentDisabled == false" class="true-time-icon"><img src="../../../assets/icons/new_icons/new_icons/wallet-grey.png" alt=""></span>
    <span *ngIf="addPaymentDisabled == true" class="time-icon"><img src="../../../assets/icons/new_icons/new_icons/wallet-white.png" alt=""></span>
    <h5 class="devry-text mt-1 mb-1 " [ngClass]="addPaymentDisabled == false ? 'disabBTn' : ''">Choose Payment Method</h5>
    <div class="select_bu ttons col-sm-4 col-md-6 col-lg-4 col-12 p-0" *ngIf="addPaymentDisabled == true">
      <button (click)="getUserDetail(true)"
      [disabled]="remainingAmount > 0"
                class="btn btn-primary text-white btn-block font-weight-bolder paymet-btn">Proceed to pay
              </button>
              <p *ngIf="remainingAmount > 0" style="color: red; font-weight: 600; margin-left: 10px;">
                Add items worth Rs. {{ remainingAmount }} more to proceed.
              </p>
    </div>
  </div>

</div>
</div>


</div>

          </div>
        </div>
        <br>
        <!-- <div class="delivery">
          <h3>DELIVERY ADDRESS</h3>
          <div *ngFor="let address of userAddresses;let i=index;" class="ml-3">
            <div class="check-boxx pl-2">
              <input (ngModelChange)="onAddressSelected(i)" [(ngModel)]="address.selected" class="form-check-input"
                type="checkbox">
            </div>
            <div class="check-fig">
              <h5>{{address.address_type}} <span (click)="editAddress(address)" style="font-size: 10px;" class="cursorpointer">Edit</span>
                <span (click)="deleteAddress(address)" style="font-size: 10px;margin-left: 10px;" class="cursorpointer">Delete</span></h5>
              <p>{{address.address}}</p>
            </div>
          </div>

          <button (click)="addNewAddress()" class="btn btn-outline-primary rounded-0 pl-5 pr-5 btn_add"><i
              aria-hidden="true" class="fa fa-plus"></i> &nbsp; ADD NEW ADDRESS
          </button>
        </div> -->

<!--        <div class="payment" style="visibility: hidden;">-->
<!--          <h3>DISTANCE ({{distance}} km)</h3>-->
<!--          <div class="row"></div>-->
<!--          <div class="row">-->
<!--            &lt;!&ndash; <div #gmap style="width:100%;height:100%;min-height:400px; background-color: aliceblue; border-radius: 10px;"></div> &ndash;&gt;-->
<!--            <div #gmap style="width:100%;height:100%;min-height:0px; background-color: aliceblue; border-radius: 10px;"></div>-->
<!--          </div>-->
<!--        </div>-->

      </div>
      
      <div class="col-lg-4 side-1 mt-lg-0 mt-3 chef-section pt-16-px">

        
        <div>
          <p class="order-class">Your Order</p>
        </div>
        <div class="row" *ngIf="chefProfile!=null">
     
          
          <div class="col-auto pl-0">
            <img *ngIf="chefProfile!=null" [src]="profile_pic" style="width: 80px; height: 80px; border-radius: 0px;" alt="">
          </div>
          <div class="col pl-0 cursorpointer" (click)="openChefDetail(chefProfile.id)">
            
            <p class="mb-0 mt-0 chef-name">{{chefProfile.name}}</p>
            <p class="mb-0 specialises" style="font-size: 14px;">Specialises in {{chef_special_cuisine}}</p>
            <!-- <p class="font-weight-bolder mb-0" style="font-size: 12px;">CHEF</p> -->

            <!-- <button class="btn btn-primary btn-sm btn-block" (click)="openChefDetail(chefProfile.id)">See Menu</button> -->
          </div>
        </div>
        <div class="row" *ngIf="eventDetail!=null">
          <div class="col-auto pl-0">
            <img *ngIf="eventDetail!=null" [src]="utilService.getImageCDNURL(eventDetail.logo)" style="width: 80px; height: 80px; border-radius: 0px;" alt="">
          </div>
          <div class="col pl-0">
            <p class="font-weight-bolder mb-0" style="font-size: 12px;">Event</p>
            <p class="font-weight-bolder mb-0 mt-0">{{eventDetail.name}}</p>
            <button class="btn btn-primary btn-sm btn-block" (click)="openEventDetail(eventDetail.id)">See Menu</button>
          </div>
        </div>
        <div class="mt-2">
          <div *ngFor="let item of cartItems" class="row bg-white" style="margin-bottom:20px;">
            <div class="row main align-items-center pdx-0">
              <div class="col pl-0 align-self-baseline">
                <div class="row">
                  <h6 *ngIf="item.item_id!=null" class="item-name">{{item.itemDetail.name}}</h6>
                  <h6 *ngIf="item.event_id!=null" class="item-name">{{item.eventDetail.name}}</h6>
                </div>
                
              </div>
              <div class="col">
                <div class="row">
                 
                </div>
                <!-- <br> -->
                <div class="row float-left plus-minus">
                  <a (click)="remove(item)" class="cursor-pointer">-</a>
                  <a class="data_number fnt-16"><span>
                      {{item.quantity}}</span>
                  </a>
                  <a (click)="addItem(item)" class="cursor-pointer">+
                  </a></div>
                  <div class="d-flex float-right">
                    <div class="m-0 p-0 pr-1">
                      <i class="fa fa-inr" style="font-size: 16px;" aria-hidden="true"></i>
                    </div>
                    <div>
                      <h5 class="price-txt m-0 p-0 fnt-16"> {{item.price}}</h5>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <!-- <div class="row price_row">
            <div class="col-9">
              <h6 class="font-weight-bolder" >SUB TOTAL</h6>
              <h6 class="font-weight-bolder" >DELIVERY</h6>
              <h6 class="font-weight-bolder" >DELIVERY SERVICE TAX</h6>
              <h6 *ngIf="!splitGST" class="font-weight-bolder" >IGST</h6>
              <h6 *ngIf="splitGST" class="font-weight-bolder" >SGST</h6>
              <h6 *ngIf="splitGST" class="font-weight-bolder" >CGST</h6>
            </div>
            <div class="col-2 ml-auto price_money">
              <h6 class="font-weight-bolder" ><span><i class="fa fa-inr" aria-hidden="true"></i></span> {{totalPrice}}</h6>
              <h6 class="font-weight-bolder" ><span><i class="fa fa-inr" aria-hidden="true"></i></span> {{deliveryCharges}}</h6>
              <h6 class="font-weight-bolder" ><span><i class="fa fa-inr" aria-hidden="true"></i></span> {{deliveryServiceCharges}}</h6>
              <h6 *ngIf="!splitGST" class="font-weight-bolder" ><span *ngIf="!splitGST"><i class="fa fa-inr" aria-hidden="true"></i></span> {{totalTax}}</h6>
              <h6 *ngIf="splitGST" class="font-weight-bolder" ><span *ngIf="splitGST"><i class="fa fa-inr" aria-hidden="true"></i></span> {{totalTax/2}}</h6>
              <h6 *ngIf="splitGST" class="font-weight-bolder" ><span *ngIf="splitGST"><i class="fa fa-inr" aria-hidden="true"></i></span> {{totalTax/2}}</h6>
            </div>
          </div>

          <br>

          <div class="row price_row">
            <div class="col-9">
              <h6 class="text-primary font-weight-bolder">TOTAL</h6>
            </div>
            <div class="col-1 ml-auto inr_icon">
              <i class="fa fa-inr" aria-hidden="true"></i>
            </div>
            <div class="col-2 ml-auto price_money">
              <h6 class="text-primary font-weight-bolder">{{totalPrice + deliveryCharges + deliveryServiceCharges + totalTax}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h6 class="font-weight-bolder" style="color: #383838;">SUB TOTAL</h6>
            </div>
            <div class="col align-self-center text-right text-muted">
              <h6 class="font-weight-bolder" style="color: #9BA2A6;">Rs {{totalPrice}}</h6>
            </div>
          </div> -->
          <div class="row w-100 new_money_row">
            <div class="col pdx-0">
              <h6 class="bill-txt">Bill Details</h6>
            </div>
          
          </div>
          <div class="row w-100 new_money_row subtotal-row">
            <div class="col pdx-0">
              <h6 class="bill-summary-txt" style="color: #383838;">Subtotal</h6>
            </div>
            <div class="col-auto ml-auto align-self-center text-right text-muted d-flex">

              <div>
                <i class="fa fa-inr fnt-16" aria-hidden="true"></i>
              </div>
              <div class="my_width">
                <h6 class="bill-summary-txt fnt-16" >{{totalPrice}}</h6>
              </div>
            </div>
          </div>

          <div class="row w-100 new_money_row">
            <div class="col pdx-0">
              <h6 class="bill-summary-txt" style="color: #383838;">Delivery</h6>
            </div>
            <div class="col-auto ml-auto align-self-center text-right text-muted d-flex">

              <div>
                <i class="fa fa-inr fnt-16" aria-hidden="true"></i>
              </div>
              <div class="my_width">
                <h6 class="bill-summary-txt fnt-16" style="color: #383838;">{{deliveryCharges.toFixed(2)}}</h6>
              </div>
            </div>
          </div>

          <div class="row w-100 new_money_row">
            <div class="col pdx-0">
              <h6 class="bill-summary-txt" style="color: #383838;">GST On Delivery</h6>
            </div>
            <div class="col-auto ml-auto align-self-center text-right text-muted d-flex">
              <div>
                <i class="fa fa-inr fnt-16" aria-hidden="true"></i>
              </div>
              <div class="my_width">
                <h6 class="bill-summary-txt fnt-16" style="color: #383838;">{{deliveryServiceCharges.toFixed(2)}}</h6>
              </div>
            </div>
          </div>

          <div *ngIf="!splitGST" class="row w-100 new_money_row">
            <div class="col pdx-0">
              <h6 class="bill-summary-txt" style="color: #383838;">IGST</h6>
            </div>
            <div class="col-auto ml-auto align-self-center text-right text-muted d-flex">
              <div>
                <i class="fa fa-inr fnt-16" aria-hidden="true"></i>
              </div>
              <div class="my_width">
                <h6 class="bill-summary-txt fnt-16" style="color: #383838;"> <span>{{totalTax.toFixed(2)}}</span></h6>
              </div>
            </div>
          </div>

          <div *ngIf="splitGST" class="row w-100 new_money_row">
            <div class="col pdx-0">
              <h6 class="bill-summary-txt" style="color: #383838;">SGST</h6>
            </div>
            <div class="col-auto ml-auto align-self-center text-right text-muted d-flex">
              <div>
                <i class="fa fa-inr fnt-16" aria-hidden="true"></i>
              </div>
              <div class="my_width">
                <h6 class="bill-summary-txt fnt-16" style="color: #383838;">
                  <span>{{(totalTax / 2).toFixed(2)}}</span></h6>
              </div>
            </div>
          </div>

          <div *ngIf="splitGST" class="row w-100 new_money_row">
            <div class="col pdx-0">
              <h6 class="bill-summary-txt" style="color: #383838;">CGST</h6>
            </div>
            <div class="col-auto ml-auto align-self-center text-right text-muted d-flex">
              <div>
                <i class="fa fa-inr fnt-16" aria-hidden="true"></i>
              </div>
              <div class="my_width">
                <h6 class="bill-summary-txt fnt-16" style="color: #383838;">
                  <span>{{(totalTax / 2).toFixed(2)}}</span></h6>
              </div>
            </div>
          </div>

          <!-- <br> -->
          <div class="br-t-px"></div>

          <div class="row w-100">
            <div class="col pdx-0">
              <h6 class="text-primary bill-summary-txt fnt-bld">Total Amount</h6>
            </div>
            <div class="col-auto ml-auto align-self-center text-right text-muted d-flex">
              <div>
                <i class="fa fa-inr fnt-16 fnt-bld" style="color: #E26812;" aria-hidden="true"></i>
              </div>
              <div class="my_width">
                <h6 class="text-primary bill-summary-txt fnt-16 fnt-bld">
                  {{(totalPrice + deliveryCharges + deliveryServiceCharges + totalTax).toFixed(2)}}</h6>
              </div>
            </div>
          </div>
          <div class="row w-100 mt-2" *ngIf="couponApplied">
            <div class="col pdx-0">
              <h6 class="text-primary bill-summary-txt fnt-bld">Discounted Amount</h6>
            </div>
            <div class="col-auto ml-auto align-self-center text-right text-muted d-flex">
              <div>
                <i class="fa fa-inr fnt-16 fnt-bld" style="color: #E26812;" aria-hidden="true"></i>
              </div>
              <div class="my_width">
                <h6 class="text-primary bill-summary-txt fnt-16 fnt-bld">
                  {{ (discountedTotalAmount + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2)}}
                </h6>
              </div>
            </div>
          </div>
          <br>

          <div class="row w-100 pb-0 mb-0">
            <!-- <div class="col-12 p-0">
              <button (click)="getUserDetail(true)"
                class="btn btn-primary text-white btn-block bill-summary-txt">PLACE AN
                ORDER
              </button>
            </div> -->
            <br>
            <br>
            <div class="col-12 p-0">
              <div class="form-group">
                <label class="mb-0 mt-1">Coupon</label>
                <!-- <input [(ngModel)]="couponValue" class="form-control" maxlength="180" placeholder="Enter Coupon"
                  style="height: 40px; border-radius: 0; color: black;" type="text"/> -->
                  <!-- <select [(ngModel)]="couponValue" class="form-control" aria-placeholder="Please select cupon"> -->
                    <div class="input-group">
                      <div *ngIf="!couponApplied" class="w-100">
                    <select [(ngModel)]="couponValue" class="form-control" [disabled]="couponApplied">
                      <option [ngValue]="undefined" disabled selected> -- Please select coupon -- </option>
                    <option *ngFor="let coupon of couponData" [value]="coupon.coupon_code">{{ coupon.coupon_code }} {{coupon.coupon_desc}}</option>
                  </select>
                </div>
                  <div class="input-group-append d-flex justify-content-between w-100" style="border: dotted 5px grey;" *ngIf="couponApplied">
                    <p class="bill-summary-txt m-0 fnt-bld">{{couponValue}} Hurry {{coupon.coupon_desc}}</p>
                    <button  style="border:none; background:transparent;" class="fnt-bld bill-summary-txt m-0" type="button" (click)="removeCoupon()">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="message" style="margin-bottom: 5px;" [ngClass]="{'alert alert-success': message.includes('successfully'),'alert alert-danger': message.includes('Invalid') || message.includes('An error') || message.includes('exceed') || message.includes('Coupon is not applicable for this cart') || message.includes('No coupon found or Coupon is not yet valid')}">
                  {{ message }}
                                </div>
                <div style="text-align: right">
                  <button style="font-size:12px; border:none; background:transparent;" (click)="getCoupon(couponValue)"  [ngClass]="{'applied-button btn btn-primary': couponApplied}">{{ couponApplied ? 'Applied' : 'Apply' }}</button>
                </div>
               
              </div>
            </div>
            <div class="col-12 p-0">
              <div class="form-group">
                <label class="mb-0 mt-1">Comments For Chef</label>
                <textarea [(ngModel)]="comment" class="form-control" maxlength="180" placeholder="Enter Comment"
                  style="height: 80px; border-radius: 0; background: #fff; color: black;" type="text"></textarea>
                <div style="text-align: right">
                  <p style="font-size: 12px; color: lightslategrey;">{{180 - comment.length}} chars left</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- <div class="bg-primary ready_to_order" style="padding: 2% 4%;">
  <div class="row w-100 position-relative">
    <div class="col-xl col-lg col-md col-sm-12 col-12">
      <h6 class="text-white text-uppercase font-weight-bolder text-lg-left text-center">Ready to Order?</h6>
      <h2 class="text-white text-uppercase font-weight-bolder text-lg-left text-center">Let's personalize your menu
        selections.</h2>
    </div>
    <div class="col-xl-auto col-lg-auto col-md-auto col-sm-12 col-12" style="width: 500px; margin-top: 20px;">
      <button class="btn bg-white w-100 rounded-0 btn-block text-uppercase font-weight-bolder"
        routerLink="/customer-profile">Back to Profile
      </button>
    </div>
  </div>
</div> -->


<!-- <div style="background-color: #F0B241;; padding: 2% 4%;">
  <div class="row w-100">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
      <h6 class="font-weight-bolder text-white text-lg-left text-center">JUST IN YOUR POCKET</h6>
      <h2 class="font-weight-bolder text-white text-uppercase text-lg-left text-center">We are coming as an App soon </h2>
    </div>
    <div class="col-xl-4 col-lg-5 col-md-5 col-sm-4 col-12 align-self-center d-flex text-white">
      <div class="d-flex">
        <div style="margin: 10px;">
          <i aria-hidden="true" class="fa fa-google fa-2x"></i>
        </div>
        <div>
          <span style="font-size: 15px; margin: 0;">Get it on</span> <br> <span
            style="font-size: 20px; font-weight: 600; white-space: nowrap;"> Google
            Play </span>
        </div>
      </div>
      <div class="d-flex ml-auto">
        <div style="margin: 10px;">
          <i aria-hidden="true" class="fa fa-apple fa-2x"></i>
        </div>
        <div>
          <span style="font-size: 15px; margin: 0;">Dowload on the</span> <br> <span
            style="font-size: 20px; font-weight: 600; white-space: nowrap;">
            Apple Store </span>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <app-footer></app-footer> -->
<!--<div class="container">-->
<!--  <div #pdfTable id="pdfTable">-->
<!--    <div *ngIf="pdfOrder!=null" class="card" style="">-->
<!--      <div class="card-body">-->
<!--        <h1 style="color: #e26812; text-align: center; font-weight: 600; font-family: sans-serif; margin: 20px;">WE-->
<!--          THE CHEFS</h1>-->

<!--        <img src="https://www.wethechefs.in/tempimg/Logo.png"-->
<!--             style="position: absolute; top: 30px; right: 40px; width: 100px; height: 100px;" alt="">-->
<!--        <hr>-->

<!--        <div class="" style="display: flex; padding: 50px;">-->
<!--          <div class="" style="width: 45%; height: auto;">-->
<!--            <div class="" style="display: flex;">-->
<!--              <div class="" style="width: 40%; height: auto;">-->
<!--                <p>Invoice Number :</p>-->
<!--              </div>-->
<!--              <div class="" style="border: solid 1px black; width: 45%; height: auto;">-->
<!--                <p>{{pdfOrder.invoice_number}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="" style="display: flex;">-->
<!--              <div class="" style="width: 40%; height: auto;">-->
<!--                <p>Order Number :</p>-->
<!--              </div>-->
<!--              <div class="" style="border: solid 1px black; width: 45%; height: auto;">-->
<!--                <p>{{pdfOrder.uid}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="" style="display: flex;">-->
<!--              <div class="" style="width: 40%; height: auto;">-->
<!--                <p>Invoice Date :</p>-->
<!--              </div>-->
<!--              <div class="" style="border: solid 1px black; width: 45%; height: auto;">-->
<!--                <p>{{utilService.getDateTimeByTime(pdfOrder.created_at)}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="" style="display: flex;">-->
<!--              <div class="" style="width: 40%; height: auto;">-->
<!--                <p>Customer Name :</p>-->
<!--              </div>-->
<!--              <div class="" style="border: solid 1px black; width: 45%; height: auto;">-->
<!--                <p>{{pdfOrder.cus_name}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="" style="display: flex;">-->
<!--              <div class="" style="width: 40%; height: auto;">-->
<!--                <p>Customer Phone :</p>-->
<!--              </div>-->
<!--              <div class="" style="border: solid 1px black; width: 45%; height: auto;">-->
<!--                <p>{{pdfOrder.cus_phone}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="" style="width: 10%; height: auto;">-->
<!--          </div>-->
<!--          <div class="" style="width: 45%; height: auto;">-->
<!--            <div class="" style="display: flex;">-->
<!--              <div class="" style="width: 60%; height: auto;">-->
<!--                <p>Delivery Address :</p>-->
<!--              </div>-->
<!--              <div class="" style="border: solid 1px black; width: 45%; height: auto;">-->
<!--                <p>{{pdfOrder.order_address.address}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <br>-->

<!--        <table class="table table-bordered" border="1" cellpadding="0" cellspacing="0">-->
<!--          <thead style="background-color: lightgrey;">-->
<!--          <tr>-->
<!--            <td rowspan="1">S.No</td>-->
<!--            <td rowspan="1">Item Name</td>-->
<!--            <td rowspan="1">Qty</td>-->
<!--            <td rowspan="1">Unit Rate</td>-->
<!--            <td rowspan="1">Taxable Amount</td>-->
<!--            <td colspan="2">IGST</td>-->
<!--            <td colspan="2">CGST</td>-->
<!--            <td colspan="2">SGST</td>-->
<!--            <td rowspan="1">Grand Total</td>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td class="font-weight">%</td>-->
<!--            <td class="font-weight">Amount</td>-->
<!--            <td class="font-weight">%</td>-->
<!--            <td class="font-weight">Amount</td>-->
<!--            <td class="font-weight">%</td>-->
<!--            <td class="font-weight">Amount</td>-->
<!--            <td></td>-->
<!--          </tr>-->
<!--          <tr *ngFor="let item of pdfOrder.orderItems;let i=index;">-->
<!--            <td>{{(i + 1)}}</td>-->
<!--            <td>{{item.name}}</td>-->
<!--            <td>{{item.quantity}}</td>-->
<!--            <td>{{utilService.getFixedAmount(item.price / item.quantity, 2)}}</td>-->
<!--            <td>{{item.price}}</td>-->
<!--            <td>{{item.icgstper}}</td>-->
<!--            <td>{{item.icgstamount}}</td>-->
<!--            <td>{{item.sgstper}}</td>-->
<!--            <td>{{item.sgstamount}}</td>-->
<!--            <td>{{item.cgstper}}</td>-->
<!--            <td>{{item.cgstamount}}</td>-->
<!--            <td>{{item.totalamount}}</td>-->
<!--          </tr>-->

<!--          <tr>-->
<!--            <td><span style="visibility: hidden;">span</span></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--          </tr>-->

<!--          <tr class="font-weight">-->
<!--            <td></td>-->
<!--            <td>Total Item Cost</td>-->
<!--            <td>{{pdfOrder.totalQuantity}}</td>-->
<!--            <td></td>-->
<!--            <td>{{pdfOrder.taxAmount}}</td>-->
<!--            <td></td>-->
<!--            <td>{{utilService.getFixedAmount(pdfOrder.icgsttotal, 2)}}</td>-->
<!--            <td></td>-->
<!--            <td>{{utilService.getFixedAmount(pdfOrder.sgsttotal, 2)}}</td>-->
<!--            <td></td>-->
<!--            <td>{{utilService.getFixedAmount(pdfOrder.cgsttotal, 2)}}</td>-->
<!--            <td></td>-->
<!--          </tr>-->

<!--          <tr>-->
<!--            <td><span style="visibility: hidden;">span</span></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--          </tr>-->

<!--          <tr class="font-weight">-->
<!--            <td></td>-->
<!--            <td>Delivery Charges</td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td>{{pdfOrder.delivery_charges}}</td>-->
<!--            <td>{{pdfOrder.deliveryIgstPer}}</td>-->
<!--            <td>{{pdfOrder.deliveryIgst}}</td>-->
<!--            <td>{{pdfOrder.deliverySGSTPer}}</td>-->
<!--            <td>{{pdfOrder.deliverySGST}}</td>-->
<!--            <td>{{pdfOrder.deliverySGSTPer}}</td>-->
<!--            <td>{{pdfOrder.deliverySGST}}</td>-->
<!--            <td>{{pdfOrder.delivery_charges + pdfOrder.delivery_service_tax}}</td>-->
<!--          </tr>-->

<!--          <tr>-->
<!--            <td><span style="visibility: hidden;">span</span></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--          </tr>-->

<!--          <tr class="font-weight">-->
<!--            <td></td>-->
<!--            <td>Total Food Cost</td>-->
<!--            <td>{{pdfOrder.totalQuantity}}</td>-->
<!--            <td></td>-->
<!--            <td>{{pdfOrder.taxAmount + pdfOrder.delivery_service_tax}}</td>-->
<!--            <td></td>-->
<!--            <td *ngIf="pdfOrder.icgsttotal!=0">{{utilService.getFixedAmount(utilService.calculateTotal(pdfOrder.icgsttotal, pdfOrder.deliveryIgstAmount), 2)}}</td>-->
<!--            <td *ngIf="pdfOrder.icgsttotal==0">{{pdfOrder.icgsttotal}}</td>-->
<!--            <td></td>-->
<!--            <td *ngIf="pdfOrder.sgsttotal!=0">{{utilService.getFixedAmount(utilService.calculateTotal(pdfOrder.sgsttotal, pdfOrder.deliverySGST), 2)}}</td>-->
<!--            <td *ngIf="pdfOrder.sgsttotal==0">{{pdfOrder.sgsttotal}}</td>-->
<!--            <td></td>-->
<!--            <td *ngIf="pdfOrder.sgsttotal!=0">{{utilService.getFixedAmount(utilService.calculateTotal(pdfOrder.sgsttotal, pdfOrder.deliverySGST), 2)}}</td>-->
<!--            <td *ngIf="pdfOrder.sgsttotal==0">{{pdfOrder.sgsttotal}}</td>-->
<!--            <td>{{pdfOrder.total_price}}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->

<!--        <br>-->

<!--        <h3 class="" style="text-align: center; font-weight: 600; font-family: sans-serif; margin: 20px;">Unit of WETHECHEFS FOOD SOLUTIONS (OPC) PRIVATE LIMITED</h3>-->
<!--        <div class="" style="display: flex; border: solid 1px black;">-->
<!--          <div class="" style="width: 100px; margin-left: 10px;">Address</div>-->
<!--          <div class="border-left">A 2229, Ground floor, Greenfields Colony, Faridabad, Haryana -121010</div>-->
<!--        </div>-->
<!--        <div class="" style="display: flex; border: solid 1px black;">-->
<!--          <div class="col-2" style="width: 100px; margin-left: 10px;">Phone</div>-->
<!--          <div class="col border-left"></div>-->

<!--        </div>-->
<!--        <div class="" style="display: flex; border: solid 1px black;">-->
<!--          <div class="col-2" style="width: 100px; margin-left: 10px;">Email</div>-->
<!--          <div class="col border-left"></div>-->

<!--        </div>-->
<!--        <div class="" style="display: flex; border: solid 1px black;">-->
<!--          <div class="col-2" style="width: 100px; margin-left: 10px;">Website</div>-->
<!--          <div class="col border-left">www.WetheChefs</div>-->

<!--        </div>-->
<!--        <div class="" style="display: flex; border: solid 1px black;">-->
<!--          <div class="col-2" style="width: 100px; margin-left: 10px;">GSTIN : </div>-->
<!--          <div class="col border-left">06AACCW9003E1ZL</div>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--</div>-->
