<!-- <p>phoneverfication works!</p> -->

<div class="varification">
  <!-- The Modal -->

  <!-- Modal Header -->
  <button (click)="closeModal()" class="close" data-dismiss="modal" style="margin: 10px;"
          type="button">&times;
  </button>

  <br>
  <br>
  <h4 class="modal-title font-weight-bolder">PHONE NUMBER VERIFICATION</h4>
  <p>We have sent you a verification code <br> to +91 {{utilService.getUserProfile().phone}}</p>

  <br>

  <p style="text-align: center;">Please enter the code you received on your phone here</p>

  <br>

  <!-- Modal body -->
<!--  <div class="box">-->
<!--    <ul>-->
<!--      <li><input class="input-0" placeholder="0" type="text"></li>-->
<!--      <li><input class="input-0" placeholder="0" type="text"></li>-->
<!--      <li><input class="input-0" placeholder="0" type="text"></li>-->
<!--      <li><input class="input-0" placeholder="0" type="text"></li>-->

<!--    </ul>-->
<!--  </div>-->
  <div style="margin: 20px;">
<!--    <label>OTP</label>-->
    <input [(ngModel)]="otp" class="form-control"
           placeholder="Enter OTP" required
           type="number">
    <p>{{utilService.getTimerTimeLeft(timeLeft)}}</p>
  </div>

  <br>
  <br>
  <button (click)="submitOTP()"
          class="btn btn-primary btn-block text-uppercase font-weight-bolder w-75 m-auto d-block" style="">SUBMIT
  </button>
  <div style="margin: 10px;"></div>
  <button [disabled]="resend_disabled" (click)="sendOTPTodevice()" class="btn btn-outline-primary btn-block text-uppercase font-weight-bolder w-75 m-auto d-block"
          style="">SEND CODE AGAIN
  </button>

  <br>

<!--  <p class="text-uppercase">Edit Phone Number</p>-->
  <br>

</div>
