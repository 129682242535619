import { Component,OnInit, Renderer2 , Inject, PLATFORM_ID} from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JourneyCustomerComponent } from "./modals/journey-customer/journey-customer.component";
import { APIService } from './services/api.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './services/util.service';
import {EmitEvent, Events, EventService} from "./services/event.service";
import { CouponPageComponent } from './modals/coupon-page/coupon-page.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'chef';
  firstName = '';
  lastName = '';
  name = '';
  email = '';
  phone = '';
  password = '';
  sub;
  google;
  facebook;
  apple;
  home_route: boolean = false;
  constructor(private renderer: Renderer2, private modalService: NgbModal, private apiService: APIService,@Inject(PLATFORM_ID) private platformId: Object,public auth: AuthService,private eventService: EventService,public utilService:UtilService,
  public http: HttpClient) {
    // this.instafeeds();
    if (window.location.pathname == '/thank-you') {
      this.home_route = true;
      
      
    }
    this.getSocialLogin();
    this.handleAuthResponse();
  }
  ngOnInit(): void {
    this.getSocialLogin().then(() => {
      this.handleAuthResponse();
    });
  }

  getSocialLogin(): Promise<void> {
    return this.apiService.getAPI(this.apiService.BASE_URL + 'user/getSocialLogin').then((result) => {
      this.google = result.result[0]?.main_url;
    }).catch((error) => {
      console.error('Failed to get social login URL', error);
    });
  }
  private handleAuthResponse() {
    if (window.location.hash) {
      const hash = window.location.hash.substr(1);
      const params = new URLSearchParams(hash);
      const accessToken = params.get('access_token');
      const idToken = params.get('id_token');
      if (accessToken) {
        localStorage.setItem('access_token', accessToken);
        this.fetchUserProfile(accessToken);
      }
      if (idToken) {
        localStorage.setItem('id_token', idToken);
      }

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }
  
  private fetchUserProfile(token: string) {
    this.http.get(this.google + '/userinfo', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).subscribe((userInfo: any) => {
      window.history.replaceState({}, document.title, window.location.pathname);
      console.log('User Info:', userInfo);
      let userData = userInfo;
      this.signupAPI(userData)
      this.utilService.setItem(this.utilService.USER_LOGIN, '1');
    }, error => {
      console.error('Failed to fetch user profile', error);
    });
  }
  ngAfterViewInit() {
    if (localStorage?.getItem('WTC_USER_LOGIN') == '0' || !localStorage?.getItem('WTC_USER_LOGIN')) {
      // this.openjourney();
    }
    // let loader = this.renderer.selectRootElement('#loader');
    // this.renderer.setStyle(loader, 'display', 'none');
    if(localStorage.getItem('city') == undefined ){
      localStorage.setItem("city","Delhi")
  }
  // this.openCoupon();
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
  }
}
    // window.scroll(0, 0);


  openjourney() {
    const modalRef = this.modalService.open(JourneyCustomerComponent, { size: '', windowClass: 'mobileClass', centered: true });
    modalRef.dismissed.subscribe((result) => {
      console.log("dismissed")
    })
  }
  openCoupon() {
    const modalRef = this.modalService.open(CouponPageComponent, { size: '', windowClass: 'mobileClass', centered: true });
    modalRef.dismissed.subscribe((result) => {
      console.log("dismissed")
    })
  }
  instafeeds() {
    // console.log("instafeeds")
    this.apiService.getAPI('https://v1.nocodeapi.com/wethechefs/instagram/dbwCkDYoBcFVlLEl').then((result) => {
      // console.log(result);
      localStorage?.setItem('insta', JSON.stringify(result.data));
    })
  }
  signupAPI(data) {
    // if (this.type == 'customer') {
      // this.validRegion = true;
      // this.validEmail = true;
      // this.validPassword =true;
      this.name = data.name;
      this.email =data.email;
      this.phone = '';
      this.sub = data.sub;
      let postData = {
        "name": this.name,
        "password": this.email,
        "email":this.email,
        "phone": '',
        "auth_id":this.sub,
        "type": '0'
      }
      this.apiService.postAPI(this.apiService.BASE_URL + "user/registerByAuth", postData).then((result) => {
        if (result.status) {
          // this.utilService.setItem(this.utilService.USER_LOGIN, '1');
          //CUSTOMER,CHEF,ADMIN
          this.utilService.setItem(this.utilService.USER_LOGIN, '1');
          this.eventService.emit(new EmitEvent(Events.USER_SIGNUP, ''));
          this.utilService.setItem(this.utilService.SIGNUP_USER_TYPE, "CUSTOMER");
          this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(result.result.user));
          // this.openPhoneVerificationModal()
          // this.activeModal.close(SignupComponent);
          // this.activeModal.close(AuthmodalComponent);
        } else {
          alert(result.message);
        }
      }, (error) => {
        console.log("something went wrong");
      })
    // }
  }
}
