<app-navbar></app-navbar>
<div [ngClass]="home_route != true ? 'my_margin' :'thanyou-page'">
    <!-- <div [ngClass]="home_route != true ? 'my_margin' :'thanyou-page'" style="min-height: 100vh;"> -->
    <router-outlet (activate)="onActivate($event)">
        <ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="timer">
            <div class="container">
                <div class="loader-spinner">
                    <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle class="load one" cx="60" cy="60" r="20" pathLength="1" />
                        <circle class="load two" cx="60" cy="60" r="10" />
                        <circle class="load three" cx="60" cy="60" r="30" pathLength="1" />
                    </svg>
                </div>
            </div>
        </ngx-spinner>
    </router-outlet>
</div>
<app-footer></app-footer>