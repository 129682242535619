import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {APIService} from "../../services/api.service";
import {UtilService} from "../../services/util.service";
import {EmitEvent, Events, EventService} from "../../services/event.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  cartItems = [];
  totalPrice = 0;
  min_order_val = 0;
  cart_quantity = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private apiService: APIService,
    public utilService: UtilService,
    private eventService: EventService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.getUserCartItems();
  }

  dismiss() {
    this.activeModal.close(CartComponent);
  }

  checkout() {
    if (this.utilService.isUserLoggedIn()) {
      if (this.cartItems.length > 0) {
        if (this.totalPrice >= this.min_order_val) {
          this.activeModal.close(CartComponent);
          this.router.navigate(['/checkout'])
        } else {
          this.toaster.error("Minimum order amount must be greater than " + this.min_order_val);
        }
      }
    } else {
      this.activeModal.close(CartComponent);
      this.eventService.emit(new EmitEvent(Events.OPEN_NAVBAR_LOGIN, ''));
    }
  }

  private getUserCartItems() {
    this.totalPrice = 0;
    this.cart_quantity = 0;
    if (this.utilService.isUserLoggedIn()) {

      this.cartItems = [];
      if (this.utilService.isUserLoggedIn()) {
        this.apiService.getAPI(this.apiService.BASE_URL + "cart/getCartItemDetailByUserId/" + this.utilService.getUserID()).then((result) => {
          this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
          if (result.status) {
            this.cartItems = result.result;
            for (let i = 0; i < this.cartItems.length; i++) {
              this.totalPrice += Number(this.cartItems[i].price);
              this.cart_quantity += this.cartItems[i].quantity;
            }
            if (this.cartItems[0].item_id != null) {
              this.getChefDetail(this.cartItems[0].item_id);
            } else if (this.cartItems[0].event_id != null) {
              this.getEventDetail(this.cartItems[0].event_id);
            }
          }
        }, (error) => {
          console.log("error");
        })
      }
    } else {
      let cartItems = this.utilService.getLocalCart();
      this.cartItems = this.utilService.getLocalCart();
      // console.log("cartItems:-" + JSON.stringify(this.cartItems, null, 4));
      if (this.cartItems.length != 0) {
        for (let i = 0; i < this.cartItems.length; i++) {
          this.totalPrice += Number(this.cartItems[i].price) * this.cartItems[i].quantity;
          this.cart_quantity += this.cartItems[i].quantity;
        }
        if (this.cartItems[0].type == 'event') {
          this.getEventDetail(this.cartItems[0].id);
        } else {
          this.getChefDetail(this.cartItems[0].id);
        }
      }
    }
  }


  getChefDetail(item_id: any) {
    this.apiService.postAPI(this.apiService.BASE_URL + "items/getChefDetailByItemId", {
      item_id: item_id
    }).then((result) => {
      if (result.status) {
        this.min_order_val = result.result.min_order_val;
      }
    }, (error) => {
      //console.log("something went wrong");
    })
  }

  getEventDetail(event_id: any) {
    this.apiService.getAPI(this.apiService.BASE_URL + "events/getEventDetails/" + event_id).then((result) => {
      if (result.status) {
        this.min_order_val = result.result.price;
      }
    }, (error) => {
      //console.log("something went wrong");
    })
  }

  addItem(item: any) {
    if (this.utilService.isUserLoggedIn()) {
      let postData;
      if (item.item_id != null) {
        postData = {
          user_id: this.utilService.getUserID(),
          item_id: item.item_id,
          quantity: item.quantity + 1,
          price: (item.itemDetail.base_price * (item.quantity + 1))
        }
      } else if (item.event_id != null) {
        postData = {
          user_id: this.utilService.getUserID(),
          event_id: item.event_id,
          quantity: item.quantity + 1,
          price: (item.eventDetail.price * (item.quantity + 1))
        }
      }
      this.apiService.postAPI(this.apiService.BASE_URL + "cart/addToCart", postData).then((result) => {
        this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
        if (result.status) {
          this.getUserCartItems()
        }
      }, (error) => {
        console.log("error");
      })
    } else {
      this.utilService.addToLocalCart(this.modalService, item.id, item.name, item.chef_id, item.price, item.type);
      this.getUserCartItems()
      this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    }
  }

  remove(item: any) {
    if (this.utilService.isUserLoggedIn()) {
      let postData;
      if (item.item_id != null) {
        postData = {
          user_id: this.utilService.getUserID(),
          item_id: item.item_id,
          quantity: item.quantity - 1,
          price: (item.itemDetail.base_price * (item.quantity - 1))
        }
      } else if (item.event_id != null) {
        postData = {
          user_id: this.utilService.getUserID(),
          event_id: item.event_id,
          quantity: item.quantity - 1,
          price: (item.eventDetail.price * (item.quantity - 1))
        }
      }

      this.apiService.postAPI(this.apiService.BASE_URL + "cart/addToCart", postData).then((result) => {
        this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
        if (result.status) {
          this.getUserCartItems()
        }
      }, (error) => {
        console.log("error");
      })
    } else {
      this.utilService.deleteFromCart(item);
      this.getUserCartItems()
      this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    }
  }

  clearCart() {
    if (this.utilService.isUserLoggedIn()) {
      this.apiService.postAPI(this.apiService.BASE_URL + "cart/clearCart", {
        user_id: this.utilService.getUserID()
      }).then((result) => {
        this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
        if (result.status) {
          this.getUserCartItems()
        }
      }, (error) => {
        console.log("error");
      })
    } else {
      this.utilService.deleteFullLocalCart()
      this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
      this.getUserCartItems()
    }

  }
}
