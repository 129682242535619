import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { APIService } from "../../../services/api.service";
import { UtilService } from "../../../services/util.service";
@Component({
  selector: 'app-google-login',
  template: '<button (click)="auth.loginWithRedirect()">Log in</button>',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss']
})
export class GoogleLoginComponent {
google;
facebook;
apple;
  constructor( private apiService: APIService,
    public utilService: UtilService) {}

// ngOnit(){
//   this.getSocialLogin();
// }
ngOnInit(): void {
  this.getSocialLogin();
}
  getSocialLogin(){
    this.apiService.getAPI(this.apiService.BASE_URL + 'user/getSocialLogin').then((result) => {
      // console.log(result);
      
      this.google = result.result[0].url.replace('${window.location.origin}', encodeURIComponent(window.location.origin))
      this.facebook = result.result[1].url.replace('${window.location.origin}', encodeURIComponent(window.location.origin))
      this.apple = result.result[2].url.replace('${window.location.origin}', encodeURIComponent(window.location.origin))
    })
  }
  loginWithGoogle2() {
    window.location.href = this.google;
  }

  loginWithFacebook() {
    window.location.href = this.facebook;
  }
  loginWithApple() {
    window.location.href = this.apple;
  }
}
