<!-- <p>home works!</p> -->

<div style="min-height: 100vh; background: #fff;">
    <div style="padding: 3% 4% 0% 4%;
    text-align: center;"><p>
        <b>Explore Our Curated Offerings.</b>
    </p></div>
    <div class="offerings-container" style="padding:0% 4%">
        <div class="offerings-wrapper">
          <div class="offering" *ngFor="let offering of offeringsList">
            <div class="image-container" (click)="handleOfferingClick(offering)">
              <img [src]="offering.image" alt="Offering Image">
              <div class="overlay-text">{{offering.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding:3.1% 4%">
      <div *ngIf="cms!=null&&cms!=undefined" class="total_count total-count-icon" style="background-color:#E2E2E2; border-radius: 8px;">
        <div class="icon-slider-div">
            
            <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="scrollLeftServed()" [ngClass]="this.leftServedDisabled ? 'cursor-not-allowed':''"></i>
            <!-- <img (click)="scrollLeftServed()" class="served-slide-icon" src="../../../assets/icons/new_icons/left-arrow.svg" alt="" style="background: none;"> -->
        </div>
        <!-- <div class="row mx-15">
            <div class="col-lg-8 m-auto"> -->
                <div class="row h-100 w-100 align-content-center m-0 custom-p-4 custom-sec-slider meal-served-div">
                    <!-- <div class="col-xl-1 col-lg-1 col-sm-1 col-1 text-center p-0 m-0 served-banner">
                        <div class="m-auto">
                        </div>
                    </div> -->
                    <div class="col-lg-8 col-12 pd-x-0-30 m-auto">
                        <div #widgetsContentServed  class="row custom-sec-slider meal-served-div"> 
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/home_made.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data1}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label1}}</p>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/review.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data2}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label2}}</p>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/dishes.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data3}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label3}}</p>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/chefs.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data4}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label4}}</p>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/curated_served.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data5}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label5}}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
            <!-- </div>
        </div> -->
        <div class="icon-slider-div">
            <i class="fa fa-long-arrow-right" aria-hidden="true" (click)="scrollRightServed()" [ngClass]="this.rightServedDisabled ? 'cursor-not-allowed':''"></i>
            <!-- <img (click)="scrollRightServed()" class="served-slide-icon" src="../../../assets/icons/new_icons/right-arrow.svg" alt="" style="background: none;"> -->
        </div>
    </div>
    </div>
    <div class="new_home_banner">

        <!-- <div class="text" *ngIf="cms!=null&&cms!=undefined">
        <p *ngFor="let banner of cms.S1_BANNERS; index as i">{{banner.title}}</p>
      </div> -->
        <div *ngIf="cms!=null&&cms!=undefined">
            <!-- <ngb-carousel #carousel [interval]="7000" class=""
                [showNavigationIndicators]="showNavigationIndicators"> -->
                <ngb-carousel #carousel [interval]="7000" class=""
                >
                <ng-template *ngFor="let banner of cms.S1_BANNERS; index as i" ngbSlide>
                    <div (click)="openChristmas(i)" class="carousel-caption">
                        <p>{{banner.title}}</p>
                    </div>
                    <a rel="nofollow noopener noreferrer" target="_blank">
                        <div (click)="openChristmas(i)" class=" w-100 rounded-0" style="cursor:pointer;">
                            <!--              <img [defaultImage]="bannerDefaultpng" [lazyLoad]="utilService.getImageCDNURL(banner.image)"-->
                            <!--                style="width: 100%; position: relative; border-radius: 0px !important;" class="bannerImage">-->
                            <img [srcset]="utilService.getCDNMultipleResolutions(banner.image)"
                                style="width: 100%; position: relative; border-radius: 0px !important; max-height: 100%; aspect-ratio: 4;"
                                class="bannerImage">
                        </div>
                    </a>
                </ng-template>
            </ngb-carousel>
        </div>
            <!-- <div >
                <div (click)="openChristmas(cms.S1_BANNERS[1].event_id)" class="carousel-caption">
                    <p>{{cms.S1_BANNERS[1].title}}</p>
                </div>
               
                    <a rel="nofollow noopener noreferrer" target="_blank">
                       
                    <div (click)="openChristmas(cms.S1_BANNERS[1].event_id)" class=" w-100 rounded-0" style="cursor:pointer;">
                        <img [src]="utilService.getImageCDNURL(cms.S1_BANNERS[1].image)"
                            style="width: 100%; position: relative; border-radius: 0px !important; max-height: 100%; aspect-ratio: 4;"
                            class="bannerImage">
                    </div>
                </a>
            </div> -->
    </div>
    <div >
        <!-- <div style="background-color: #E2E2E2;"> -->
<div [ngClass]="popularCuisines?.length <=4 ? '' : 'height-check'" class="search-div-text container" id="orderAuthenticFood">
    <!-- <div [ngClass]="popularCuisines?.length <=4 ? 'hight_75' : 'height-check'" class="search-div-text container hight_75"> -->
    <div class="new-search-div"><p><b>Select your city </b></p> <select class="srch-destination " name="Please select" id="" [(ngModel)]="selectedCity" (change)="selectedState()" style="cursor: pointer;    background-color: white;
        border: none;
        border-radius: 0px 8px 8px 0px;
        width: 22%; color: #E26812;">
         <option value="Delhi">Delhi/NCR</option>
         <option value="Bangalore">Bangalore</option>
         <option value="Gurgaon">Gurgaon</option>
         <option value="Noida">Noida</option>
        </select></div>
    <div class="input_class col-lg-8 m-auto" *ngIf="showInput" >
        <p style="text-align: center" id="searchInputSection"><b>Order Authentic Food</b></p>
     <div class="input-group">
        <!-- <input (keydown.enter)="searchPage()" (ngModelChange)="searchItems()" [(ngModel)]="searchedText" -->
        <i class="fa fa-search fa-fw"></i>
        <input  (ngModelChange)="searchItems()" [(ngModel)]="searchedText"
            class="form-control" placeholder="Search for a meal, cuisine, or chef"
            style="background-color: white; border: 1px solid #B8B8B8;
            border-radius: 8px 8px 8px 8px; width:43%;" type="text">
            <!-- <i aria-hidden="true" class="fa fa-map-marker"></i> -->
            <!-- <input class="form-control" placeholder="Location" type="text" disabled value="Delhi/NCR"
                   style="background-color: white; border: 1px solid #B8B8B8;
                   border-radius: 0px; color:#878181"> -->
                   <!-- <select class="srch-destination " name="Please select" id="" [(ngModel)]="selectedCity" (change)="selectedState()" style="cursor: pointer;    background-color: white;
                   border: 1px solid #B8B8B8;
                   border-radius: 0px 8px 8px 0px;
                   width: 22%; color: #495057b8;">
                    <option value="Delhi">Delhi/NCR</option>
                    <option value="Bangalore">Bangalore</option>
                   </select> -->
        <!-- <button (click)="searchPage()" style="background-color: #E26812; width: 59px; color: white; padding: 0px;  z-index: 10; outline: none; letter-spacing: 1px; border: 1px solid #E26812;
        border-radius: 0px 8px 8px 0px;"> <i class="fa fa-search fa-fw srch-icon"></i></button> -->
        <!-- <input (click)="searchPage()"
            style="background-color: #E26812; width: 59px; color: white; padding: 0px;  z-index: 10; outline: none; letter-spacing: 1px; border: 1px solid #E26812;
            border-radius: 0px 8px 8px 0px;"
            type="button" > -->
            <div class="popular-searches" *ngIf="cms!=null&&cms!=undefined"><div class="popular-span"><span class="popular-text">{{cms.S14.heading}}</span> <div class="pop-span-section"><span class="search-span" (click)="openCuisine(cuisine.id,cuisine.name)" *ngFor="let cuisine of popularCuisines">{{cuisine.name}}</span></div></div></div>

    </div>
   
    <div *ngIf="searchresult" class="searchresult" style="background-color: white;">
        <div class="pd-2-15">
            <div class="row">
                <div class="col-sm-12">
                    <ul class="nav nav-pills tab-btn-dishes" id="pills-tab" role="tablist">
                        <li class="nav-item">
                          <button [ngClass]="{'old': Dtoggle, 'new': !Dtoggle}" class="  dish-btn-tab" (click)="Btnchange()">Dish</button>
                        </li>
                        <li class="nav-item mrg-10">
                          <button [ngClass]="{'old': !Dtoggle, 'new': Dtoggle}" class="chef-btn-tab" (click)="Btnchange()">Chef</button>
                        </li>
                      </ul>
                </div>
            </div>
            <div *ngIf="cuisines.length==0&&items.length==0&&chefs.length==0 && !Dtoggle"
                style="display: flex;text-align: center;">
                <p style="margin: 10px;">Uh-oh! Our Home chefs not found with this search! Please check another keyword</p>
            </div>
            <div *ngIf="cuisines.length==0&&items.length==0&&chefs.length==0 && Dtoggle"
                style="display: flex;text-align: center;">
                <p style="margin: 10px;">Uh-oh! Our Home chefs add new items all the time! Please check back for
                    this item,
                    connect with a Menu Maker for a special request, or browse our other delicious menus.</p>
            </div>
            <div #widgetsContent class="d-f lex d_cusines mb-2" style="width: 100%; overflow-x: auto;">
                <h5 *ngIf="cuisines.length>0" class="font-weight-bolder">Cuisine</h5>
                <div (click)="openCuisine(cuisine.id,cuisine.name)" *ngFor="let cuisine of cuisines"
                    class="d-flex mr-2 my-2 cursorpointer">
                    <div class="pr-0">
                        <!--                <img [src]="utilService.getImageCDNURL(cuisine.image)" style="width: 50px; height: 50px;">-->
                        <img [srcset]="utilService.getCDNMultipleResolutions(cuisine.image)"
                             class="cuisine-chef-image">
                    </div>
                    <div class="pl-1">
                        <!-- <h6 class="font-weight-bolder mb-0">{{cuisine.name}}</h6> -->
                        <p class="mt-0 cusun-n">{{cuisine.name}}</p>
                        <!-- <p class="mt-0" style="font-size: 13px; color: rgb(153, 153, 153);">Cuisine</p> -->
                    </div>
                </div>
            </div>
            <div (click)="closeDropdown()" style="position: absolute; top: 0%; right: 0%;padding: 0px 15px;">
                <button class="btn"><i aria-hidden="true" class="fa fa-times"></i></button>
            </div>
            <div *ngIf="cuisines.length >= '3'" style="position: absolute; top: 9%; right: 0%; display: none;">
                <div style="float: left">
                    <button (click)="scrollLeft()" class="btn arrow-btn p-2 custom-btn-home"><i aria-hidden="true"
                            class="fa fa-chevron-left"></i>
                    </button>
                </div>
                <div style="float: right; display: none;">
                    <button (click)="scrollRight()" class="btn arrow-btn p-2 custom-btn-home"><i aria-hidden="true"
                            class="fa fa-chevron-right"></i>
                    </button>
                </div>
            </div>
            <hr class="hr-line" *ngIf="cuisines.length!=0&&items.length!=0&&chefs.length!=0">
            <div *ngIf="!Dtoggle" class="d-f lex chef_list_cont">
                <h5 *ngIf="chefs.length>0" class="font-weight-bolder">Chefs</h5>
                <div (click)="openChefDetail(chef.id,chef.name)" *ngFor="let chef of chefs" class="chef_list  mr-2 w-100">
                <div class="row">
                    <div class="col d-flex">
                        <div class="pr-0 sr-chef-img">
                            <img *ngIf="chef.profile_pic==null||chef.profile_pic==undefined||chef.profile_pic==''"
                            alt="" src="./assets/images/default_chef.png" style="margin-top: 10px;" />
                        <!--              <img *ngIf="chef.profile_pic!=null&&chef.profile_pic!=undefined&&chef.profile_pic!=''"-->
                        <!--                [src]="utilService.getImageCDNURL(chef.profile_pic)" style="margin-top: 10px;" />-->
                        <!-- <img *ngIf="chef.profile_pic!=null&&chef.profile_pic!=undefined&&chef.profile_pic!=''"
                            [src]="apiService.BASE_IMAGE_URL+chef.profile_pic" style="margin-top: 10px;" /> -->
                            <img *ngIf="chef.profile_pic!=null&&chef.profile_pic!=undefined&&chef.profile_pic!=''&&!(chef.profile_pic).includes('uploads')"
                            [src]="utilService?.getImageCDNURL(chef?.profile_pic)" style="margin-top: 10px;" />
                            <img *ngIf="(chef.profile_pic)?.includes('uploads')" [src]="apiService.BASE_IMAGE_URL+chef.profile_pic" style="margin-top: 10px;"/>
                        
                        </div>
                      <div class="chef-content">
                        <div class="dishes-content-heading">
                            <b style="font-weight: bold;" class="mb-0">{{chef.name}}</b>
                            <span class="star-rat"><ngb-rating [(rate)]="chef.avg" [max]="5" class="str-icon" [readonly]='true'></ngb-rating><small class="fnt-11-mob fnt-18">({{chef.avg}} Rating)</small></span>
                        </div>
                        <p class="mt-0 chef_name chef-descrip-pera fnt-italic" style="font-size: 18px;">Specialist in  {{(chef.cuisines?.length > 25) ? (chef?.cuisines | slice:0:25) + '..' : (chef.cuisines)}}</p>
                      </div>
                     </div>
                      <div class="col-auto ml-auto">
                        <div class="pdt-10-b-0">
                        <div class="price-content d-flex">
                        <span class="text-primary fnt-11-mob"><i class="fa fa-inr" aria-hidden="true"></i> <b>{{chef.min_order_val}}</b></span>
                        <span class="mrg-ml-10 chev-right">
                            <i class="fa fa-angle-right text-primary"></i>
                            <!-- <i class="fa fa-chevron-right text-primary" aria-hidden="true"></i> -->
                         </span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div *ngIf="Dtoggle" class="cont">
                <h5 *ngIf="items.length>0" class="font-weight-bolder">Dishes</h5>
                <!-- <div *ngFor="let item of items" class="row dishes_item " (click)="openChefDetail(item.chef_id,null)"> -->
                    <div *ngFor="let item of items" class="row dishes_item " (click)="openChefDetail(item.chef_id,null);openChefItem(item.id)">
                    <div class="col d-flex">
                  <div class="sr-dishes-img">
                    <img *ngIf="item.images=='[]'" class="img-dishes"
                    src="../../../assets/icons/new_icons/new_icons/dishes-dummy.jpg" alt="" style="margin-top: 10px;">
                    <img *ngIf="item.images!='[]'" class="img-dishes"
                    [srcset]="utilService.getCDNMultipleResolutions(item.images | slice:2:-2)" alt="" style="margin-top: 10px;">
                    <!-- <img _ngcontent-serverapp-c118="" style="margin-top: 10px;" class="ng-tns-c118-1 ng-star-inserted" src="https://api.wethechefs.in/uploads/2021_12_3_19_12_5465BD6DCF-2423-4C3A-BADC-BCB42263E282.jpeg"> -->
                  </div>
                  <div class="dishes-content">
                    <div class="dishes-content-heading">
                        <b class="mb-0">{{item.name}}</b>
                        <!-- <span class="star-rat"><i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star-half-o"></i></span>
                       <span><small class="fnt-11-mob fnt-18">(4.5 Rating)</small></span> -->
                    </div>
                        <ul>
                            <!--                <li>{{item.qty}} {{item.quantity_type}}</li>-->
                            <!--                <li *ngIf="item.calories!=''&& item.calories!='0'">{{item.calories}}kl</li>-->
                        </ul>
                        <!-- <h6 (click)="openChefDetail(item.chef_id,null)" class="mrg-top-5 mob-fnt-10 fnt-18">{{item.chef_name}}</h6> -->
                        <h6 (click)="openChefDetail(item.chef_id,null);openChefItem(item.id)" class="mrg-top-5 mob-fnt-10 fnt-18">{{item.chef_name}}</h6>
                  </div>
                    </div>
                    <div class="col-auto ml-auto">
                        <div class="price-content price-dishes-mob d-flex">
                        <span class="text-primary fnt-11-mob"><i class="fa fa-inr" aria-hidden="true"></i> <b> {{item.base_price}}</b></span>
                        <span class="mrg-ml-10 chev-right">
                            <i class="fa fa-angle-right text-primary"></i>
                            <!-- <i class="fa fa-chevron-right text-primary" aria-hidden="true"></i> -->
                         </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</div>
</div>
<!--  MEET OUR CHEFS-->
<div *ngIf="cms!=null&&cms!=undefined" class="meetthechef" style="background: #EEA471;">
    <div class="row mb-pad-25 meethechef-div">
        <div class="col-6 most-loved-pl-0">
        <!-- <h5 class="font-weight-bolder section_heading heading-chef-loved">{{cms.S5.heading}}</h5> -->
        </div>
        <!-- <div class="col-6 chefpdr-0">
            <div class="loved-chef-icon loved-chef-icon-mob-none chefpdr-0">
                <i class="fa fa-long-arrow-left cursorpointer" aria-hidden="true" (click)="scrollLeftChef()" [ngClass]="this.leftDisabled ? 'cursor-not-allowed':''"></i>
                <i class="fa fa-long-arrow-right cursorpointer mrgn-right-0" aria-hidden="true" (click)="scrollRightChef()" [ngClass]="this.rightDisabled ? 'cursor-not-allowed':''"></i>
            </div>
            <div class="loved-chef-icon loved-chef-icon-tab-none chefpdr-0">
                <i class="fa fa-long-arrow-left cursorpointer" aria-hidden="true" (click)="scrollMobLeftChef()" [ngClass]="this.leftDisabled ? 'cursor-not-allowed':''"></i>
                <i class="fa fa-long-arrow-right cursorpointer mrgn-right-0" aria-hidden="true" (click)="scrollMobRightChef()" [ngClass]="this.rightDisabled ? 'cursor-not-allowed':''"></i>
            </div>
        </div> -->
        
    </div>
    
<div #widgetsChef class="row love-chef-crausel">
   <div *ngFor="let chef of homeChefs; let chefIndex=index" class="col-sm-12 col-lg-12 col-md-12 col-12 m-auto bg-white" style="border-radius:5px;">
    <div  class="row">
        <div class="col-sm-3 p-0">
            <div class="name-of-chef-sec">
    <div class="col-sm-12 d-none-mob">
        <div class="py -2 desk-block-rating">
            <ngb-rating [(rate)]="chef.Rating" [max]="5"  class="star-fs-18" [readonly]='true'></ngb-rating>
            <!-- <span><i class="fa fa-star fs-icon-10"></i></span>
            <span><i class="fa fa-star fs-icon-10"></i></span>
            <span><i class="fa fa-star fs-icon-10"></i></span>
            <span><i class="fa fa-star fs-icon-10"></i></span>
            <span><i class="fa fa-star fs-icon-10"></i></span> -->
            <span class="fw-400 fs-rating-12 marg-lft-5 fs-rating-10">({{chef.Rating | number : '1.2-2'}} Rating)</span>
        </div>
    </div>
   <div class="row m-0">
    <div class="col-sm-12 col-3">
        <div class="py-2 chef-img">
            <img *ngIf="(chef.image1)?.includes('assets')&&!(chef.profile_pic)?.includes('uploads')" [srcset]="utilService.getImageCDNURL(chef.profile_pic)" />
            <img *ngIf="!(chef.image1)?.includes('assets')" [alt]="chef.tag1"
            [srcset]="utilService.getImageCDNURL(chef.image1)" alt="Home Chef" loading="lazy"/>
            <!-- <img *ngIf="chef.hasOwnProperty('image1')" [alt]="chef.tag1"
            [srcset]="utilService.getImageCDNURL(chef.image1)" alt="Home Chef" loading="lazy">
            <img *ngIf="chef.hasOwnProperty('image1')" [alt]="chef.tag1"
            [srcset]="utilService.getImageCDNURL(chef.image1)" alt="Home Chef" loading="lazy"> -->
            <img *ngIf="(chef.image1)?.includes('assets')&&(chef.profile_pic)?.includes('uploads')" [src]="apiService.BASE_IMAGE_URL+chef.profile_pic"  alt="Home Chef" loading="lazy"/>
        </div>
    </div>
    <div class="col-sm-12 col-9">
    <div class="pt-2 name-of-chef pb-0">
         <div class="pb-2 pt-0 tb-dis-none pdb-10-px">
            <!-- <i class="fa fa-star-half-o"></i> -->
            <ngb-rating [(rate)]="chef.Rating" [max]="5" class="star-fs-14" style="font-size: 25px;" [readonly]='true'></ngb-rating>
            <!-- <input type="radio" name="rating" value={{chef.Rating}}><span class="star"></span> -->
            <!-- <input type="radio" name="rate" id={{chef.Rating}}>
        <label for={{chef.Rating}} class="fa fa-star"></label> -->
                <!-- <span ><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star-half-o"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span> -->
                <span class="fw-400 fs-12 marg-lft-5">({{chef.Rating | number : '1.2-2'}} Rating)</span>
            </div>
        <h2>{{chef.name}}</h2>
        <p class="py-8-0 fs-15 tb-fs-12" *ngIf="chef.itemName!=null&&chef.itemName!=undefined">Specializes in {{chef.cuisines}}</p>
        <p class="mb-0 fs-12 tb-fs-10 fw-700 d-none-mob">Min order value - <span class="mb-0 fs-12 tb-fs-10 fw-700 d-none-mob"><b>{{chef.min_val}} Rs</b></span></p>
    </div>
    </div>
    <div class="col-3" style="line-height: 1;">
        <p class="mb-0 fs-12 tb-fs-10 fs-mob-10 fw-400 tb-dis-none">Min order value</p><span class="mb-0 fs-12 tb-fs-10 fs-mob-10 fw-700 tb-dis-none"><b>{{chef.min_val}} Rs</b></span>
    </div>
    <div class="col-sm-12 col-9">
        <div *ngIf="chef.quote!=''" class="qutation">
           <p class="tb-fs-12">
            <img src="../../../assets/icons/new_icons/new_icons/quote.png" class="quote-icon-right" style="color: rgba(226, 104, 18, 1); background: none;"/>
            <!-- <i class="fa fa-quote-left quote-icon-right" aria-hidden="true"  style="color: rgba(226, 104, 18, 1);"></i> -->
            {{chef.quote}}
            <!-- <i class="fa fa-quote-left quote-icon-dis" aria-hidden="true"  style="transform: scalex(-1); color: rgba(226, 104, 18, 1);"></i> -->
        </p>
        </div>
    </div>
    <div class="col-sm-12 d-none-mob">
        <div class="py-2">
            <p class="fs-15 fw-700 tb-fs-12">
                <!-- <b>Customer Name</b> -->
            </p>
        </div>
    </div>
   </div>
    
            </div>
        </div>
        <div class="col-sm-9">
            <div class="p-44 bg-white">
                <div class="row">
                 <div *ngFor="let item of chef.selectDishes;let dishindex=index"  class="col-sm-12">
                    <div  class="row most-loved-dishes-mob">
                        <div class="col-sm-4 col-4 pl-mob-13">
                            <div class="pr-0 pl-lg-2 pl-0">
                                <img *ngIf="item.images=='[]'" class="img-dishes"
                                src="../../../assets/icons/new_icons/new_icons/dishes-dummy.jpg" alt="">
                                <img *ngIf="item.images!='[]'" class="img-dishes"
                                [srcset]="utilService.getCDNMultipleResolutions(item.images | slice:2:-2)" alt="">
                                <!-- <img  class="img-dishes" src="https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/450b1d20-2f10-4ef2-0879-d74677820900/w=360"/> -->
                            </div>
                        </div>
                        <div class="col-sm-8 col-8">
                            <div class="row">
                                <div class="col-sm-6 col-7 px-0">
                                    <div class="pl-0 pl-lg-0">
                                        <div>
                                            <h2 class="dishes-text">
                                                {{(item.name.length > 30) ? (item.name | slice:0:30) + '...' : (item.name)}}

                                            </h2>
                                        <div class="tag-div" *ngIf="item.display_tag">

                                            <p  class="tag-text" *ngFor="let tag of item.display_tag.split(',')">{{ tag }}</p>
                                            <!-- <p class="tag-text">tag</p> -->
                                        </div>
                                        <div class="py-2" style="display:none;">
                                            <p class="chef-desc-sec">
                                                {{(item.long_desc.length > 47) ? (item.long_desc | slice:0:47) + '...' : (item.long_desc)}}
                                                <!-- Lorem ipsum dolor sit amet, consect adipiscing elit, Nunc mattis feugiat ex scelerisque congue. -->
                                            </p>
                                        </div>
                                        </div>
                                        
            
                                    </div>
                                </div>
                                <div class="col-sm-6 col-5 pl-0-mob">
                                    <div class="add-cart-btn-sect pl-0-mob add-cart-btn-sect-mob">
                                        <!-- <div class="col-auto ml-auto p-0" style="position: relative; margin-right: 2px; "> -->
                                            <button (click)="addToCart(item, chefIndex, dishindex,item.id,item.base_price)" *ngIf="!item.in_cart" class="add-cart-btn add-cart-btn-mob">Add to Cart</button>
                                            
                                            <!-- <button 
                                              class="btn btn-primary text-white font-weight-bolder text-uppercase add_to_cart_btn p-1">
                                              <img alt="" src="./assets/icons/cart.svg" style="" class="t_img">
                                              Add
                                            </button> -->
                                            <div *ngIf="item.in_cart" class="d-flex">
                                              <div class="d-flex m-auto">
                                                <button (click)="remove(item)" class="btn p-1 btn-primary text-white"
                                                  style="font-size: 12px; border-radius: 0px !important; border-top-left-radius: 8px !important; border-bottom-left-radius: 8px !important; font-weight: 600;">
                                                  <span style="margin: 2px;">
                                                    <svg aria-labelledby="icon-svg-title- icon-svg-desc-" class="sc-rbbb40-0 kyPUnV"
                                                      fill="#FFFFFF" height="14" role="img" viewBox="0 0 20 20" width="14"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                      <title>remove</title>
                                                      <path
                                                        d="M10.96 10.96h4.28c0.53 0 0.96-0.43 0.96-0.96s-0.43-0.96-0.96-0.96v0h-10.48c-0.53 0-0.96 0.43-0.96 0.96s0.43 0.96 0.96 0.96v0h6.2z">
                                                      </path>
                                                    </svg>
                                                  </span></button>
                                                <button class="btn p-1 bg-white"
                                                  style="font-size: 12px; font-weight: 600; border-radius: 0px !important;"
                                                  *ngIf="item.cart_item!=undefined">{{item.cart_item.quantity}}</button>
                                                <button (click)="addItem(item, chefIndex, dishindex)" class="btn p-1 btn-primary text-white"
                                                  style="font-size: 12px; border-radius: 0px !important; border-top-right-radius: 8px !important; border-bottom-right-radius: 8px !important; font-weight: 600;">
                                                  <span style="margin: 2px;"> <svg aria-labelledby="icon-svg-title- icon-svg-desc-"
                                                      class="sc-rbbb40-0 kyPUnV" fill="#FFFFFF" height="14" role="img" viewBox="0 0 20 20"
                                                      width="14" xmlns="http://www.w3.org/2000/svg">
                                                      <title>plus</title>
                                                      <path
                                                        d="M15.5 9.42h-4.5v-4.5c0-0.56-0.44-1-1-1s-1 0.44-1 1v4.5h-4.5c-0.56 0-1 0.44-1 1s0.44 1 1 1h4.5v4.5c0 0.54 0.44 1 1 1s1-0.46 1-1v-4.5h4.5c0.56 0 1-0.46 1-1s-0.44-1-1-1z">
                                                      </path>
                                                    </svg>
                                                  </span>
                                                </button>
                                              </div>
                                            </div>
                                          <!-- </div> -->
                                        <!-- <button class="dish-rating-btn dish-rating-btn-mob">Dish Rating</button> -->
                                       
                                    </div>
                                </div>
                                <div class="col-sm-12 pl-0-mob">
                                    <div>
                                        <p class="chef-desc-sec">
                                            {{(item.long_desc.length > 47) ? (item.long_desc | slice:0:47) + '...' : (item.long_desc)}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row most-loved-dishes-tab">
                        <div class="col-auto pr-0 pl-lg-2 pl-0">
                            <img *ngIf="item.images=='[]'" class="img-dishes"
                                src="../../../assets/icons/new_icons/new_icons/dishes-dummy.jpg" alt="">
                            <img *ngIf="item.images!='[]'" class="img-dishes"
                            [srcset]="utilService.getCDNMultipleResolutions(item.images | slice:2:-2)" alt="">
                            <!-- <img class="img-dishes" src="https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/450b1d20-2f10-4ef2-0879-d74677820900/w=360"/> -->
                        </div>
                        <div class="col pl-lg-4 pl-lg-0 pl-2" style="position: relative; ">
                            <div><h2 class="dishes-text">{{(item.name.length > 26) ? (item.name | slice:0:26) + '...' : (item.name)}}</h2>
                                <!-- {{item.display_tag}} -->
                                <div class="tag-div" *ngIf="item.display_tag">
                                <p  class="tag-text" *ngFor="let tag of item.display_tag?.split(',')">{{tag}}</p>
                                <!-- <p class="tag-text">tag</p> -->
                            </div>
                            <div class="py-2">
                                <p class="chef-desc-sec">
                                    {{(item.long_desc.length > 33) ? (item.long_desc | slice:0:33) + '...' : (item.long_desc)}}
                                </p>
                            </div>
                            </div>
                            

                        </div>
                        <div class="col-auto">
                            <div class="add-cart-btn-sect">
                                <button (click)="addToCart(item,chefIndex, dishindex,item.id,item.base_price)" *ngIf="!item.in_cart" class="add-cart-btn">Add to Cart</button>
                                            
                                <!-- <button 
                                  class="btn btn-primary text-white font-weight-bolder text-uppercase add_to_cart_btn p-1">
                                  <img alt="" src="./assets/icons/cart.svg" style="" class="t_img">
                                  Add
                                </button> -->
                                
                                <div *ngIf="item.in_cart" class="d-flex">
                                  <div class="d-flex m-auto">
                                    <button (click)="remove(item)" class="btn p-1 btn-primary text-white"
                                      style="font-size: 12px; border-radius: 0px !important; border-top-left-radius: 8px !important; border-bottom-left-radius: 8px !important; font-weight: 600;">
                                      <span style="margin: 2px;">
                                        <svg aria-labelledby="icon-svg-title- icon-svg-desc-" class="sc-rbbb40-0 kyPUnV"
                                          fill="#FFFFFF" height="14" role="img" viewBox="0 0 20 20" width="14"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <title>remove</title>
                                          <path
                                            d="M10.96 10.96h4.28c0.53 0 0.96-0.43 0.96-0.96s-0.43-0.96-0.96-0.96v0h-10.48c-0.53 0-0.96 0.43-0.96 0.96s0.43 0.96 0.96 0.96v0h6.2z">
                                          </path>
                                        </svg>
                                      </span></button>
                                    <button class="btn p-1 bg-white"
                                      style="font-size: 12px; font-weight: 600; border-radius: 0px !important;"
                                      *ngIf="item.cart_item!=undefined">{{item.cart_item.quantity}}</button>
                                    <button (click)="addItem(item, chefIndex, dishindex)" class="btn p-1 btn-primary text-white"
                                      style="font-size: 12px; border-radius: 0px !important; border-top-right-radius: 8px !important; border-bottom-right-radius: 8px !important; font-weight: 600;">
                                      <span style="margin: 2px;"> <svg aria-labelledby="icon-svg-title- icon-svg-desc-"
                                          class="sc-rbbb40-0 kyPUnV" fill="#FFFFFF" height="14" role="img" viewBox="0 0 20 20"
                                          width="14" xmlns="http://www.w3.org/2000/svg">
                                          <title>plus</title>
                                          <path
                                            d="M15.5 9.42h-4.5v-4.5c0-0.56-0.44-1-1-1s-1 0.44-1 1v4.5h-4.5c-0.56 0-1 0.44-1 1s0.44 1 1 1h4.5v4.5c0 0.54 0.44 1 1 1s1-0.46 1-1v-4.5h4.5c0.56 0 1-0.46 1-1s-0.44-1-1-1z">
                                          </path>
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <!-- <button class="add-cart-btn">Add to Cart</button> -->
                            </div>
                        </div>


                    </div>
                 </div>
                </div>
                <div class="col-sm-12 text-center">
                    <button class="see-menu-btn"  (click)="openChefDetail(chef.id,chef.name)">
                        See Menu
                    </button>
                 </div>
            </div>
        </div>
    </div>
   </div>
</div>
   
   
</div>

<!--  -->
    <!-- <div *ngIf="cms!=null&&cms!=undefined" class="total_count total-count-icon" style="background-color:#E2E2E2;">
        <div class="icon-slider-div">
            
            <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="scrollLeftServed()" [ngClass]="this.leftServedDisabled ? 'cursor-not-allowed':''"></i>
          
        </div>
        
                <div class="row h-100 w-100 align-content-center m-0 custom-p-4 custom-sec-slider meal-served-div">
                   
                    <div class="col-lg-8 col-12 pd-x-0-30 m-auto">
                        <div #widgetsContentServed  class="row custom-sec-slider meal-served-div"> 
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/home_made.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data1}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label1}}</p>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/review.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data2}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label2}}</p>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/dishes.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data3}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label3}}</p>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/chefs.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data4}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label4}}</p>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-2 col-4 text-center p-0 m-0">
                            <div class="m-auto">
                                <img src="../../../assets/icons/new_icons/curated_served.svg" class="img-chef"/>
                                <h1 class="fc-333333 fw-700">{{cms.S6.data5}}+</h1>
                                <p class="mb-0 fw-400" style="color: #333333;">{{cms.S6.label5}}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
         
        <div class="icon-slider-div">
            <i class="fa fa-long-arrow-right" aria-hidden="true" (click)="scrollRightServed()" [ngClass]="this.rightServedDisabled ? 'cursor-not-allowed':''"></i>
          
        </div>
    </div> -->
    <!-- <p>[alt]="banner.tag"</p> -->

    <div style="width: 100%; overflow-x: hidden;" *ngIf="cms!=null&&cms!=undefined">

        <!--  WHAT DO YOU WANT TO EAT TODAY?-->
        <div class="fourth_div new-btn-div" style="min-height: 200px;">
            <div *ngIf="cms!=null&&cms!=undefined&&allCuisines.length>0">
                <div class="d- flex justify- content-end w-100">
                    <!-- <div class="mr-auto">
                        <p class="occassion-p">{{cms.S2.heading}}
                        </p>
                    </div> -->
                    <div class="row">
                        <div class="col-6">
                            <p class="occassion-p">{{cms.S2.heading}}</p>
                        </div>
                        <div class="col-6">
                            <div class="press-icon press-icon-occ" [ngClass]="this.collectionTag.length > 4 ? 'display-occ-none': 'display-occ' ">
                                <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="scrollLeft1()" [ngClass]="this.leftCuisineDisabled ? 'cursor-not-allowed':''"></i>
                                <i class="fa fa-long-arrow-right right-arrow-margin" aria-hidden="true" (click)="scrollRight1()" [ngClass]="this.rightCuisineDisabled ? 'cursor-not-allowed':''"></i>
                            </div>
                           
                        </div>
                    </div>
                   
                </div>
                <div #widgetsContent1 class="d-flex"
                    style="display: flex; width: 100%; overflow-x: auto; overflow-y: hidden;">
                    <div class="cuisine d-flex" style="display: flex;">
                        <div (click)="openCuisine(cuisine.id,cuisine.name)" *ngFor="let cuisine of allCuisines"
                            class="img_div p-0 cursorpointer">
                            <div class="hover05">
                                <div class="fig">
                                    <img [alt]="cuisine.tag"
                                        [srcset]="utilService.getCDNMultipleResolutions(cuisine.image)"
                                        style="max-height: 100%; aspect-ratio: 300/300; position: relative;" />
                                        <p class="desc">{{cuisine.name}}</p>
                                </div>
                            </div>
                            
                        </div>
                      
                    </div>
                   
                </div>
              

                <!-- <div>
                    <button class="btn btn-primary  ml-auto d-block border_2 font-weight-bolder text-uppercase mt-2"
                        routerLink="/search-cuisine/book-your-order" style="font-size: 15px; white-space: nowrap; width: auto;">Explore
                        more cuisines
                    </button>
                </div> -->

            </div>
        </div>

        <!-- occasion section -->
        <!-- <div style="padding:3.1% 4%" class="mb-coll-30 pdt-0">
            <div class="row">
                <div class="col-12">
                    <p class="occassion-p">{{cms.SO3.heading}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-3 col-6 occassion-mb-20" (click)="openOccassion(occassion.id,occassion.heading)" *ngFor="let occassion of collectionTag"> 
                    <div class="inner-div-trending hand-icon">
                    <img 
                    [srcset]="utilService.getCDNMultipleResolutions(occassion.image)" class="occassion-image">
                    <div class="occassion-overlay">
                      <p class="occassion-overlay-text">{{occassion.heading}}</p>
                    </div>
                </div>
                </div>
            </div>

        </div> -->


        <!--  -->
        <!-- <div style="padding:3.1% 4%" class="pdt-0 pdb-30">
            <div class="row">
                <div class="col-6">
                    <p class="occassion-p">{{cms.SO3.heading}}</p>
                </div>
                <div class="col-6">
                    <div class="press-icon press-icon-occ" [ngClass]="this.collectionTag.length > 4 ? 'display-occ-none': 'display-occ' ">
                        <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="scrollLeftOccTrending()" [ngClass]="this.leftOcctrendingDisabled ? 'cursor-not-allowed':''"></i>
                        <i class="fa fa-long-arrow-right right-arrow-margin" aria-hidden="true" (click)="scrollRightOccTrending()" [ngClass]="this.rightOcctrendingDisabled ? 'cursor-not-allowed':''"></i>
                    </div>
                   
                </div>
            </div>
            <div class="row">
                <div #widgetsContentOcctrending class="d-flex trending-mb-overflow w-100">
                <div class="col-lg-3 col-md-3 col-6 trending-pl-0" (click)="openOccassion(occassion.id,occassion.heading)" *ngFor="let occassion of collectionTag"> 
                    <div class="inner-div-trending hand-icon">
                    <img 
                    [srcset]="utilService.getCDNMultipleResolutions(occassion.image)" class="trending-image">
                  
                    <div class="trending-overlay">
                      <p class="trending-overlay-text">{{occassion.heading}}</p>
                     
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
        </div> -->
        <!-- Shop by collection -->
        <!-- <div style="padding:3.1% 4%" class="pdt-0 pdb-30">
            <div class="row">
                <div class="col-6">
                    <p class="occassion-p">{{cms.SO4.heading}}</p>
                </div>
                <div class="col-6">
                    <div class="press-icon press-icon-occ" [ngClass]="this.chefTag.length > 4 ? 'display-occ-none': 'display-occ' ">
                        <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="scrollLeftTrending()" [ngClass]="this.lefttrendingDisabled ? 'cursor-not-allowed':''"></i>
                        <i class="fa fa-long-arrow-right right-arrow-margin" aria-hidden="true" (click)="scrollRightTrending()" [ngClass]="this.righttrendingDisabled ? 'cursor-not-allowed':''"></i>
                    </div>
                   
                </div>
            </div>
            <div class="row">
                <div #widgetsContenttrending class="d-flex trending-mb-overflow w-100">
                <div class="col-lg-3 col-md-3 col-6 trending-pl-0" (click)="openCollection(collection.id,collection.heading)" *ngFor="let collection of chefTag"> 
                    <div class="inner-div-trending hand-icon">
                    <img 
                    [srcset]="utilService.getCDNMultipleResolutions(collection.image)" class="trending-image">
                    <div class="trending-overlay">
                      <p class="trending-overlay-text">{{collection.heading}}</p>
                      <p class="trending-desc">{{collection.descrption}}</p>
                      <div class="trending-view-all"><button class="btn trending-btn-view">View All</button></div>
                    </div>
                    
                </div>
                </div>
              
            </div>
        </div>
        </div> -->


<!--  -->
<!-- video section -->

<div class="overlay-bg" [style]="VideoClass">
    <div class="verlay">
    <!-- <div class="curate-section" style="background-image: url('./assets/icons/new_icons/new_icons/video_bac.png')"> -->
    <div class="row">
       
        <div class="col-lg-6 col-md-8 m-auto">
                       <div class="watch-video">
                        <p>{{cms.VideoS1.heading}}</p>
                <p>{{cms.VideoS1.desc}}</p>
                <button (click)="VideoPopup()"><i class="fa fa-play-circle" aria-hidden="true"></i><span class="watch-now">Watch Now</span> </button>
            </div>
        
        </div>
<!-- <div class="col-sm-12">
        <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
    </div> -->
    </div>


</div>
</div>


<!-- video section end -->
        <!--  how it works S3-->

<div style="padding: 3.1% 4%;" class="handpick-mt-mb-7">
<div class="row">
    <div class="col-sm-12">
        <h5 class="S3-section-heading">{{cms.S3.heading}}</h5>
        <h5 class="s3-section-heading2">{{cms.S3.heading2}}</h5>
    </div>
</div>
<div class="row">
    <div class="col-sm-4 col-4 mb-pd-r-0">
        <div class="Home-Chefs text-center">
            <p class="mb-0"><img src="./assets/icons/new_icons/new_icons/menus.png" style="background:none;"/></p>
            <p class="s3-title-1">{{cms.S3.C1Title}}</p>
            <p class="mb-0 pb-0 s3-description">{{cms.S3.C1Description}}</p>
        </div>
    </div>
    <div class="col-sm-4 col-4 mb-pad-0">
        <div class="Home-Chefs text-center">
            <p class="mb-0"><img src="./assets/icons/new_icons/new_icons/certified.png" style="background:none;"/></p>
            <p class="s3-title-1">{{cms.S3.C2Title}}</p>
            <p class="mb-0 pb-0 s3-description">{{cms.S3.C2Description}}</p>
        </div>
    </div>
    <div class="col-sm-4 col-4 mb-pd-l-0">
        <div class="Home-Chefs text-center">
            <p class="mb-0"><img src="./assets/icons/new_icons/new_icons/customers.png" style="background:none;"/></p>
                        <p class="s3-title-1">{{cms.S3.C3Title}}</p>
                        <p class="mb-0 pb-0 s3-description">{{cms.S3.C3Description}}</p>
        </div>
    </div>
</div>
</div>


       <!-- <div *ngIf="cms!=null&&cms!=undefined" class="third_div plan_div_third" id="planyourplate">
            <h5 class="S3-section-heading">{{cms.S3.heading}}</h5>
            <h5 class="s3-section-heading2">{{cms.S3.heading2}}</h5>



             <div *ngIf="cms.S3!=undefined" class="row m-0 p-0">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 m-auto pl-0 align-self-center mt-0 pt-0"
                    style="height: fit-content; text-align: -webkit-center; margin-top: -1px !important;">
                    <div class="w-fitcontent p-0 m-0 column_content">
                        <p class=""><img src="./assets/icons/new_icons/new_icons/menus.png" style="background:none;"/></p>
                        <p class="s3-title-1">{{cms.S3.C1Title}}</p>
                        <p class="mb-0 pb-0 s3-description">{{cms.S3.C1Description}}</p>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 m-auto pl-0 align-self-center"
                    style="height: fit-content; text-align: -webkit-center;">
                    <div class="w-fitcontent p-0 m-0 column_content">
                        <p class=""><img src="./assets/icons/new_icons/new_icons/certified.png" style="background:none;"/></p>
                        <p class="s3-title-1">{{cms.S3.C2Title}}</p>
                        <p class="mb-0 pb-0 s3-description">{{cms.S3.C2Description}}</p>
                    </div>
                </div>

                <div class="col-xl-auto col-lg-auto col-md-4 col-sm-4 col-4 m-auto pl-0 align-self-center"
                    style="height: fit-content; text-align: -webkit-center;">
                    <div class="w-fitcontent p-0 m-0 column_content">
                        <p class=""><img src="./assets/icons/new_icons/new_icons/customers.png" style="background:none;"/></p>
                        <p class="s3-title-1">{{cms.S3.C3Title}}</p>
                        <p class="mb-0 pb-0 s3-description">{{cms.S3.C3Description}}</p>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->

        <!-- Know more banner -->
        <!-- <div *ngIf="cms!=null&&cms!=undefined" class="second_div container-fluid div_second_margin">
            <h5 class="font-weight-bolder section_heading d-sm-block d-xs-block d-lg-none d-md-none d-xl-none">
                {{cms.S4.title}}
            </h5>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <img [srcset]="utilService.getCDNMultipleResolutions(cms.S4.image)" alt="Home Chef"
                        style="max-height: 100%; aspect-ratio: 7/4;">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">

                    <div>
                        <h5 class="font-weight-bolder mb-lg-4 mb-2 section_heading d-none d-xl-block d-lg-block">
                            {{cms.S4.title}}
                        </h5>
                        <div>
                            <p *ngFor="let data of cms.S4.descriptions" class="mb-lg-3 mb-2 section_p">{{data.data}}</p>
                        </div>
                    </div>
                    <div class="two_buttons d-md-none d-lg-block">
                        <button class="btn btn-primary btn-block  border_2 font-weight-bolder text-uppercase"
                            routerLink="/our-story">Know More
                        </button>
                        <button class="btn btn-outline-primary btn-block  border_2 font-weight-bolder"
                            routerLink="/become-a-chef">BECOME
                            A CHEF
                        </button>
                    </div>
                </div>
            </div>

            <div class="two_buttons position-relative w-100 d-none d-md-block d-lg-none mt-2">
                <button class="btn btn-primary btn-block  border_2 font-weight-bolder text-uppercase"
                    routerLink="/our-story">Know More
                </button>
                <button class="btn btn-outline-primary btn-block  border_2 font-weight-bolder" routerLink="/become-a-chef"
                >BECOME
                    A CHEF
                </button>
            </div>
        </div> -->

        <!--  MEET OUR CHEFS-->
        <!-- <div *ngIf="cms!=null&&cms!=undefined" class="meetthechef">
            <h5 class="font-weight-bolder section_heading">{{cms.S5.heading}}</h5>

            <div *ngFor="let chef of homeChefs | slice: (page-1) * pageSize : page * pageSize;" class="row m-0 p-0"
                style="width: 100%;">
                <div class="col-xl-auto col-lg-auto col-md-6 col-sm-6 col-6 photo_col">
                    <img *ngIf="chef.hasOwnProperty('image1')" [alt]="chef.tag1"
                        [srcset]="utilService.getImageCDNURL(chef.image1)" alt="Home Chef" loading="lazy"
                        style="max-height: 100%; aspect-ratio: 370/500;">
                </div>
                <div class="col-lg-auto col-lg-auto col-md-6 col-sm-6 col-6 photo_col">
                    <img *ngIf="chef.hasOwnProperty('image2')" [alt]="chef.tag2"
                        [srcset]="utilService.getImageCDNURL(chef.image2)" alt="Home Chef" loading="lazy"
                        style="max-height: 100%; aspect-ratio: 370/500;">
                </div>
                <div class="col-xl col-lg col-md-12 col-sm-12 col-12 h-auto pl-lg-3 p-0"
                    style="width: 100%; position: relative;">
                    <div class="column_text_quote">
                        <h6 *ngIf="chef.quote!=''">
                            "{{chef.quote}}"</h6>
                    </div>
                    <div class="column_text">
                        <h5 class="font-weight-bolder ml-0 mb-0 section_subheading">{{chef.name}}</h5>
                        <p *ngIf="chef.itemName!=null&&chef.itemName!=undefined" class="section_p">Specialises
                            in {{chef.cuisines}}
                        </p>
                    </div>
                    <div class="column_text_about">
                        <h5 class="font-weight-bolder mb-0 section_subheading">About</h5>
                        <p class="section_p">{{chef.about}}</p>
                    </div>
                    <div class="mt-auto column_text">
                        <button (click)="openChefDetail(chef.id,chef.name)"
                            class="btn btn-primary btn-block  text-uppercase font-weight-bolder">See
                            menu
                        </button>
                    </div>
                </div>
            </div>

            <div style="width: fit-content; display: block; margin-left: auto; padding: 1% 0% 2% 4%;">
                <ngb-pagination [(page)]="page" [collectionSize]="collectionSize" [pageSize]="pageSize">
                </ngb-pagination>
            </div>
        </div> -->
<!-- new chef section -->
  
<!-- Commented change position of total meals served on 16/12/2022 replace food gram to total meals served -->
        <!-- <div *ngIf="cms!=null&&cms!=undefined" class="total_count" style="background-color: #F0B241;;">
            <div class="row h-100 w-100 align-content-center m-0 p-4">
                <div class="col-xl-4 col-lg-4 col-sm-4 col-4 text-center p-0 m-0">
                    <div class="m-auto">
                        <h1 class="text-white font-weight-bolder">{{cms.S6.data1}}+</h1>
                        <p class="mb-0" style="color: #ffffff; font-weight: 600;">Meals Served</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-4 col-4 text-center p-0 m-0">
                    <div class="m-auto">
                        <h1 class="text-white font-weight-bolder">{{cms.S6.data2}}+</h1>
                        <p class="mb-0" style="color: #ffffff; font-weight: 600;">5 - Star Reviews</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-4 col-4 text-center p-0 m-0">
                    <div class="m-auto">
                        <h1 class="text-white font-weight-bolder">{{cms.S6.data3}}+</h1>
                        <p class="mb-0" style="color: #ffffff; font-weight: 600;">Homemade Dishes</p>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Commented Delicious on 16/12/2022 -->
        <!--  SERIOUSLY, HAVEN'T TRIED THESE OUT?   S7-->
        <!-- <div *ngIf="cms!=null&&cms!=undefined" class="tried" style="margin: 4% 4% 4% 4%;">
            <h5 class="font-weight-bolder section_heading">{{cms.S7.heading}}</h5>
            <div class="row m-0 p-0">
                <div class="col-6 p-0">
                    <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover">
                        <ng-template *ngFor="let img of cms.S7.banner1; index as i" ngbSlide>
                            <a rel="nofollow noopener noreferrer" target="_blank">
                                <div class=" w-100 small_photos">
                                    
                                    <img [alt]="img.tag1" [srcset]="utilService.getCDNMultipleResolutions(img.image)"
                                        style="width: 98.5%; position: relative; max-height: 100%; aspect-ratio: 1100/550;">
                                </div>
                            </a>
                        </ng-template>
                    </ngb-carousel>
                    <ngb-carousel #carousel [interval]="5000">
                        <ng-template *ngFor="let img of cms.S7.banner2; index as i" ngbSlide>
                            <a rel="nofollow noopener noreferrer" target="_blank">
                                <div class=" w-100 small_photos">
                                   
                                    <img [alt]="img.tag1" [srcset]="utilService.getCDNMultipleResolutions(img.image)"
                                        style="width: 98.5%; margin-top: 1.5%; position: relative; max-height: 100%; aspect-ratio: 1100/550;">

                                </div>
                            </a>
                        </ng-template>
                    </ngb-carousel>
                </div>
                <div class="col-6 p-0">
                    <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover">
                        <ng-template *ngFor="let img of cms.S7.banner3; index as i" ngbSlide>
                            <a rel="nofollow noopener noreferrer" target="_blank">
                                <div class=" w-100 large_photos">
                                    
                                    <img [alt]="img.tag1" [srcset]="utilService.getCDNMultipleResolutions(img.image)"
                                        class="img-fluid"
                                        style="width: 100%; position: relative; max-height: 100%; aspect-ratio: 1100/1100;">

                                </div>
                            </a>
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
        </div> -->

        <!--  -->

       

        <!-- <owl-carousel [options]="carouselOptions">
            <ng-container *ngFor="let image of images">
              <ng-template carouselSlide [src]="image"></ng-template>
            </ng-container>
          </owl-carousel> -->
        <!-- OUR PARTNERS S9 -->
        <div *ngIf="cms!=null&&cms!=undefined" class="healthy_boring cms-s9-boring-div">
            

            <div class="row mb-flex-reverse">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 img_div cms-s9-img-div">
                    <!--          <img [src]="utilService.getImageCDNURL(cms.S9.image)" style="width: 100%; background-position: center">-->
                    <img [srcset]="utilService.getCDNMultipleResolutions(cms.S9.image)"
                         class="about-us-image">
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt -2 pt-lg-0 mb-pt-0">
                    <h5 class="about-us-heading">{{cms.S9.heading}}</h5>
                    <p class="about-description">{{cms.S9.title}}</p>
                    <p class="mb-0 about-description">{{cms.S9.description}}</p>
                  <div class="row aboutus-content-div">
                    <div class="col-6 about-text-center">
                        <p class="about-heading-p">{{cms.S9.label1}}</p>
                        <p class="descrption-heading-p">{{cms.S9.content1}}</p>
                    </div>
                    <div class="col-6 about-text-center">
                        <p class="about-heading-p">{{cms.S9.label2}}</p>
                        <p class="descrption-heading-p">{{cms.S9.content2}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 about-text-center">
                        <p class="about-heading-p">{{cms.S9.label3}}</p>
                        <p class="descrption-heading-p">{{cms.S9.content3}}</p>
                    </div>
                    <div class="col-6 about-text-center">
                        <p class="about-heading-p">{{cms.S9.label4}}</p>
                        <p class="descrption-heading-p">{{cms.S9.content4}}</p>
                    </div>
                  </div>
                  <div>
                    <button class="about-us-btn about-more-btn" [routerLink]="['/our-story']">More About Us</button>
                  </div>
                </div>
            </div>
        </div>
<!--  -->
<!-- Banner section -->

    <div class="curate-section pdt-0">

        <div class="row curate-row-gap">
            <div class="col-sm-6">
                <div class="s10-curate-image" [style]="bannerClass">
<h2>{{cms.S16?.title1}}</h2>
<div class="s10-curate-btn">
    <button class="btn" (click)="openOrderNow(cms.S16?.link1)">Order Now</button>
</div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="s10-curate-image" [style]="bannerClass2">
<h2>{{cms.S16.title2}}</h2>
<div class="s10-curate-btn">
    <button class="btn" (click)="openOrderNow(cms.S16?.link2)">Let’s Start</button>
</div>
                </div>
            </div>
        </div>

</div>

<!--  -->
<!-- <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
          <div class="col-md-4">Item 1</div>
          
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div class="col-md-4">Item 4</div>
       
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div class="col-md-4">Item 7</div>
         
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div> -->
  
  <!-- <ngb-carousel
  #carousel
  [interval]="6000"
  (slide)="onSlide($event)"
>
  <ng-template ngbSlide *ngFor="let img of images; index as i">
      <div class="carousel-caption">
          <h3>My slide {{i + 1}} title</h3>
      </div>
      <a href="https://www.google.fr/?q=Number+{{i+1}}" target="_blank" rel="nofollow noopener noreferrer">
          <div class="picsum-img-wrapper">
              <img [src]="img" alt="My image {{i + 1}} description" />
          </div>
      </a>
  </ng-template>
</ngb-carousel> -->
<!--  -->
<div *ngIf="cms!=null&&cms!=undefined" id="faq">
    <div class="faq_cont pdt-0">
        <!-- <h5 class="font-weight-bolder section_heading" style="font-family: NotoSans;">FREQUENTLY ASKED QUESTIONS
            FOR
            CUSTOMERS AND CHEFS
        </h5> -->
        <div class="row">
            <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="row">
                    <div class="col-12 col-lg-9 col-md-12 col-sm-12 fc-000 fw-700"><h5 class="fc-000 fw-700 fnt-25 fnt-16px-tab pdr-12 mb-30-px">{{cms.ContactUs.heading}}</h5></div>
                    <div class="col-12 fc-000"><p class="fc-000 fw-400 fnt-18 mb-60-px">{{cms.ContactUs.desc}}</p></div>
                    <div class="col-12">
                        <button class="contact-btn" routerLink="/contact-us">Contact us</button>
                    </div>
                </div>
            </div>
        <div class="col-lg-6 col-md-6 col-xs-12 scroll-p-0 faq-tab-size pd-l-3 pl-0-faq" *ngIf="cms!=undefined&&cms.hasOwnProperty('S12faq')" id="accordion"
            style="height: 100%; overflow-y: auto; max-height: 52vh; min-height: fit-content;">
            <div *ngFor="let faq of cms.S12faq; let i = index" class="card card-list">
                <div class="card-header br-bottom-none pd-21-px fc-D9D9D9" id="heading-1">
                    <div class="mb-0">
                        <a aria-expanded="false" class="collapsed d-flex pr-3" data-toggle="collapse"
                            href="#collapse-{{i+1}}" role="button">
                            <h6 class="mb-fs-10 fw-400 fs-12 fs-16 mb-3px " >{{faq.question}}</h6>
                        </a>
                    </div>
                    <div aria-labelledby="heading-1" class="collapse" data-parent="#accordion"
                        id="collapse-{{i+1}}">
                        <div class="card-body p-2 pl-0" style="background-color: #D9D9D9;">
                            <p class="mb-fs-10 fw-400 fs-12 fs-16" style=" margin-left: -8px; letter-spacing: 1px;">{{faq.answer}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>

<!--  -->
<!-- <div class="container" >
    <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="4000">
        <div class="carousel-inner row w-100 mx-auto" role="listbox">
            <div class="carousel-item col-md-3  ">
               <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 1" class="thumb">
                      <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=1" alt="slide 1">
                    </a>
                  </div>
                </div>
            </div>
            <div class="carousel-item col-md-3 ">
               <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 3" class="thumb">
                     <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=2" alt="slide 2">
                    </a>
                  </div>
                </div>
            </div>
            <div class="carousel-item col-md-3 ">
               <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 4" class="thumb">
                     <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=3" alt="slide 3">
                    </a>
                  </div>
                </div>
            </div>
            <div class="carousel-item col-md-3 active">
                <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 5" class="thumb">
                     <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=4" alt="slide 4">
                    </a>
                  </div>
                </div>
            </div>
            <div class="carousel-item col-md-3 ">
              <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 6" class="thumb">
                      <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=5" alt="slide 5">
                    </a>
                  </div>
                </div>
            </div>
            <div class="carousel-item col-md-3 ">
               <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 7" class="thumb">
                      <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=6" alt="slide 6">
                    </a>
                  </div>
                </div>
            </div>
            <div class="carousel-item col-md-3 ">
               <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 8" class="thumb">
                      <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=7" alt="slide 7">
                    </a>
                  </div>
                </div>
            </div>
             <div class="carousel-item col-md-3  ">
                <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 2" class="thumb">
                     <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=8" alt="slide 8">
                    </a>
                  </div>
                  
                </div>
            </div>
  <div class="carousel-item col-md-3  ">
                <div class="panel panel-default">
                  <div class="panel-thumbnail">
                    <a href="#" title="image 2" class="thumb">
                     <img class="img-fluid mx-auto d-block" src="//via.placeholder.com/200x200?text=8" alt="slide 9">
                    </a>
                  </div>
                  
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next text-faded" href="#myCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
  </div> -->




<!-- Wine, platter S8 -->
<div *ngIf="cms!=null&&cms!=undefined" class="party-mb-div mrgn-tb">
    <!-- <h5 class="font-weight-bolder text-uppercase section_heading" style="padding: 0% 4%;">{{cms.S8.heading}} -->
    <!-- </h5> -->

    <div class="party_banner" >
        <!--        <img [src]="utilService.getImageCDNURL(cms.S8.image)" alt="Dessert" class="">-->
        <img [srcset]="utilService.getCDNMultipleResolutions(cms.S8.image)" alt="Dessert" class="part-image-banner"
            >
        <div class="carousel-caption">
            <p class="mb-0 title1">{{cms.S8.title1}}</p>
            <div class="btn-party-dinner"> 
                <button class="know-more-btn cursorpointer" routerLink="/our-story">Know more</button>
            <button class="became-btn cursorpointer" routerLink="/become-a-chef">Become a chef</button>
            </div>
            
            <!-- <p class="text-uppercase mb-0 font-weight-bolder title2">{{cms.S8.title2}}</p> -->
            <!-- <p class="d-none d-xs-none d-sm-none d-md-none d-lg-none d-xl-block email">{{cms.S8.email}}</p> -->
        </div>
    </div>
</div>
<!-- Wine, platter -->
        <!-- TESTIMONIALS -->
        <div class="testimonials" style="background: #E2E2E2;">
            <div class="d-flex mb-30-px">
                <h5 class="font-weight-bolder mr-4 section_heading">TESTIMONIALS</h5>
                <div class="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block ml-auto" style="margin-top: -5px;">
                    <div style="float: left">
                        <a data-slide="prev" href="#carousel-thumb-testi_desktop">
                            <button class="arrow-btn" style="background: none; border: none;">
                                <!-- <i aria-hidden="true" class="fa fa-chevron-left"></i> -->
                                <img class="served-slide-icon" src="../../../assets/icons/new_icons/new_icons/long-left-arrow.png" alt="" style="background: none; width:20px">
                                <!-- <img class="served-slide-icon" src="../../../assets/icons/new_icons/left-arrow.svg" alt="" style="background: none;"> -->
                            </button>
                        </a>
                    </div>
                    <div style="float: right">
                        <a data-slide="next" href="#carousel-thumb-testi_desktop">
                            <button class="arrow-btn" style="background: none; border: none;">
                                <!-- <i aria-hidden="true" class="fa fa-chevron-right"></i> -->
                                <img class="served-slide-icon" src="../../../assets/icons/new_icons/new_icons/long-right-arrow.png" alt="" style="background: none; width: 20px;"> 
                                <!-- <img class="served-slide-icon" src="../../../assets/icons/new_icons/right-arrow.svg" alt="" style="background: none;">  -->
                            </button>
                        </a>
                    </div>
                </div>
                <div class="d-sm-block d-xs-block d-lg-none d-md-none d-xl-none ml-auto" style="margin-top: -5px;">
                    <div style="float: left">
                        <a data-slide="prev" href="#carousel-thumb-vision">
                            <button class="arrow-btn" style="background: none; border: none;">
                                <!-- <i aria-hidden="true" class="fa fa-chevron-left"></i> -->
                                <img class="served-slide-icon" src="../../../assets/icons/new_icons/new_icons/long-left-arrow.png" alt="" style="background: none; width: 20px;">
                                <!-- <img class="served-slide-icon" src="../../../assets/icons/new_icons/left-arrow.svg" alt="" style="background: none;"> -->
                            </button>
                        </a>
                    </div>
                    <div style="float: right">
                        <a data-slide="next" href="#carousel-thumb-vision">
                            <button class="arrow-btn" style="background: none; border: none;">
                                <!-- <i aria-hidden="true" class="fa fa-chevron-right"></i> -->
                                <img class="served-slide-icon" src="../../../assets/icons/new_icons/new_icons/long-right-arrow.png" alt="" style="background: none; width: 20px;">
                                <!-- <img class="served-slide-icon" src="../../../assets/icons/new_icons/right-arrow.svg" alt="" style="background: none;"> -->
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <!-- for mobile -->
            <section class="corousal d-sm-block d-xs-block d-lg-none d-md-none d-xl-none">
                <div class="carousel carousel-thumbnails slide" data-interval="false" data-ride="carousel"
                    id="carousel-thumb-vision">
                    <div class="carousel-inner" role="listbox">
                        <div class="testimonial-main" *ngFor="let item of mobTestimonials;let i=index;" [class]="item.class">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="profile-img">
                                            <!--                      <img [src]="utilService.getImageCDNURL(item.test.userimage)" />-->
                                            <img
                                                [srcset]="utilService.getCDNMultipleResolutions(item.test.userimage)" />
                                        </div>
                                        <div class="name-user">
                                            <span>@{{item.test.username}}</span>
                                            <strong class="section_sub_p">About {{item.test.chefname}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex testimonials-rating">
                                    <h6 class="m-0 font-weight-bolder">{{item.test.title}}</h6>
                                    <div class="reviews">
                                        <i *ngIf="item.test.rating>0" class="fa fa-star"></i>
                                        <i *ngIf="item.test.rating>1" class="fa fa-star"></i>
                                        <i *ngIf="item.test.rating>2" class="fa fa-star"></i>
                                        <i *ngIf="item.test.rating>3" class="fa fa-star"></i>
                                        <i *ngIf="item.test.rating>4" class="fa fa-star"></i>
                                    </div>
                                </div>
                              
                                <div class="client-comment">
                                    <p>{{item.test.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- for mobile -->

            <!-- for desktop -->

            <div class="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
                <section class="corousal">
                    <div class="carousel carousel-thumbnails slide" data-interval="false" data-ride="carousel"
                        id="carousel-thumb-testi_desktop">
                        <div class="carousel-inner" role="listbox">
                            <div *ngFor="let test of testimonials;let i=index;" [class]="test.class">
                                <div class="d-flex testimonial-main">
                                    <div *ngFor="let item of test.test" class="testimonial-box">
                                        <div>
                                            <div class="box-top">
                                                <div class="profile">
                                                    <div class="profile-img">
                                                        <!--                            <img [src]="utilService.getImageCDNURL(item.userimage)" />-->
                                                        <img
                                                            [srcset]="utilService.getCDNMultipleResolutions(item.userimage)" />
                                                    </div>
                                                    <div class="name-user">
                                                        <span>@{{item.username}}</span>
                                                        <strong class="section_sub_p">About {{item.chefname}}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex testimonials-rating">
                                                <h6 class="m-0 font-weight-bolder">{{item.title}}</h6>
                                                <div class="reviews">
                                                    <i *ngIf="item.rating>0" class="fa fa-star"></i>
                                                    <i *ngIf="item.rating>1" class="fa fa-star"></i>
                                                    <i *ngIf="item.rating>2" class="fa fa-star"></i>
                                                    <i *ngIf="item.rating>3" class="fa fa-star"></i>
                                                    <i *ngIf="item.rating>4" class="fa fa-star"></i>
                                                </div>
                                            </div>
                                           
                                            <div class="client-comment">
                                                <p>{{item.description}}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <!-- for desktop -->

        </div>
<!-- press section -->
 <div *ngIf="cms!=null&&cms!=undefined" class="press-padding-mb" style="background:#fff; padding:3.1% 4%;">
    <div class="row">
        <div class="col-6">
            <p class="press-heading">{{cms.S7.heading}}</p>
        </div>
        <div class="col-6">
            <div class="press-icon">
                <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="scrollLeftPress()" [ngClass]="this.leftPressDisabled ? 'cursor-not-allowed':''"></i>
                <i class="fa fa-long-arrow-right" aria-hidden="true" (click)="scrollRightPress()" [ngClass]="this.rightPressDisabled ? 'cursor-not-allowed':''"></i>
            </div>
           
        </div>
    </div>
    <div class="row press-sect mb-press-sec">
        <div #widgetsContentPress class="press-div">
        <div  class="col" *ngFor="let img of cms.S7.banner1; index as i">
            <div (click)="pressEvent(i)" class="py-2" >
                <img [srcset]="utilService.getCDNMultipleResolutions(img.image)" class="press-img" [ngClass]="this.cms.S7.banner1[i].tag1 !=undefined ? 'title-cursor' : 'title-href'"/>
            </div>
        </div>
    </div>
    </div>
  </div>

<!--  -->
<!-- coming soon section -->
<div *ngIf="cms!=null&&cms!=undefined" class="mobile_app bg-EEA471" style="padding: 3.1% 4%;">
    <div class="row">
        <div class="col-lg-6 col-md-7 col-6 pr-0">
            <div class="py-17px">
            <h6 class=" mobile-title1-fs mb-1">{{cms.S13.title2}}</h6>
            <h1 class="mobile-title2-fs mt-16px mb-0">{{cms.S13.description2}}</h1>
            <div><button class="coming-soon-btn">Coming Soon</button></div>
        </div>
    </div>
      
        <div class="col-lg-6 col-md-5 col-6 mb-pad-0">
            <!--          <img [src]="utilService.getImageCDNURL(cms.S13.image2)" alt="" class="rounded-0">-->
            <div class="col-lg-6 col-12 p y -1 7px pdy-17">
            <img width="100%" [srcset]="utilService.getCDNMultipleResolutions(cms.S13.image2)" alt=""
                class="rounded-0 t_img">
            </div>
        </div>
      
    </div>
</div>


<!--  -->

<!-- new newsletter and end section  -->
<div class="join_newsletter mrg-top-0" style="background-color: #fff; width: 100%; padding: 2% 4%;">

    <div class="row mb-pt-22 mb-pb-22 pt-4 pb-4  w-100">
        <div class="col-lg-4 col-sm-12 col-12 col-md-5">
            <h5 class="fw-700 fc-000 fs-25 text-nowrap ml-0 pl-lg-auto section_heading mb-30-px">
                Join the Community</h5>
                <h5 class="fw-400 fc-000 mb-pb-16 text-nowrap ml-0 pl-lg-auto section_heading">
                    Get updates on specials </h5>
        </div>
        <div class="col-lg-5 col-7 col-sm-8 col-md-4 align-self-end pr-0">
            <input [(ngModel)]="subscribe_email" class="join_input form-control p-21-0 input-pd-3"
                placeholder=""  type="text">
        </div>
        <div class="col-lg-auto col-5 col-sm-4 col-md-2 mt-2 mt-lg-0 align-self-end pl-0">
            <button (click)="addSubscription()" class="btn subscripe-btn text-dark btn-block"
                >
                <img src="../../../assets/icons/new_icons/subscribe.svg" class="subscribe-img"/>
                Subscribe Now
            </button>
        </div>
    </div>
</div>
<!--  -->

<div style="padding: 2.2% 4% 0%; background-color: #e2e2e263;" class="handpick-mt-mb-7">
    <div class="row">
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17 where-Tradition">At We The Chefs, Tradition Finds a Home</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Join our extended family of skilled home chefs as we bring the essence of homemade cuisine from across India to your doorstep in Gurgaon, Delhi, and Bangalore. Discover a variety of delectable options that include </h5>
            <li class="s3-section-heading2 static-mb-15 des-static-14">Kashmiri Home Food</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">Home Cooked Mughlai Food</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">Homemade Anglo Indian Food</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">Homemade North Indian Food</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">South Indian Home Cooked Food</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">Homemade Desert</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">Home Cooked Karnataka Cuisine</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">Homemade Gujarati Food</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">Homemade Continental Food</li>
                <li class="s3-section-heading2 static-mb-15 des-static-14">Home Food Snacks</li>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Decide your home cooked meal as per your occasion needs</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">No matter the occasion—be it a birthday party, dates, housewarming, or any other small gathering & celebration—we're here to cater to your needs with the finest custom-made food menus, ensuring your expectations are not just met but exceeded.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Meet Our Star Home Chefs from Gurgaon, Delhi & Bangalore</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Indulge in the richness of home cooked food with We The Chef, your go-to online platform for exquisite homemade food in Gurgaon, Delhi & Bangalore. Our dedicated home chefs craft authentic Indian and international cuisines, delivering a taste of tradition to your doorstep. Whether you're craving a regional delicacy or planning a special event, our commitment to quality and freshness ensures a heavenly culinary experience.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Elevate your dining experience with us and savour the essence of homemade food</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Good food is the foundation of genuine happiness, and at We The Chefs, we believe in crafting home cooked meals that resonate with that sentiment. Picture a table filled with the delightful symphony of 'mmm' and the satisfying clinks of cutlery, where every plate is licked clean. Our culinary journey is built on the pillars of authenticity, secret recipes, and the creation of unforgettable food experiences.</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Established with a mission to deliver the taste and warmth of home cooked food, brimming with 'Maa ka pyar,' We The Chef takes pride in promoting hygiene and quality home food. Our culinary artisans aim to make every occasion special, be it birthdays, kitty parties, corporate dinners, or office party, by offering a delightful array of home cooked food in Gurgaon, Delhi and Bangalore. We strive to unite the diverse cultures and flavours of India conveniently, making small party catering a breeze and ensuring healthy, delicious meals are delivered straight to your doorstep. In essence, We The Chefs is not just about meals; it's about bringing people together through the joy of homely, hearty food.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Our Vision and Mission</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Empower your palate with curated gourmet food experiences, effortlessly delivered to your doorstep in Gurgaon, Delhi, and Bangalore. We strive to revolutionize dining by connecting food lovers with carefully selected home chefs, promoting diverse flavors, and making gourmet and home cooked meals easily accessible online.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">What Sets Us Apart</h5>
            <li class="s3-section-heading2 static-mb-15 des-static-14">Homemade Food Made with Fresh Ingredients</li>
            <li class="s3-section-heading2 static-mb-15 des-static-14">Handpicked Home Chefs</li>
            <li class="s3-section-heading2 static-mb-15 des-static-14">Culinary Feast</li>
            <li class="s3-section-heading2 static-mb-15 des-static-14">Uncompromising Quality</li>
            <li class="s3-section-heading2 static-mb-15 des-static-14">Tailored For You</li>
            <li class="s3-section-heading2 static-mb-15 des-static-14">Effortless Ordering</li>
            <li class="s3-section-heading2 static-mb-15 des-static-14">Safe and Swift Delivery</li>
            <h5 class="s3-section-heading2 static-mb-15 txt-align-just des-static-14">With the help of our carefully chosen, gifted in-house chefs from all over India, we help you experience the true essence of freshly homemade food in Gurgaon, Delhi, and Bangalore.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Handpicked Home Chefs</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">We meticulously select our passionate home chefs from Gurgaon, Delhi, and Bangalore, guaranteeing not just delicious but also hygienic, high-quality home cooked meals.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Culinary Feast</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">With our carefully chosen assortment of gourmet homemade food, indulge in a world of gourmet and regional cuisines, honouring traditional flavors and bringing long-lost recipes back to life.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Uncompromising Food Quality</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Elevate your dining experience with high-quality home cooked meals, meticulously prepared to ensure impeccable hygienic standards using the finest ingredients available.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Tailored for You</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Revel in the joy of personalization as we cater to your tastes, curating the perfect party food menu that reflects your unique preferences.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Effortless Food Ordering</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Seamlessly order our gourmet and homemade food online in Gurgaon, Delhi, & Bangalore, ensuring convenience and simplicity in every step of your culinary journey.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Safe and Swift Food Delivery</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Enjoy peace of mind with our safe homemade food delivery service, bringing our delectable home cooked food right to your doorstep in Gurgaon, Delhi, & Bangalore, ensuring a hassle-free experience.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Tried and Tested Food Menus</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">We personally taste each chef’s food menu.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">FSSAI Certified</h5>
            <h5 class="s3-section-heading2 txt-align-just des-static-14">Our home chefs adhere to regulated cleanliness standards.</h5>
        </div>
        <div class="col-sm-12">
            <h5 class="press-heading static-fs-17">Loved by Customers</h5>
            <h5 style="margin-bottom: 0px!important;" class="s3-section-heading2 txt-align-just des-static-14">We closely monitor verified customer reviews for feedback.</h5>
        </div>
    </div>
  
    </div>
    <div style="padding: 0.2% 4% 2.2% 4%; background-color: #e2e2e263;" class="handpick-mt-mb-7">
        <div *ngFor="let item of data;let i = index;">
            <button style="background:none; padding:12px 0px;" class="accordionD static-fs-17-faq press-heading-faq" (click)="toggleAccordian($event, i)"> {{item.parentName}} </button>
            <div style="background:none" class="panelD" hide="!item.isActive">
            
              <p *ngFor="let child of item.childProperties" class="s3-section-heading2-faq txt-align-just des-static-14-faq"> {{child.propertyName}} </p>
            </div>
          </div></div>
<!-- <app-r-t-order></app-r-t-order> -->
</div>
</div>

<!--  -->
        <!--   SHARE _WETHECHEFS_ LOVE s10-->
        <!-- <div *ngIf="cms!=null&&cms!=undefined" class="sharewethecheflove">
        <h5 class="font-weight-bolder section_heading">{{cms.S10.heading}} <a
            href="https://www.instagram.com/wethechefs.in/" target="_blank"><i aria-hidden="true"
              class="fa fa-instagram instagram_logo"></i></a>
        </h5>
        <div class="d-flex m-auto justify-content-center">
          <div class="image_tran">
            <div class="hover05">
              <div class="fig">
                <a [href]="cms.S10.link1" target="_blank"><img [src]="apiService.BASE_IMAGE_URL+cms.S10.image1" alt=""
                ></a>
              </div>
            </div>
            <div class="hover05">
              <div class="fig">
                <a [href]="cms.S10.link2" target="_blank"><img [src]="apiService.BASE_IMAGE_URL+cms.S10.image2" alt=""
                ></a>
              </div>
            </div>
            <div class="hover05">
              <div class="fig">
                <a [href]="cms.S10.link3" target="_blank"><img [src]="apiService.BASE_IMAGE_URL+cms.S10.image3" alt=""
                ></a>
              </div>
            </div>
            <div class="hover05">
              <div class="fig">
                <a [href]="cms.S10.link4" target="_blank"><img [src]="apiService.BASE_IMAGE_URL+cms.S10.image4" alt=""
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- NEw position of Total meals served -->
      <!-- <div *ngIf="cms!=null&&cms!=undefined" class="total_count" style="background-color: #F0B241;;">
        <div class="row h-100 w-100 align-content-center m-0 p-4">
            <div class="col-xl-4 col-lg-4 col-sm-4 col-4 text-center p-0 m-0">
                <div class="m-auto">
                    <h1 class="text-white font-weight-bolder">{{cms.S6.data1}}+</h1>
                    <p class="mb-0" style="color: #ffffff; font-weight: 600;">Meals Served</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4 col-4 text-center p-0 m-0">
                <div class="m-auto">
                    <h1 class="text-white font-weight-bolder">{{cms.S6.data2}}+</h1>
                    <p class="mb-0" style="color: #ffffff; font-weight: 600;">5 - Star Reviews</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4 col-4 text-center p-0 m-0">
                <div class="m-auto">
                    <h1 class="text-white font-weight-bolder">{{cms.S6.data3}}+</h1>
                    <p class="mb-0" style="color: #ffffff; font-weight: 600;">Homemade Dishes</p>
                </div>
            </div>
        </div>
    </div> -->
        <!-- Commented for remove Food Gram on 16/12/2022 -->
        <!-- <div class="sharewethecheflove">
            <h5 class="font-weight-bolder section_heading">{{cms.S10.heading}} <a
                    href="https://www.instagram.com/wethechefs.in/" target="_blank"><i aria-hidden="true"
                        class="fa fa-instagram instagram_logo"></i></a>
            </h5>
            <div class="d-flex m-auto justify-content-center">
                <div class="image_tran">
                    <div *ngFor="let data of insta; let i = index" class="hover05">
                        <div class="fig" *ngIf="i<4">
                            <a href="{{data.permalink}}" target="_blank"><img alt="" src="{{data.media_url}}"
                                ></a>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div> -->
<!-- End End Food Gram -->

        <!-- <div id="faq" style="height: 0px; width: 0px;"></div> -->
       

       
        <!-- <div *ngIf="cms!=null&&cms!=undefined" class="bg-primary ready_to_order" style="padding: 2% 4%;">
            <div class="d-flex position-relative">
                <div class="mr-lg-auto py-3 py-lg-0">
                    <h6 class="text-white text-uppercase font-weight-bolder section_p mb-1">{{cms.S13.title1}}</h6>
                    <h2 class="text-white text-uppercase font-weight-bolder section_heading">{{cms.S13.description1}}
                    </h2>
                    <button class="btn bg-white  btn-block text-uppercase font-weight-bolder" routerLink="/search-cuisine/book-your-order">
                        Get
                        Started
                    </button>
                </div>
                <div class="position-absolute d-none d-sm-none d-xs-none d-lg-block d-md-none d-xl-block"
                    style="right: 0%; top: 0%;">
                    <img [srcset]="utilService.getCDNMultipleResolutions(cms.S13.image1)" alt=""
                        style="width: 250px; height: 250px;" class="t_img">
                </div>
            </div>
        </div> -->

       

        
        <div *ngIf="quantity != 0" class="checkout_footer d-flex">
            <div class="align-self-center">
              <p style="font-weight: 600; margin-left: 10px; margin-bottom: 0px;" class="fs-order-30 mob-check-ft">
                Cart Value (<span>&#8377;</span>{{ totalPrice }})
              </p>
              <p *ngIf="remainingAmount > 0" style="color: red; font-weight: 600; margin-left: 10px; margin-bottom: 0px;" class="mob-check-ft">
                Add items worth Rs. {{ remainingAmount }} more to proceed.
              </p>
            </div>
            <div class="ml-auto align-self-center">
              <button
              [class.btn-primary]="totalPrice >= min_order_val"
              [class.btn-secondary]="totalPrice < min_order_val"
              [disabled]="totalPrice < min_order_val"
                class="btn btn-primary text-white checkout-btn-mb"
                style="margin-right: 10px;"
                (click)="checkOut()"
              >
                Place Order
              </button>
            </div>
          </div>

<!-- <app-footer style="margin-bottom: 100px;"></app-footer> -->