import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignupComponent } from '../signup/signup.component';
import { AuthmodalComponent } from "../authmodal/authmodal.component";
import { APIService } from "../../../services/api.service";
import { UtilService } from "../../../services/util.service";
import { ToastrService } from "ngx-toastr";
import { PhoneverficationComponent } from "../phoneverfication/phoneverfication.component";
import { ForgotPasswordComponent } from "../../../modals/forgot-password/forgot-password.component";
import { AuthService } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // email = 'vishwakarmasunil68@gmail.com';
  // password = 'Sunil@123456';
  email = '';
  password = '';
phone;
  validEmail = true;
  validPhone = true;
  validEmailText = '';
  validPhoneText = '';
  validPassword = true;
  validPasswordText = '';
  submitted = false;
  otp;
  resend_disabled = true;
  userProfile;
  verified = false;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private apiService: APIService,
    public utilService: UtilService,
    private toaster: ToastrService,
    public auth: AuthService,
    public http: HttpClient
  ) {
    
  }

  ngOnInit(): void {
    
  }
  loginWithGoogle() {
    this.auth.loginWithRedirect();
  }
  login2() {
    const data = {
      client_id: 'IQbtujPtTlQ2ehwuvKNZZFdXeV8ArW6W',
      username: this.email,
      password: this.password,
      realm: 'Username-Password-Authentication',
      grant_type: 'password',
      scope: 'openid profile email'
    };

    this.http.post('https://dev-pi3bps6fhyrgugtu.us.auth0.com/oauth/token', data).subscribe(
      (response: any) => {
        console.log('Login successful', response);
        // Handle successful login
      },
      (error) => {
        console.error('Login failed', error);
        // Handle login failure
      }
    );
  }

  // loginWithGoogle2() {
  //   window.location.href = `https://dev-pi3bps6fhyrgugtu.us.auth0.com/authorize?response_type=token&client_id=IQbtujPtTlQ2ehwuvKNZZFdXeV8ArW6W&redirect_uri=${window.location.origin}&scope=openid profile email&connection=google-oauth2`;
  // }

  // loginWithFacebook() {
  //   window.location.href = `https://dev-pi3bps6fhyrgugtu.us.auth0.com/authorize?response_type=token&client_id=IQbtujPtTlQ2ehwuvKNZZFdXeV8ArW6W&redirect_uri=${window.location.origin}&connection=facebook`;
  // }
  // private handleAuthResponse() {
  //   if (window.location.hash) {
  //     const hash = window.location.hash.substr(1);
  //     const params = new URLSearchParams(hash);
  //     const accessToken = params.get('access_token');
  //     const idToken = params.get('id_token');

  //     // Handle access token
  //     if (accessToken) {
  //       localStorage.setItem('access_token', accessToken);
  //       this.fetchUserProfile(accessToken);
  //     }

  //     // Handle ID token if needed
  //     if (idToken) {
  //       localStorage.setItem('id_token', idToken);
  //     }

  //     // Clean up URL (remove token from URL)
  //     window.history.replaceState({}, document.title, window.location.pathname);
  //   }
  // }
  
  // private fetchUserProfile(token: string) {
  //   console.log(token,"token")
  //   this.http.get('https://dev-pi3bps6fhyrgugtu.us.auth0.com/userinfo', {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   }).subscribe((userInfo: any) => {
  //     console.log('User Info:', userInfo);
  //     // User info contains profile details like name, email, etc.
  //     // localStorage.setItem('user_info', JSON.stringify(userInfo));
  //   }, error => {
  //     console.error('Failed to fetch user profile', error);
  //   });
  // }
  validateChef() {
    let isValid = true;
// console.log(this.phone,"pone")

    if (this.phone == undefined) {
      // if (this.phone == '') {
      isValid = false;
      this.validPhone = false;
      this.validPhoneText = 'Please enter phone number';
    } else {
      this.validPhone = true;
    }
    // if (!this.utilService.validateEmail(this.email)) {
    //   isValid = false;
    //   this.validEmail = false;
    //   this.validEmailText = 'Please enter correct email';
    // } else {
    //   this.validEmail = true;
    // }
    // if (this.password == '') {
    //   isValid = false;
    //   this.validPassword = false;
    //   this.validPasswordText = 'Please enter password';
    // } else {
    //   this.validPassword = true;
    // }

    return isValid;
  }

  login() {

    if (!this.validateChef()) {
      return;
    }
    let postData = {
      "phone": this.phone,
      // "password": this.password,
    }
console.log(postData,"Deepak")
this.apiService.postAPI(this.apiService.BASE_URL + 'user/loginByPhoneOtp', {
  phone: this.phone,
  type: 'customer'
}).then((result) => {
  if (result.status) {
    this.toaster.success('OTP sent to phone');
    this.submitted = true;
    this.userProfile = result.result;
    this.setResendEnableTimer();
  } else {
    this.toaster.error(result.message);
  }
}, (error) => {
  console.log('something went wrong');
})
    // this.apiService.postAPI(this.apiService.BASE_URL + "user/login", postData).then((result) => {
    //   if (result.status) {
    //     if (result.result.user.type == '0') {
    //       this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(result.result.user));
    //       if (result.result.user.phone_verified == 0) {
    //         this.openEmailVerificationModal()
    //       } else {
    //         this.utilService.setItem(this.utilService.USER_LOGIN, '1');
    //         this.activeModal.close(AuthmodalComponent);
    //         this.activeModal.close();
    //       }

    //     } else {
    //       this.toaster.error("Invalid email/password");
    //     }
    //   } else {
    //     this.toaster.error("Oops! Something’s not right. <br /> We can’t find an account with those details. Please check your username(phone) or password and try again. We are waiting for you!",
    //       '', { enableHtml: true }
    //     );
    //   }
    // }, (error) => {
    //   console.log("something went wrong");
    // })
  }
  submitOTP() {
    if (this.otp == undefined || this.otp.toString().length == 0) {
      this.toaster.error("please enter otp");
      return;
    }

    let userType = "customer";

    this.apiService.postAPI(this.apiService.BASE_URL + "user/verifyLoginOTP", {
      id: this.userProfile.id,
      type: userType,
      otp: this.otp
    }).then((result) => {
      if (result.status) {
        if (result.result.user.type == '0') {
          this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(result.result.user));
          if (result.result.user.phone_verified == 0) {
            this.openEmailVerificationModal()
          } else {
            this.utilService.setItem(this.utilService.USER_LOGIN, '1');
            this.activeModal.close(AuthmodalComponent);
            this.activeModal.close();
          }

        } else {
          this.toaster.error("Invalid email/password");
        }
      } else {
        this.toaster.error("Oops! Something’s not right. <br /> We can’t find an account with those details. Please check your username(phone) or password and try again. We are waiting for you!",
          '', { enableHtml: true }
        );
      }
    }, (error) => {
      console.log("something went wrong");
    })
  }
  timeLeft: number = 120000;
  interval;

  setResendEnableTimer() {
    this.timeLeft = 120000;
    this.interval = setInterval(() => {
      // console.log('timeleft:-' + this.timeLeft);
      if (this.timeLeft > 0) {
        this.timeLeft = this.timeLeft - 1000;
        this.resend_disabled = true;
      } else {
        this.clearTimer();
        this.resend_disabled = false;
      }
    }, 1000);
  }

  clearTimer() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  }
  openEmailVerificationModal() {
    const modalRef = this.modalService.open(PhoneverficationComponent, {
      size: '',
      windowClass: "mobileClass",
      centered: true
    });
    modalRef.componentInstance.send_confirmation = "1";
    modalRef.componentInstance.reset_password = "0";

    // this.activeModal.close();
  }

  opensignin() {
    const modalRef = this.modalService.open(SignupComponent, {
      windowClass: "mobileClass_signUp",
    });
    modalRef.closed.subscribe((result) => {
      this.activeModal.close()
    })
    modalRef.componentInstance.type = 'customer';
    modalRef.componentInstance.orignFrom = 'customer';
  }

  closeModal() {
    this.activeModal.close();
  }

  forgotPassword() {
    this.activeModal.close()
    const modalRef = this.modalService.open(ForgotPasswordComponent, {
      size: '',
      windowClass: "mobileClass",
      centered: true
    });
  }
}
