import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deliveryFilter'
})
export class DeliveryFilterPipe implements PipeTransform {

  transform(items: any, element:string, value:any, filtertype : string) {
    if(items != null && items != undefined && items.length > 0 && value != undefined)
    {
      if(filtertype.toUpperCase() == "SAMEDAY")
      {
        return items.filter(item => item[element] == value);
      }
      else if(filtertype.toUpperCase() == "PREORDER")
      {
        return items.filter(item => item[element] > value ||  item[element] == "null" || item[element] == "" || item[element] == null || item[element] == undefined);
      }
      else
      {
        return items;
      }
    }
    else
    {
      return items;
    }
    return items;
  }

}
