import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderIdService {

  constructor() { }
  private orderNumber: string;

  setOrderNumber(orderNumber: string) {
    // this.orderNumber = orderNumber;
    localStorage.setItem("order_id",orderNumber)
  }

  getOrderNumber() {
    return localStorage.getItem('order_id');
  }
}
