<!-- <p>navbar works!</p> -->
<!-- <p>navbar works!</p> -->
<div *ngIf="home_route == true && message !='' && title !=''" class="broad-class" (click)="openBroadcast()" [ngClass]="title != '' ? 'title-cursor' : 'title-href'">{{message}}</div>
<div *ngIf="home_route == true && message !='' && title ==''" class="broad-class"  [ngClass]="title != '' ? 'title-cursor' : 'title-href'">{{message}}</div>
<nav class="navbar navbar-expand-lg navbar-default navbar-static-top fixed-top">
  <!-- <nav class="navbar navbar-expand-lg navbar-default navbar-static-top fixed-top bg-transparent"> -->
  <div class="d-flex flex-grow-1">
    <div class="w-100 text-left align-self-center">
      <div class="d-flex">
        <div>
          <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
            <!-- <i class="fa fa-bars" aria-hidden="true"></i> -->
            <img alt="" src="./assets/icons/menu1.svg" class="img_menu">
          </button>
        </div>
        <div class="d-sm-block d-xs-block d-lg-none d-md-block d-xl-none"
          style="margin: auto; padding: 0%; height: 30px; margin-left: 0%;">
          <h4
            style="font-family: Jakob; font-weight: 600; white-space: nowrap; padding-bottom: 0% !important; margin-top: 2px; outline: none;"
            class="text-primary cursorpointer pb-0" routerLink="/home">We The Chefs</h4>
        </div>
      </div>
    </div>
    <a class="navbar-brand mx-0 m-auto d-none d-lg-block d-md-none d-xl-block">
      <!-- <img alt="" src="./assets/icons/Logo.svg" style="width: 50px; height: 50px; border-radius: 50%;"> -->
      <h3 style="font-family: Jakob; margin-bottom: 0%; font-weight: 600; white-space: nowrap; outline: none;"
        class="text-primary m-auto cursorpointer" routerLink="/home">We The Chefs</h3>
    </a>

    <!-- this is for normal navbar -->
    <div class="d-flex align-items-center w-100 justify-content-end" *ngIf="!isLoggedIn">
      <div style="margin-top: -2px;" class="align-items-center mr-2">
        <button class="btn navbar-toggler p-0 m-0" (click)="opencart()">
          <span *ngIf="cart_items_quantity!=0" class="fa-stack has-badge custom-mb-margin-0" [attr.data-count]="cart_items_quantity">
            <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
          </span>
          <span *ngIf="cart_items_quantity==0" class="custom-mb-margin-0">
            <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
          </span>
        </button>
        <!-- <div> -->
        <button class="btn p-0 m-0 navbar-toggler deep" (click)="openWhatsappp()" target="_blank">
         
          <span >
            <i class="fa fa-whatsapp fa-inverse" style="color: black;"></i>
          </span>
        </button>
      <!-- </div> -->
      </div>
      <div class="">
        <p aria-controls="navbarSupportedContent1"
          style="padding: 0% !important; margin: 0% !important; cursor: pointer; font-size: 17px;"
          class="navbar-toggler w-100 text-right text-primary" (click)="openauthmodal()">LOGIN</p>
      </div>

    </div>
    <!-- this is for normal navbar -->

    <!-- this is for profile navbar mobile -->
    <div *ngIf="isLoggedIn" style="width: fit-content; height: fit-content; display: flex;" class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none
     navbar-toggler w-100 ml-auto text-right text-primary
      d-inline-block float_right" ngbDropdown placement="bottom-right" display="dynamic" style="padding-right: 0%;">
      <button class="btn" (click)="opencart()" style="margin: 0%; padding: 2px 0% 0% 0%;">
        <span *ngIf="cart_items_quantity!=0" class="fa-stack has-badge" [attr.data-count]="cart_items_quantity">
          <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
        </span>
        <span *ngIf="cart_items_quantity==0">
          <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
        </span>
      </button>
      <button class="btn" id="dropdownMenuButton" ngbDropdownToggle style="margin: 0%; padding-right: 0%;"
        type="button">
        <img alt="" src="./assets/icons/Profile.svg" style="width: 20px; height: 20px;">
      </button>
      <!-- <div> -->
        <button class="btn"  target="_blank">
         
          <span class="fa-stack" (click)="openWhatsappp()">
            <i class="fa fa-whatsapp fa-inverse" style="color: black; margin-top:10px; font-size: 18px;"></i>
          </span>
        </button>
      <!-- </div> -->
      <div aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
        <a ngbDropdownItem (click)="openCustomerProfile('1')"> <img src="./assets/icons/Profile.svg"
            style="width: 12px; height: 12px;" alt=""> Profile</a>
        <a ngbDropdownItem (click)="openCustomerProfile('2')"> <img src="./assets/icons/Heart.svg"
            style="width: 12px; height: 12px;" alt=""> Favorites</a>
        <a ngbDropdownItem (click)="openCustomerProfile('3')"> <img src="./assets/icons/Heart.svg"
            style="width: 12px; height: 12px;" alt=""> Orders</a>
        <a ngbDropdownItem (click)="openCustomerProfile('4')"><img src="./assets/icons/Subtract.svg"
            style="width: 12px; height: 12px;" alt="">
          Settings</a>
        <a ngbDropdownItem (click)="logout()"><img src="./assets/icons/Log out.svg" style="width: 12px; height: 12px;"
            alt=""> Log out</a>
      </div>
    </div>
    <!-- this is for profile navbar -->
  </div>

  <div class="collapse navbar-collapse my-2 my-lg-0 w-100" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto mr-auto" data-target=".navbar-collapse.show" data-toggle="collapse">
      <!-- <li class="nav-item ml-2">
          <a  href="https://www.wethechefs.in/chefdetail/IPL-Indian-Pakwan-League" class="nav-link cursorpointer" routerLinkActive="active">IPL</a>
      </li> -->
      <li class="nav-item ml-2">
        <!-- <a [routerLink]="['/home']" href="" class="nav-link cursorpointer" routerLinkActive="active"> <span
            [ngClass]="{'home_link': home_route}" > Home
          </span> <span class="sr-only">(current)</span></a> -->
          <a  [routerLink]="['/zappetite']" class="nav-link cursorpointer" routerLinkActive="active">Z’Appetite Party Packs</a>
      </li>
      <!-- <li class="nav-item ml-2">
        <a [routerLink]="['/our-story']" href="" class="nav-link cursorpointer" routerLinkActive="active">Our Story</a>
      </li> -->
      <!--      <li class="nav-item ml-2" routerLink="/our-community">-->
      <!--        <a class="nav-link cursorpointer" routerLinkActive="active">Our Community</a>-->
      <!--      </li>-->
      <li class="nav-item ml-2">
        <a [routerLink]="['/search-cuisine/book-your-order']" class="nav-link cursorpointer" routerLinkActive="active">Pre - Order
        </a>
      </li>
      <li class="nav-item ml-2">
        <a href="https://wethechefs.in/events" class="nav-link cursorpointer" routerLinkActive="active">Events</a>
      </li>
      <!-- <li class="nav-item ml-2">
        <a  href="https://www.wethechefs.in/chefdetail/Book-by-11am-Eat-by-7pm" class="nav-link cursorpointer" routerLinkActive="active">Z’appetite</a>
      </li> -->
      <!-- <div class="dropdown">
        <li class="nav-item ml-2" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <a class="nav-link cursorpointer">Curated Meals
          </a>
        </li>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a *ngFor="let event of events" href="event-detail?event_id={{event.id}}" class="dropdown-item"
            (click)="openEvent(event.id)" style="cursor: pointer">{{event.name}}</a>
        </div>
      </div> -->
      
      <li class="nav-item ml-2">
        <a [routerLink]="['/our-story']" href="" class="nav-link cursorpointer" routerLinkActive="active">About Us</a>
      </li>
      <li class="nav-item ml-2">
        <a [routerLink]="['/become-a-chef']" class="nav-link cursorpointer" routerLinkActive="active">Become a Chef</a>
      </li>
      <li class="nav-item ml-2">
        <a  href="https://wethechefs.in/blog" class="nav-link cursorpointer" routerLinkActive="active">Blog</a>
      </li>
      <!-- this is profile navbar -->
      <!-- this is for profile navbar -->
    </ul>

    <div *ngIf="isLoggedIn" class="desktop_view_icons" ngbDropdown class="d-flex float_right" placement="bottom-right"
      display="dynamic">
      <button class="btn d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block" (click)="opencart()">
        <span *ngIf="cart_items_quantity!=0" class="fa-stack has-badge" [attr.data-count]="cart_items">
          <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
        </span>
        <span *ngIf="cart_items_quantity==0">
          <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
        </span>
      </button>
      <button *ngIf="isLoggedIn" id="dropdownMenuButton" ngbDropdownToggle
        class="btn profile_btn d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block">
        <img src="./assets/icons/Profile.svg" style="width: 15px; height: 15px;" alt="">
      </button>
      <div>
        <button class="btn whatsapp-desktop" (click)="openWhatsappp()" target="_blank">
         
          <span class="fa-stack ">
            <i class="fa fa-whatsapp fa-inverse" style="color: black; margin-top:10px; font-size: 18px;"></i>
          </span>
        </button>
      </div>
      <div aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
        <!-- <a ngbDropdownItem routerLink="/customer-profile"> <img src="./assets/icons/Profile.svg" style="width: 12px; height: 12px;" alt=""> Customer Profile</a> -->
        <a ngbDropdownItem (click)="openCustomerProfile('1')"> <img src="./assets/icons/Profile.svg"
            style="width: 12px; height: 12px;" alt=""> Profile</a>
        <a ngbDropdownItem (click)="openCustomerProfile('2')"> <img src="./assets/icons/Heart.svg"
            style="width: 12px; height: 12px;" alt=""> Favorites</a>
        <a ngbDropdownItem (click)="openCustomerProfile('3')"> <img src="./assets/icons/Heart.svg"
            style="width: 12px; height: 12px;" alt=""> Orders</a>
        <a ngbDropdownItem (click)="openCustomerProfile('4')"><img src="./assets/icons/Subtract.svg"
            style="width: 12px; height: 12px;" alt="">
          Settings</a>
        <a ngbDropdownItem (click)="logout()"><img src="./assets/icons/Log out.svg" style="width: 12px; height: 12px;"
            alt=""> Log out</a>
        <!-- <a ngbDropdownItem href="javascript:void(0)">Option 3</a> -->
      </div>
    </div>


    <div *ngIf="!isLoggedIn" class="my-2 my-lg-0 d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block ml-auto">
      <div class="d-flex">
        <!-- <div>
          <button class="btn" (click)="opencart()">
            <span *ngIf="cart_items_quantity!=0"  [attr.data-count]="cart_items_quantity" class="fs-22">
              <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
            </span>
            <span *ngIf="cart_items_quantity==0" class="fs-22">
              <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
            </span>
          </button>
        </div> -->
        <div >
          <button class="btn" (click)="opencart()">
            <span *ngIf="cart_items_quantity!=0" class="fa-stack has-badge custom-mb-margin-0" [attr.data-count]="cart_items_quantity">
              <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
            </span>
            <span *ngIf="cart_items_quantity==0" class="custom-mb-margin-0">
              <i class="fa fa-shopping-cart fa-inverse" style="color: black;"></i>
            </span>
          </button>
          <!-- <div> -->
          <button class="btn p-0 m-0 navbar-toggler deep" (click)="openWhatsappp()" target="_blank">
           
            <span >
              <i class="fa fa-whatsapp fa-inverse" style="color: black;"></i>
            </span>
          </button>
        <!-- </div> -->
        </div>
        <div class="whatsapp-div">
            <span class="fa-s tack whatsa pp-class" (click)="openWhatsappp()">
              <i class="fa fa-whatsapp fa-inverse fs-22" style="color: black; font-size: 18px;"></i>
            </span>
        </div>
        <div class="login-div">
          <button [ngClass]="{'old': !toggle, 'new': toggle}" class="btn btn-primary rounded font-weight-bolder br-8-px btn-login signup-btn" style="font-size: 13px; margin: 5px;"
            (click)="openauthmodal();change()" type="submit">
            Login
          </button>
          <button [ngClass]="{'old': toggle, 'new': !toggle}" class="btn btn-primary rounded font-weight-bolder br-8-px signup-btn" style="font-size: 13px; margin: 5px;"
            (click)="opensignin();change()" type="submit">
          Signup
          </button>
        </div>
      </div>


    </div>

    <!-- this is for desktop view and tab view and for normal view -->

    <!-- this is for desktop view and tab view and normal view -->
  </div>

  <!-- this is for mobile view and profile view -->
  <!-- <div *ngIf="!isLoggedIn" class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
    <div class="collapse navbar-collapse my-2 my-lg-0 w-100" id="navbarSupportedContent1">
      <form class="form-inline">
        <button class="btn btn-primary my-2 my-sm-0 ml-5 rounded font-weight-bolder" style="font-size: 13px;"
          type="submit">
          Sign Up
        </button>
        <button class="btn my-2 my-sm-0 ml-2 rounded font-weight-bolder" style="font-size: 13px;"
          routerLink="/signupstudent" type="submit">Login in
        </button>
      </form>
    </div>
  </div> -->
  <!-- this is for mobile view and profile view -->
</nav>


<div class="modal-primary">
  <!-- Modal studentsummary -->
  <ng-template #signupmodal let-modal>
    <div class="modal-body profiletutor p-0 text-dark" tabindex="0">
      <div class="d-flex justify-content-end m-1">
        <h4 class="font-weight-bolder mr-auto"><img alt="" src="./assets/images/newimages/Group 203.svg"
            style="width: 20px; height: 20px; margin-top: -5px;">
          &nbsp; All Hobbies</h4>
        <h4><i (click)="modal.dismiss('Cross click')" class="fa fa-times ml-auto"></i></h4>
      </div>
    </div>
  </ng-template>
</div>
