import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, ElementRef, HostListener, OnInit, ViewChild,AfterViewInit} from '@angular/core';
import {Router,NavigationEnd} from '@angular/router';
import {NgbCarouselConfig, NgbModal,NgbCarousel, NgbCarouselModule, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
// import { } from '@ng-bootstrap/ng-bootstrap';
import {interval, Subscription} from 'rxjs';
import {APIService} from '../../services/api.service';
import {UtilService} from '../../services/util.service';
import {ToastrService} from 'ngx-toastr';
import {SearchService} from '../../services/search.service';
import {Events, EventService,EmitEvent} from '../../services/event.service';
import {Meta, Title} from '@angular/platform-browser';
import {ConfirmationModalComponent} from '../../modals/confirmation-modal/confirmation-modal.component';
import {VideoPopupComponent} from '../../modals/video-popup/video-popup.component'
import { json } from 'express';
import { SchemaInjectorService } from '../../services/schema-injector.service';
interface Chef {
    id: string;
    name: string;
    itemName: string;
    about: string;
    image1: string;
    image2: string;
    quote: string;
    cuisines: string;
    tag1: string;
    tag2: string;
    min_val:any;
    selectDishes:any
    Rating:any
    profile_pic:any;
}
declare let $: any;
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [
        trigger('flipState', [
            state('active', style({
                transform: 'rotateY(180deg)'
            })),
            state('inactive', style({
                transform: 'rotateY(0)'
            })),
            transition('active => inactive', animate('400ms ease-out')),
            transition('inactive => active', animate('400ms ease-in'))
        ])
    ],
})
export class HomeComponent implements OnInit {
    @ViewChild('searchInputSection') searchInputSection: ElementRef;
    showNavigationIndicators = false;
    mySubscription: Subscription | undefined;
    searchresult: boolean = false;
    // showNavigationArrows = false;
    disableInput= false
    page = 1;
    pageSize = 1;
    collectionSize;
    homeChefHeading = '';

    bannerClass= ''
    bannerClass2= ''
    VideoClass= ''
    cms: any;
    selectedDishes:any;
    disabledButn:any;
    disabledRButn:any;
    subscription;
    commission = 0;
    quantity = 0;
    totalPrice = 0;
    min_order_val = 0;
    cartItems = [];
    ItemsArray=[];
    Dtoggle: boolean = true;
    rightDisabled: boolean = false;
    leftDisabled: boolean = true;
    righttrendingDisabled:boolean = false;
    lefttrendingDisabled:boolean = true;
    rightOcctrendingDisabled:boolean = false;
    leftOcctrendingDisabled:boolean = true;
    leftServedDisabled:boolean = true;
    rightServedDisabled:boolean = false;
    leftCuisineDisabled:boolean = true;
    rightCuisineDisabled:boolean = false;
    leftPressDisabled:boolean = true;
    rightPressDisabled:boolean = false;
    selectedCity = '';
    mappedCity = '';
    offeringsList = [
        {
          title: 'Order Authentic Food',
          image: './assets/home_section/authenticfood.png',
          internal: true
        },
        {
          title: 'Private Events',
          image: './assets/home_section/private_party.png',
          link: 'https://events.wethechefs.in'
        },
        {
          title: 'Chef’s Table',
          image: './assets/home_section/chef_table.jpg',
          link: 'https://example.com/private-events'
        },
        {
          title: 'Quick Food Delivery',
          image: './assets/home_section/food_delivery.jpg',
          link: 'https://example.com/private-events'
        },
        {
          title: 'Gifting and Food Pyramid',
          image: './assets/home_section/authenticfood.png',
          link: 'https://example.com/private-events'
        },
        {
          title: 'Corporate Events',
          image: './assets/home_section/coprate.jpg',
          link: 'https://example.com/private-events'
        },
        {
            title: 'Corporate Events 2',
            image: './assets/home_section/coprate.jpg',
            link: 'https://example.com/private-events'
          },
          {
            title: 'Corporate Events 3',
            image: './assets/home_section/coprate.jpg',
            link: 'https://example.com/private-events'
          },
          {
            title: 'Corporate Events 4',
            image: './assets/home_section/coprate.jpg',
            link: 'https://example.com/private-events'
          }
      ];
    constructor(
        private router: Router,
        private modalService: NgbModal,
        public apiService: APIService,
        config: NgbCarouselConfig,
        public utilService: UtilService,
        private toaster: ToastrService,
        private searchService: SearchService,
        private eventService: EventService,
        private title: Title,
        private meta: Meta,
        private elmRef: ElementRef,
        private schemaInjector: SchemaInjectorService,
    ) {
        config.showNavigationArrows = true;
        // this.mySubscription = interval(2000).subscribe((x => {
            this.toggle();
        // }));
        const currentRoute = window.location.pathname;
        console.log(window.location,"deepak");
        
      
        if (!currentRoute.includes('/chefdetail')) {
        // Remove items from local storage
      
      // Remove items from local storage
      localStorage.removeItem('itemId');
      // You can remove multiple items if needed by calling removeItem for each key
      }
        this.router.events.subscribe((event) => {
            if (this.router.url === '/' || this.router.url === 'home') {
                // if (event instanceof NavigationEnd) {
              localStorage.removeItem("tag_id")
            //   localStorage.removeItem("itemId")
              console.log("item_id")
            }
          });
        //   if(this.selectedCity==''){
        //     window.alert('please select city')
        //   }
        //   else{
            this.selectedCity = 'Delhi';
            if(localStorage.getItem('city') != '' && localStorage.getItem('city') != 'null' && localStorage.getItem('city') != null && localStorage.getItem('city') != undefined && localStorage.getItem('city') != 'undefined'){
                this.selectedCity=localStorage.getItem('city');
            }
          
            //this.selectedCity=localStorage.getItem('city')

        //   }
    }
    
	
    getS4Image() {
        let str = this.utilService.getImageCDNURLVariant(this.cms.S4.image, 500) + ' 500w, ' +
            this.utilService.getImageCDNURLVariant(this.cms.S4.image, 800) + ' 800w, ' +
            this.utilService.getImageCDNURLVariant(this.cms.S4.image, 1200) + ' 1200w';
        return str;
    }
    getS16Image() {
        this.bannerClass = "background-image: url(" + this.utilService.getImageCDNURL(this.cms.S16.image1) + ")"
        
        this.bannerClass2 = "background-image: url(" + this.utilService.getImageCDNURL(this.cms.S16.image2) + ")"
        this.VideoClass = "background-image: url(" + this.utilService.getImageCDNURL(this.cms.VideoS1.bacImage) + ")"
        return console.log(this.bannerClass,"banner");;
        
        
    }

    s5Video = this.apiService.BASE_IMAGE_URL + 'uploads/bac/VideoS1_VIDEO.mp4'
    myScrollContainer!: HTMLElement;
    showInput: boolean = false;
    bannerDefaultpng = '/assets/images/bannerDefaultpng.png';
    homeCusines_default = '/assets/images/homeCusines_default.png';
    home_default1 = '/assets/images/home_default1.png';
    home_meet = '/assets/images/home_meet.png';

    ngOnInit(): void {
        document.body.classList.add('stop-scrolling');
        this.myScrollContainer = this.elmRef.nativeElement.querySelector('#my-scroll-container');
        // this.instafeeds();
        // this.title.setTitle('One stop destination for home cooked food and cuisines in India');
        this.title.setTitle('Home Cooked Food Delivery Online in Gurgaon, Delhi, Bangalore');
        this.meta.addTag({
            name: 'description',
            content: 'Explore the diverse flavors of homemade gourmet meals from experienced home chefs at We The Chefs. Order authentic home cooked food online in Gurgaon, Delhi, and Bangalore, and indulge in a culinary journey like no other.'
        });
        this.meta.addTag({name: 'keywords', content: 'Home cooked,online, food'});
        
        const canonicalURL = `https://www.wethechefs.in`; 
      
        this.meta.addTag({ rel: 'canonical', href: canonicalURL });
        this.meta.addTag({ rel: 'alternate', hreflang: 'en', href: canonicalURL });

        this.getCMS();
        setTimeout(() => {
                this.showInput = true;
            }
            , 500);
        this.eventService.on(Events.FAQ, (data => {
            //console.log("faqevents");
            setTimeout(() => {
                document.getElementById('faq').scrollIntoView();
            }, 200);
        }));

        this.eventService.on(Events.PLANYOURPLATE, (data => {
            //console.log("planyourplate");
            setTimeout(() => {
                if (this.allCuisines != null && this.allCuisines != undefined && this.allCuisines.length > 0) {
                    document.getElementById('planyourplate').scrollIntoView();
                }
            }, 200);
        }));
        // if (this.cms.S5.homeChefs != null && this.cms.S5.homeChefs != undefined && this.cms.S5.homeChefs != '') {
            // this.getChefDetail();
            // this.homeChefs[chefIndex].selectDishes[index].cart_item = item1;
            // this.homeChefs[chefIndex].selectDishes[index].in_cart = true;
            // this.cartselectDishes = this.homeChefs[chefIndex].selectDishes;
            // this.getHomeChefs(this.cms.S5.chefs);
            // this.getHomeChefs({
            //     chefs: this.cms.S5.chefs
            // });
            this.subscription = this.eventService.on(Events.CART_ITEM_UPDATE, (data => {
                this.getUserCartItems(this.cartselectDishes);

            }));
        // }
         
        this.schemaInjector.injectSchema(`{
            "@context": "https://schema.org",
            "@type": "Restaurant",
            "name": "We The Chefs",
            "image": "https://www.wethechefs.in/events/wp-content/uploads/2023/10/Copy-of-_DSC1403-1536x1024.jpg",
            "@id": "",
            "url": "https://www.wethechefs.in/",
            "telephone": "+91 8800988490",
            "menu": "https://www.wethechefs.in/search-cuisine/book-your-order",
            "servesCuisine": "Gourmet Food",
            "acceptsReservations": "true",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Sector 51",
              "addressLocality": "Gurgaon",
              "postalCode": "122003",
              "addressCountry": "IN"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 28.4320397,
              "longitude": 77.0686159
            } ,
            "sameAs": "https://www.wethechefs.in/events/" 
          }`);
          this.schemaInjector.injectSchema(`{
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "Home Food Delivery Near Me",
            "image": "https://www.wethechefs.in/events/wp-content/uploads/2023/08/Copy-of-Copy-of-IMG20230514211650-800x600.jpg",
            "description": "Enjoy Bespoke food menu, curated to your taste and delivered to your doorstep, ensure that each bite is as delectable as it is memorable. Choose We The Chefs for a seamless luxury dining experience with gourmet homemade food that make your next event the talk of the town.
          
          If you are planning dinner or an intimate family gathering or small house party our expert home chefs are dedicated to providing a premium dining experience at best.
          
          Order gourmet food near me in Gurgaon, Delhi, Noida, and Bangalore. Experience authentic taste of homemade food & cuisines from various states like bengali, Rajasthani, Kerala, Burmese, and Mughlai cuisines, as well as a international continental from Thai, Mexican, and Italian.",
            "brand": {
              "@type": "Brand",
              "name": "We The Chefs"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.wethechefs.in/search-cuisine/book-your-order",
              "priceCurrency": "INR",
              "price": "250",
              "priceValidUntil": "2024-12-31",
              "availability": "https://schema.org/PreOrder"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.9",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "1840"
          
            }
          }`);
          const savedCity = localStorage.getItem('city');
          if (savedCity) {
            this.selectedCity = savedCity;
            this.mapCityToAPI(savedCity); // Map the saved city for API use
          } else {
            this.selectedCity = 'Delhi'; // Default to Delhi
            this.mapCityToAPI(this.selectedCity);
          } 
        
    }

    selectedState(){
        // console.log(this.selectedCity,"city");
        // localStorage.setItem("city",this.selectedCity)
        console.log(this.selectedCity, "selectedCity");
        localStorage.setItem("city", this.selectedCity);
        this.mapCityToAPI(this.selectedCity);
        if(this.searchedText != '' ){
            this.searchItems();
        }
    }
    mapCityToAPI(city: string): void {
        if (city === 'Bangalore') {
          this.mappedCity = 'Bengalore';
        } else if (city === 'Delhi' || city === 'Gurgaon' || city === 'Noida') {
          this.mappedCity = 'Delhi';
        } else {
          this.mappedCity = city;
        }
    
        console.log(this.mappedCity, "mappedCity for API");
      }
      handleOfferingClick(offering: any) {
        if (offering.internal) {
          // Scroll to "Order Authentic Food" section
          this.scrollToSearchSection();
        } else if (offering.link) {
          // Redirect to external link
          window.location.href = offering.link;
        }
      }
    
      // Scroll function
      scrollToSearchSection() {
        // this.searchInputSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        const targetElement = document.getElementById('orderAuthenticFood');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
      }
    Btnchange() {
        this.Dtoggle = !this.Dtoggle;
      }
    getCPKM() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'cart/getCPKM').then((result) => {
            if (result.status) {
                this.commission = result.result.commission;
            }
        }, (error) => {
            //console.log("error");
        });
    }
    ngOnDestroy() {
        // this.eventService.emit(new EmitEvent(Events.NOTIFICATION_RECEIVED, ""));
        // this.eventService.on(Events.THEME_CHANGED, (data => {
        //   this.setStatusBarThemeColor()
        // }));
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
    }
    cartselectDishes = [];
   
    addToCart(item,chefIndex, index, item_id, price) {
    
        // console.log("item:"+ JSON.stringify(item));
        // console.log('homeChefs updated:-' + this.homeChefs);
        if (!this.utilService.isUserLoggedIn()) {
            let item1 = {
                id: item.id,
                name: item.name,
                price: item.base_price,
                chef_id: item.chef_id,
                 //quantity: 1,
                type: 'item'
              }
            this.homeChefs[chefIndex].selectDishes[index].cart_item = item1;
            this.homeChefs[chefIndex].selectDishes[index].in_cart = true;
            this.cartselectDishes = this.homeChefs[chefIndex].selectDishes;
            // console.log('items:-' + JSON.stringify(item, null, 4));
            if (this.cartItems.length > 0) {
            for (let i = 0; i < this.cartselectDishes.length; i++) {
                let itemMatches = false;
                if(this.cartItems.filter(x => x.item_id == this.cartselectDishes[i].id).length > 0)
                {
                    itemMatches = true;
                    break;
                }
                if (!itemMatches) {
                                    this.homeChefs.forEach(elementChef => { 
                                        elementChef.selectDishes.forEach(element => {
                                            element.cart_item = null,
                                            element.in_cart = false
                                        });
                                    });
                                    this.homeChefs[chefIndex].selectDishes[index].cart_item = item1;
                                    this.homeChefs[chefIndex].selectDishes[index].in_cart = true;
                  
                }
                // this.homeChefs[chefIndex].selectDishes[index].in_cart = true
            }
            // this.homeChefs[chefIndex].selectDishes[index].in_cart = true
            }
            
            this.utilService.addToLocalCart(this.modalService, item_id, item.name, item.chef_id, price, 'item');
            this.getUserCartItems(this.cartselectDishes);
            this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));

        } 
        else {   
            let item1 = {
                id: item.id,
                name: item.name,
                price: item.base_price,
                chef_id: item.chef_id,
                 //quantity: 1,
                type: 'item'
              }
            this.homeChefs[chefIndex].selectDishes[index].cart_item = item1;
            this.homeChefs[chefIndex].selectDishes[index].in_cart = true;
            this.cartselectDishes = this.homeChefs[chefIndex].selectDishes;
            // console.log(this.cartItems.length,"cart lenght");
            this.apiService.getAPI(this.apiService.BASE_URL + 'cart/getCartItemDetailByUserId/' + this.utilService.getUserID()).then((resultuser) => {
                if (resultuser.status) {
                    this.cartItems = resultuser.result;
                    
            if (this.cartItems.length > 0) {

                let checkEvent = this.checkEventInCart(this.cartItems);
                let itemMatches = false;
                for (let i = 0; i < this.cartselectDishes.length; i++) {
                    if(this.cartItems.filter(x => x.item_id == this.cartselectDishes[i].id).length > 0)
                    {
                        itemMatches = true;
                        break;
                    }
                }
                if (!itemMatches) {
                    //show delete items from cart
                    const modalRef = this.modalService.open(ConfirmationModalComponent, {size: ''});
                    modalRef.closed.subscribe((result) => {
                        if (result.confirm) {
                            this.apiService.deleteAPI(this.apiService.BASE_URL + 'cart/deleteCartByUserId/' + this.utilService.getUserID()).then((result) => {
                                if (result.status) {
                                    
                                    // this.ngOnInit();
                                    this.addCartAPI(item,item_id, price, index);
                                    this.homeChefs.forEach(elementChef => { 
                                        elementChef.selectDishes.forEach(element => {
                                            element.cart_item = null,
                                            element.in_cart = false
                                        });
                                    });
                                    this.homeChefs[chefIndex].selectDishes[index].cart_item = item1;
                                    this.homeChefs[chefIndex].selectDishes[index].in_cart = true;

                                }
                            }, (error) => {
                                console.log('error');
                            });
                        }
                    });
                    modalRef.componentInstance.title = 'Replace cart item?';
                    modalRef.componentInstance.message = 'Your cart contains dishes from another Chef. Do you want to discard the selection and add new dishes?';
                    return this.homeChefs[chefIndex].selectDishes[index].in_cart = false;
                }
                
            }    
            
            } 
            this.addCartAPI(item,item_id, price, index); 
            }, (error) => {
                console.log('error');
            });
            
        }
    }


    remove(item: any) {
        if (this.utilService.isUserLoggedIn()) {
            this.apiService.postAPI(this.apiService.BASE_URL + 'cart/addToCart', {
                user_id: this.utilService.getUserID(),
                item_id: item.id,
                quantity: item.cart_item.quantity - 1,
                price: (item.base_price * (item.cart_item.quantity - 1))
            }).then((result) => {
                this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
                if (result.status) {
                    this.getUserCartItems(this.cartselectDishes);
                }
            }, (error) => {
                console.log('error');
            });
        } else {
            this.utilService.deleteFromCart(item);
            this.getUserCartItems(this.cartselectDishes);
            this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
        }
    }
    addItem(item: any, chefIndex, index) {
        this.cartselectDishes = this.homeChefs[chefIndex].selectDishes;
        if (this.utilService.isUserLoggedIn()) {
            // console.log('item:-' + JSON.stringify(item, null, 4));
            this.apiService.postAPI(this.apiService.BASE_URL + 'cart/addToCart', {
                user_id: this.utilService.getUserID(),
                item_id: item.id,
                quantity: item.cart_item.quantity + 1,
                price: (item.base_price * (item.cart_item.quantity + 1))
            }).then((result) => {
                this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
                if (result.status) {
                    this.getUserCartItems(this.cartselectDishes);
                }
            }, (error) => {
                console.log('error');
            });
        } else {
            item['type'] = 'item';
            // this.utilService.addToLocalCart(item);
            this.utilService.addToLocalCart(this.modalService, item.id, item.name, item.chef_id, item.price, 'item');
            this.getUserCartItems(this.cartselectDishes);
            this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
        }
    }
   
    addCartAPI(item,item_id, price, index) {
        // console.log(item,item_id, price, index,"268 aa cart api");
    //     // console.log( this.utilService.getUserID(),
    //     item_id,
    //     1,
    //    price,"274");
        
        this.apiService.postAPI(this.apiService.BASE_URL + 'cart/addToCart', {
            user_id: this.utilService.getUserID(),
            item_id: item_id,
            quantity: 1,
            price: price,
            // commission:this.commission,
            // chef_price:this.utilService.getChefOrderPrice(price,this.commission)
        }).then((result) => {
            if (result.status) {
                item['in_cart'] = true;
                this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
                this.getUserCartItems(this.cartselectDishes);
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error');
        });
    }

    removeFromCart(item,index, item_id) {
        // console.log(item,"remove 313");
        
        if (!this.utilService.isUserLoggedIn()) {
            // alert("please login to continue");
            // return;

            this.utilService.removeFromCart(item);
            item['in_cart'] = false;

        } else {
            this.apiService.postAPI(this.apiService.BASE_URL + 'cart/deleteCart', {
                user_id: this.utilService.getUserID(),
                item_id: item_id
            }).then((result) => {
                if (result.status) {
                    item['in_cart'] = false;
                } else {
                    alert(result.message);
                }
            }, (error) => {
                console.log('error');
            });
        }
    }

    private getUserCartItems(item) {
        this.cartItems = [];
        this.quantity = 0;
        this.totalPrice = 0;
        // console.log(this.utilService.getUserID(),"Deepak-chef");
        
        if (this.utilService.isUserLoggedIn()) {
            // console.log("cart items");
            this.apiService.getAPI(this.apiService.BASE_URL + 'cart/getCartItemDetailByUserId/' + this.utilService.getUserID()).then((result) => {
                if (result.status) {
                    this.cartItems = result.result;
                    this.quantity = 0;
                    let totalPrice = 0;
                    for (let i = 0; i < this.cartItems.length; i++) {
                        totalPrice += Number(this.cartItems[i].price);
                        this.quantity += this.cartItems[i].quantity;
                    }
                    this.totalPrice = totalPrice;
                    this.getChefDetail(this.cartItems[0].item_id);
                    for (let i = 0; i < item.length; i++) {
                        item[i].in_cart = false;
                    }
                    for (let i = 0; i < item.length; i++) {
                        for (let j = 0; j < result.result.length; j++) {
                            if (item[i].id == result.result[j].item_id) {
                                item[i].in_cart = true;
                                item[i]['cart_item'] = result.result[j];
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < item.length; i++) {
                        item[i].in_cart = false;
                    }
                }
            }, (error) => {
                console.log('error');
            });
        } else {
            this.cartItems = this.utilService.getLocalCart();
            // console.log('get local cart:-' + JSON.stringify(this.utilService.getLocalCart(), null, 4));
            // console.log('cart item update:-' + this.cartItems.length);
            if (this.cartItems.length != 0) {
                // console.log(item,"394");
                for (let i = 0; i < this.cartItems.length; i++) {
                    this.totalPrice += Number(this.cartItems[i].price) * this.cartItems[i].quantity;
                    // this.cart_quantity += this.cartItems[i].quantity;
                  }
                if (this.checkEventInCart(this.cartItems)) {
                    this.setAllItemsOutOfCart(item);
                } else {
                    this.setAllItemsOutOfCart(item);
                    // console.log(item,"item.length");
                    
                    for (let i = 0; i < item.length; i++) {
                        for (let j = 0; j < this.cartItems.length; j++) {
                            // console.log(item[i].id, this.cartItems[j].id,"check id");
                            
                            if (item[i].id == this.cartItems[j].id) {
                                item[i].in_cart = true;
                                item[i]['cart_item'] = this.cartItems[j];
                            }
                        }
                    }

                    // console.log('items:-' + JSON.stringify(item, null, 4));
                }
                this.quantity = 0;
                this.getChefDetail(this.cartItems[0].id);
                for (let i = 0; i < this.cartItems.length; i++) {
                    this.quantity += this.cartItems[i].quantity;
                }
            } else {
                this.setAllItemsOutOfCart(item);
            }
        }
    }

    checkEventInCart(cart) {
        for (let i = 0; i < cart.length; i++) {
            if (cart[i].type == 'event') {
                return true;
            }
        }
        return false;
    }

    setAllItemsOutOfCart(item) {
        // console.log(item,"dee");
        
        for (let i = 0; i < item.length; i++) {
            item[i].in_cart = false;
        }
    }
    get remainingAmount(): number {
        return this.min_order_val - this.totalPrice; 
      }
      getChefDetail(item_id: any) {
        this.apiService.postAPI(this.apiService.BASE_URL + "items/getChefDetailByItemId", {
          item_id: item_id
        }).then((result) => {
          if (result.status) {
            this.min_order_val = result.result.min_order_val;
          }
        }, (error) => {
          //console.log("something went wrong");
        })
      }
    checkOut() {

        // console.log('totalPrice:-' + this.totalPrice);
        // console.log('min_order_val:-' + this.min_order_val);
console.log(this.min_order_val,"min")
        if (this.utilService.isUserLoggedIn()) {
            if (this.cartItems.length > 0) {
                if (this.totalPrice >= this.min_order_val) {
                    // this.utilService.SharingAlertData.next(this.chef_id);
                    this.router.navigate(['/checkout']);
                } else {
                    this.toaster.error('Minimum order amount must be greater than ' + this.min_order_val);
                }
            }
        } else {
            this.eventService.emit(new EmitEvent(Events.OPEN_NAVBAR_LOGIN, ''));
        }
    }
    ngAfterViewInit() {
       
      }
    getHomeChefs(postData) {
        // console.log(postData,"postData");
        
        this.homeChefs = [];
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/getHomeChefsByIds', postData).then((result) => {
            if (result.status) {
                for (let i = 0; i < result.result.length; i++) {
                    let itemName = '';
                    if (result.result[i].item != null) {
                        itemName = result.result[i].item.name;
                    }
                    let name = '';
                    if (result.result[i].profile != null) {
                        name = result.result[i].profile.name;
                    }
              
                    // console.log(result.result[i].selectedDishes,"dishes");
                    result.result[i].selectedDishes.forEach(element => {
                        element['in_cart'] = false;
                        element['cart_item'] = null;
                    });
                    
                    this.homeChefs.push({
                        id: result.result[i].chef_id,
                        name: name,
                        itemName: itemName,
                        about: result.result[i].description,
                        image1: result.result[i].image1,
                        image2: result.result[i].image2,
                        min_val:result.result[i].min_val,
                        quote: result.result[i].quote,
                        cuisines: result.result[i].cuisines,
                        tag1: result.result[i].tag1,
                        tag2: result.result[i].tag2,
                        selectDishes:result.result[i].selectedDishes,
                        Rating:result.result[i].Rating,
                        profile_pic:result.result[i]?.profile?.profile_pic
                        
                    });
                    //  this.cms.S5.chefs.filter(x => x.result.result[i].chef_id == this.cms.S5.chefs)[0].selectedDishes = this?.selectedDishes;
                    // console.log(JSON.parse(result.result[i].selectedDishes[0].images)[0],"images");
                this.getUserCartItems(this.cartselectDishes);
                    
                }
                
                if(!this.utilService.isUserLoggedIn()){
                    this.cartItems = this.utilService.getLocalCart();
                    if(this.cartItems != null && this.cartItems.length > 0)
                    {
                        for (let i = 0; i < this.homeChefs.length; i++) {
                            for (let j = 0; j < this.homeChefs[i].selectDishes.length; j++) {
                                for (let k = 0; k < this.cartItems.length; k++) {
                                    if (this.homeChefs[i].selectDishes[j].id == this.cartItems[k].id) {
                                        this.homeChefs[i].selectDishes[j].in_cart = true;
                                        this.homeChefs[i].selectDishes[j]['cart_item'] = this.cartItems[k];
                                    }
                                }
                            }
                        }
                    }
                   }
                   else {
                    this.apiService.getAPI(this.apiService.BASE_URL + 'cart/getCartItemDetailByUserId/' + this.utilService.getUserID()).then((resultuser) => {
                        if (resultuser.status) {
                            this.cartItems = resultuser.result;

                            if(this.cartItems != null && this.cartItems.length > 0)
                            {
                                for (let i = 0; i < this.homeChefs.length; i++) {
                                    for (let j = 0; j < this.homeChefs[i].selectDishes.length; j++) {
                                        for (let k = 0; k < this.cartItems.length; k++) {
                                            if (this.homeChefs[i].selectDishes[j].id == this.cartItems[k].item_id) {
                                                this.homeChefs[i].selectDishes[j].in_cart = true;
                                                this.homeChefs[i].selectDishes[j]['cart_item'] = this.cartItems[k];
                                            }
                                        }
                                    }
                                }
                            }
                        
                        } 
                    }, (error) => {
                        console.log('error');
                    });
                   }
                

                // console.log(this.homeChefs,"homeChefs");
                this.collectionSize = this.homeChefs.length;
            }
        });
    }
    
    // HOME_TOP = "HOME_TOP";
    // HOME_WTC = "HOME_WTC";
    // HOME_HIW = "HOME_HIW";
    // HOME_S7_BANNER1 = "HOME_S7_BANNER1";
    // HOME_S7_BANNER2 = "HOME_S7_BANNER2";
    // HOME_S7_BANNER3 = "HOME_S7_BANNER3";
    // HOME_S8 = "HOME_S8";
    // HOME_S9 = "HOME_S9";
    // HOME_S10 = "HOME_S10";
    // HOME_S11 = "HOME_S11";
    // HOME_CHEFS = "HOME_CHEFS";
    //
    // homeBanners = [];
    allCuisines = [];
    popularCuisines = [];
    collectionTag = [];
    chefTag = [];
    // S2;
    // S3;
    // homeCMS;
    // home_cuisine_ids = [];
    // // S7_Banner_1 = [];
    // // S7_Banner_2 = [];
    // // S7_Banner_3 = [];
    // //
    // default_image = 'assets/images/default_banner.png';
    // //
    // // wtc_default = this.default_image;
    // // wtc_title = '';
    // // wtc_description = '';
    // //
    // s8_image = this.default_image;
    // //
    // s10_1_image = this.default_image;
    // s10_2_image = this.default_image;
    // s10_3_image = this.default_image;
    // s10_4_image = this.default_image;
    // //
    // // s11_1_image = this.default_image;
    // // s11_2_image = this.default_image;
    // // s11_3_image = this.default_image;
    // //
    // s9_image = this.default_image;
    // s9_title = '';
    // s9_heading = '';
    // s9_description = '';
    //
    // HIW_1_title = '';
    // HIW_2_title = '';
    // HIW_3_title = '';
    //
    // HIW_1_description = '';
    // HIW_2_description = '';
    // HIW_3_description = '';
   

    mobile_banners = [
        {text: 'Family heirlooms for you to feast on', img: './assets/images/banner_mobile_3.jpg'},
        {text: 'Curated and plated food experiences', img: './assets/images/banner_mobile_2.jpg',},
        {text: 'Authentic. Fresh. Delicious.', img: './assets/images/banner_mobile_1.jpg',},
    ];


    getHome() {
        // this.apiService.postAPI(this.apiService.BASE_URL + 'common/getAllCMS', {
        //   names: [this.HOME_TOP, this.HOME_WTC, this.HOME_HIW, this.HOME_S7_BANNER1, this.HOME_S7_BANNER2, this.HOME_S7_BANNER3,
        //     this.HOME_S9, this.HOME_S8, this.HOME_S10, this.HOME_S11, this.HOME_CHEFS, 'HOME_CMS']
        // }).then((result) => {
        //   if (result.status) {
        //     for (let i = 0; i < result.result.length; i++) {
        //       if (result.result[i].content != null) {
        //         switch (result.result[i].name) {
        //           case this.HOME_TOP:
        //             this.homeBanners = JSON.parse(result.result[i].content);
        //             break;
        //           case this.HOME_S7_BANNER1:
        //             this.firstimages = JSON.parse(result.result[i].content);
        //             break;
        //           case this.HOME_S7_BANNER2:
        //             this.secondimages = JSON.parse(result.result[i].content);
        //             break;
        //           case this.HOME_S7_BANNER3:
        //             this.thirdimages = JSON.parse(result.result[i].content);
        //             break;
        //           // case this.HOME_CHEFS:
        //           //   this.homeChefs = JSON.parse(result.result[i].content);
        //           //   break;
        //           case this.HOME_WTC:
        //             let wtc = JSON.parse(result.result[i].content);
        //             this.S2 = wtc;
        //             break;
        //           case this.HOME_S9:
        //             let s9 = JSON.parse(result.result[i].content);
        //             this.s9_heading = s9.s9_heading;
        //             this.s9_title = s9.s9_title;
        //             this.s9_description = s9.s9_description;
        //             this.s9_image = this.apiService.BASE_IMAGE_URL + s9.s9_image;
        //             break;
        //           case this.HOME_S8:
        //             this.s8_image = this.apiService.BASE_IMAGE_URL + result.result[i].content;
        //             break;
        //           case this.HOME_HIW:
        //             let hiw = JSON.parse(result.result[i].content);
        //             this.S3 = hiw;
        //             //console.log("hiw:-" + result.result[i].content);
        //             // this.HIW_1_title = hiw.hiw_1_title;
        //             // this.HIW_2_title = hiw.hiw_2_title;
        //             // this.HIW_3_title = hiw.hiw_3_title;
        //             // this.HIW_1_description = hiw.hiw_1_description;
        //             // this.HIW_2_description = hiw.hiw_2_description;
        //             // this.HIW_3_description = hiw.hiw_3_description;
        //             break;
        //           case 'HOME_CMS':
        //             this.homeCMS = JSON.parse(result.result[i].content);
        //             this.home_cuisine_ids = this.homeCMS.home_cuisines;
        //             // this.HIW_1_title = hiw.hiw_1_title;
        //             // this.HIW_2_title = hiw.hiw_2_title;
        //             // this.HIW_3_title = hiw.hiw_3_title;
        //             // this.HIW_1_description = hiw.hiw_1_description;
        //             // this.HIW_2_description = hiw.hiw_2_description;
        //             // this.HIW_3_description = hiw.hiw_3_description;
        //             break;
        //           case this.HOME_S10:
        //             let s10 = JSON.parse(result.result[i].content);
        //             if (s10.s10_1 != '') {
        //               this.s10_1_image = this.apiService.BASE_IMAGE_URL + s10.s10_1;
        //             }
        //             if (s10.s10_2 != '') {
        //               this.s10_2_image = this.apiService.BASE_IMAGE_URL + s10.s10_2;
        //             }
        //             if (s10.s10_3 != '') {
        //               this.s10_3_image = this.apiService.BASE_IMAGE_URL + s10.s10_3;
        //             }
        //             if (s10.s10_4 != '') {
        //               this.s10_4_image = this.apiService.BASE_IMAGE_URL + s10.s10_4;
        //             }
        //             break;
        //           // case this.HOME_S11:
        //           //   let s11 = JSON.parse(result.result[i].content);
        //           //   if (s11.s11_1 != '') {
        //           //     this.s11_1_image = this.apiService.BASE_IMAGE_URL + s11.s11_1;
        //           //   }
        //           //   if (s11.s11_2 != '') {
        //           //     this.s11_2_image = this.apiService.BASE_IMAGE_URL + s11.s11_2;
        //           //   }
        //           //   if (s11.s11_3 != '') {
        //           //     this.s11_3_image = this.apiService.BASE_IMAGE_URL + s11.s11_3;
        //           //   }
        //           //   break;
        //         }
        //       }
        //
        //     }
        //     //console.log("cuisine ids:-" + JSON.stringify(this.home_cuisine_ids));
        //     if (this.home_cuisine_ids.length > 0) {
        //       this.getAllHomeCuisinesByIds();
        //     }
        //   } else {
        //     alert(result.message);
        //   }
        // }, (error) => {
        //   //console.log('error:-' + JSON.stringify(error));
        // })
    }

    getCuisines() {
        // this.apiService.getAPI(this.apiService.BASE_URL + "cuisine/getAllHomeCuisines").then((result) => {
        //   if (result.status) {
        //     this.allCuisines = result.result;
        //   }
        // })
    }

    getAllHomeCuisinesByIds() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/getCuinesByIds', {
            ids: JSON.stringify(this.cms.S2.cuisines)
        }).then((result) => {
            if (result.status) {
                this.allCuisines = result.result;
            }
        });
    }
    getS04Chef2TagByIds() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/getChefTagByIds', {
            ids: JSON.stringify(this.cms.SO4.chefTag)
        }).then((result) => {
            if (result.status) {
                this.chefTag = result.result;
                // console.log(this.chefTag,"800");
                
            }
        });
    }
    getS04Chef1TagByIds() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/getChefTagByIds', {
            ids: JSON.stringify(this.cms.SO3.occassion)
        }).then((result) => {
            if (result.status) {
                this.collectionTag = result.result;
                // console.log(this.collectionTag,"811");
                
            }
        });
    }
    getAllPopularCuisinesByIds() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/getCuinesByIds', {
            ids: JSON.stringify(this.cms.S14.cuisines)
        }).then((result) => {
            if (result.status) {
                this.popularCuisines = result.result;
                // console.log(this.popularCuisines,"s14");
                
            }
        });
    }
    pauseOnHover = true;

    flip: string = 'inactive';

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.searchresult = false;
    }

    closeDropdown() {
        this.searchresult = false;
    }

    @ViewChild('widgetsContent', {read: ElementRef})
    public widgetsContent!: ElementRef<any>;

    @ViewChild('widgetsContent1', {read: ElementRef})
    public widgetsContent1!: ElementRef<any>;
    @ViewChild('widgetsContentServed', {read: ElementRef})
    public widgetsContentServed!: ElementRef<any>;
    @ViewChild('widgetsContentPress', {read: ElementRef})
    public widgetsContentPress!: ElementRef<any>;

    @ViewChild('widgetsContenttrending', {read: ElementRef})
    public widgetsContenttrending!: ElementRef<any>;
    @ViewChild('widgetsContentOcctrending', {read: ElementRef})
    public widgetsContentOcctrending!: ElementRef<any>;
    @ViewChild('widgetsChef', {read: ElementRef})
    public widgetsChef!: ElementRef<any>;

    public scrollRight(): void {
        this.widgetsContent.nativeElement.scrollTo({
            left: (this.widgetsContent.nativeElement.scrollLeft + 1500),
            behavior: 'smooth',
            block: 'center'
        });
    }


    public scrollLeft(): void {
        this.widgetsContent.nativeElement.scrollTo({
            left: (this.widgetsContent.nativeElement.scrollLeft - 1500),
            behavior: 'smooth',
            block: 'center'
        });
    }

    public scrollLeft1(): void {
        this.widgetsContent1.nativeElement.scrollTo({
            left: (this.widgetsContent1.nativeElement.scrollLeft - 1500),
            behavior: 'smooth'
        });
        this.checkCuisineScroll();
    }

    public scrollRight1(): void {
        this.widgetsContent1.nativeElement.scrollTo({
            left: (this.widgetsContent1.nativeElement.scrollLeft + 1500),
            behavior: 'smooth'
        });
        this.checkCuisineScroll();
    }
    checkCuisineScroll(){
        this.widgetsContent1.nativeElement.scrollLeft==0? this.leftCuisineDisabled = true :this.leftCuisineDisabled = false;
    
        let newScrollLeft = this.widgetsContent1.nativeElement.scrollLeft;
        let width = this.widgetsContent1.nativeElement.clientWidth;
        let scrollWidth = this.widgetsContent1.nativeElement.scrollWidth;
        scrollWidth - (newScrollLeft+width)==0? this.rightCuisineDisabled = true :this.rightCuisineDisabled = false;
      }
    public scrollLeftServed(): void {
        this.widgetsContentServed.nativeElement.scrollTo({
            left: (this.widgetsContentServed.nativeElement.scrollLeft - 1500),
            behavior: 'smooth'
        });
        this.checkServedScroll();
    }

    public scrollRightServed(): void {
        this.widgetsContentServed.nativeElement.scrollTo({
            left: (this.widgetsContentServed.nativeElement.scrollLeft + 1500),
            behavior: 'smooth'
        });
        this.checkServedScroll();
    }
    checkServedScroll(){
        this.widgetsContentServed.nativeElement.scrollLeft==0? this.leftServedDisabled = true :this.leftServedDisabled = false;
    
        let newScrollLeft = this.widgetsContentServed.nativeElement.scrollLeft;
        let width = this.widgetsContentServed.nativeElement.clientWidth;
        let scrollWidth = this.widgetsContentServed.nativeElement.scrollWidth;
        scrollWidth - (newScrollLeft+width)==0? this.rightServedDisabled = true :this.rightServedDisabled = false;
      }
    public scrollLeftPress(): void {
        this.widgetsContentPress.nativeElement.scrollTo({
            left: (this.widgetsContentPress.nativeElement.scrollLeft - 1500),
            behavior: 'smooth'
        });
        this.checkPressScroll();
    }
    public scrollRightPress(): void {
        this.widgetsContentPress.nativeElement.scrollTo({
            left: (this.widgetsContentPress.nativeElement.scrollLeft + 1500),
            behavior: 'smooth'
        });
        this.checkPressScroll();
    }
    checkPressScroll(){
        this.widgetsContentPress.nativeElement.scrollLeft==0? this.leftPressDisabled = true :this.leftPressDisabled = false;
    
        let newScrollLeft = this.widgetsContentPress.nativeElement.scrollLeft;
        let width = this.widgetsContentPress.nativeElement.clientWidth;
        let scrollWidth = this.widgetsContentPress.nativeElement.scrollWidth;
        scrollWidth - (newScrollLeft+width)==0? this.rightPressDisabled = true :this.rightPressDisabled = false;
      }
    public scrollLeftTrending(): void {
        this.widgetsContenttrending.nativeElement.scrollTo({
            left: (this.widgetsContenttrending.nativeElement.scrollLeft - 1500),
            behavior: 'smooth'
        });
        this.checkTrendingScroll();
    }
    public scrollRightTrending(): void {
        this.widgetsContenttrending.nativeElement.scrollTo({
            left: (this.widgetsContenttrending.nativeElement.scrollLeft + 1500),
            behavior: 'smooth'
        });
        this.checkTrendingScroll();
    }
    checkTrendingScroll(){
        this.widgetsContenttrending.nativeElement.scrollLeft==0? this.lefttrendingDisabled = true :this.lefttrendingDisabled = false;
    
        let newScrollLeft = this.widgetsContenttrending.nativeElement.scrollLeft;
        let width = this.widgetsContenttrending.nativeElement.clientWidth;
        let scrollWidth = this.widgetsContenttrending.nativeElement.scrollWidth;
        scrollWidth - (newScrollLeft+width)==0? this.righttrendingDisabled = true :this.righttrendingDisabled = false;
      }
      public scrollLeftOccTrending(): void {
        this.widgetsContentOcctrending.nativeElement.scrollTo({
            left: (this.widgetsContentOcctrending.nativeElement.scrollLeft - 1500),
            behavior: 'smooth'
        });
        this.checkOccTrendingScroll();
    }
    public scrollRightOccTrending(): void {
        this.widgetsContentOcctrending.nativeElement.scrollTo({
            left: (this.widgetsContentOcctrending.nativeElement.scrollLeft + 1500),
            behavior: 'smooth'
        });
        this.checkOccTrendingScroll();
    }
    checkOccTrendingScroll(){
        this.widgetsContentOcctrending.nativeElement.scrollLeft==0? this.leftOcctrendingDisabled = true :this.leftOcctrendingDisabled = false;
    
        let newScrollLeft = this.widgetsContentOcctrending.nativeElement.scrollLeft;
        let width = this.widgetsContentOcctrending.nativeElement.clientWidth;
        let scrollWidth = this.widgetsContentOcctrending.nativeElement.scrollWidth;
        scrollWidth - (newScrollLeft+width)==0? this.rightOcctrendingDisabled = true :this.rightOcctrendingDisabled = false;
      }
    public scrollRightChef(): void {
        // console.log(this.widgetsChef.nativeElement.scrollLeft,"Rmove");
        // this.widgetsChef.nativeElement.scrollLeft = this.disabledRButn
        // console.log(this.disabledRButn,"moveR button");
        this.widgetsChef.nativeElement.scrollTo({
            // left: (this.widgetsChef.nativeElement.scrollLeft + 400),
            left: (this.widgetsChef.nativeElement.scrollLeft + 760),
            behavior: 'smooth',
            block: 'start'
        });
        this.checkScroll();
    }
    public scrollLeftChef(): void {
        console.log(this.widgetsChef.nativeElement.scrollLeft,"move");
        // this.widgetsChef.nativeElement.scrollLeft = this.disabledButn
        // console.log(this.disabledButn,"move button");
        
        this.widgetsChef.nativeElement.scrollTo({
            left: (this.widgetsChef.nativeElement.scrollLeft - 760),
            // left: (this.widgetsChef.nativeElement.scrollLeft - 760),
            behavior: 'smooth',
            block: 'center'
        });
        this.checkScroll();
    }
    public scrollMobRightChef(): void {
        this.widgetsChef.nativeElement.scrollTo({
            left: (this.widgetsChef.nativeElement.scrollLeft + 400),
            // left: (this.widgetsChef.nativeElement.scrollLeft + 760),
            behavior: 'smooth',
            block: 'center'
        });
        this.checkScroll();
    }
  
    public scrollMobLeftChef(): void {
        this.widgetsChef.nativeElement.scrollTo({
            // left: (this.widgetsChef.nativeElement.scrollLeft - 400),
            left: (this.widgetsChef.nativeElement.scrollLeft - 400),
            behavior: 'smooth',
            block: 'center'
        });
        this.checkScroll();
    }
    checkScroll(){
        this.widgetsChef.nativeElement.scrollLeft==0? this.leftDisabled = true :this.leftDisabled = false;
    
        let newScrollLeft = this.widgetsChef.nativeElement.scrollLeft;
        let width = this.widgetsChef.nativeElement.clientWidth;
        let scrollWidth = this.widgetsChef.nativeElement.scrollWidth;
        scrollWidth - (newScrollLeft+width)==0? this.rightDisabled = true :this.rightDisabled = false;
      }

    name = 'angular';
    url = URLS[0];
    toLoad = true;
    timeStamp = '';
    index = 0;

    toggle() {
        this.toLoad = false;
        this.index = (this.index + 1) % URLS.length;
        this.url = URLS[this.index];
        this.toLoad = true;
        this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
    }

    empty() {
        this.url = '';
    }

    loaded(event: any) {
        //console.log('loaded', event);
        this.timeStamp = event.timeStamp;
    }


    // firstimages = []
    // secondimages = []
    // thirdimages = []

    opensearchresult() {
        this.searchresult = true;
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    scroll1(el: HTMLElement) {
        el.scrollIntoView();
    }

    searchedText = '';
    cuisines: any = [];
    items: any = [];
    chefs: any = [];

    homeChefs: Chef[] = [];
    testimonials = [];
    mobTestimonials = [];
    
    searchItems() {
        this.cuisines = [];
        this.items = [];
        this.chefs = [];
        setTimeout(() => {
            // //console.log("search text:-"+this.searchedText);
            if (this.searchedText.length > 0) {
                this.searchresult = true;
            } else {
                this.searchresult = false;
            }
            this.searchDishes();
        }, 100);
    }

    searchDishes() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'search/searchitems', {
            // region:this.selectedCity,
            region:this.mappedCity,
            keyword: this.searchedText
        }).then((result) => {

            if (result.status) {
                this.cuisines = result.result.cuisines;
                for (let i = 0; i < this.cuisines.length; i++) {
                    if (this.cuisines[i].image == null || this.cuisines[i].image == '') {
                        this.cuisines[i].image = 'assets/images/cuisine1.png';
                    }
                }
                this.chefs = result.result.chefs;
                // for (let i = 0; i < this.chefs.length; i++) {
                //   if (this.chefs[i].profile_pic == null || this.chefs[i].profile_pic != '') {
                //     this.chefs[i].profile_pic = "assets/images/avatar.png";
                //   }
                // }
                this.items = result.result.items;
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].qty_type != null && this.items[i].qty_type != '') {
                        switch (this.items[i].qty_type) {
                            // case "1=gm,2=kg,3=pcs,4=l,5=ml"
                            case 1:
                                this.items[i]['quantity_type'] = 'gms';
                                break;
                            case 2:
                                this.items[i]['quantity_type'] = 'kg';
                                break;
                            case 3:
                                this.items[i]['quantity_type'] = 'pcs';
                                break;
                            case 4:
                                this.items[i]['quantity_type'] = 'l';
                                break;
                            case 5:
                                this.items[i]['quantity_type'] = 'ml';
                                break;
                            case 6:
                                this.items[i]['quantity_type'] = 'in';
                                break;
                        }
                    }
                }
            }
        });
    }

    openCuisine(id: any, name) {
        // this.router.navigate(['search-page'], {
        //   state: {
        //     type: 'cuisine',
        //     cuisineId: id,
        //     cuisineName: name
        //   }
        // });

        this.searchService.setType('cuisine');
        this.searchService.setValues({
            type: 'cuisine',
            cuisineId: id,
            cuisineName: name
        });
        localStorage.setItem('dataSource',id)
        this.router.navigateByUrl('search-cuisine/book-your-order');

    }
    openOccassion(id: any, name) {
        this.searchService.setType('occassion');
        this.searchService.setValues({
            type: 'occassion',
            tagId: id,
            tagName: name
        });
        this.router.navigateByUrl('search-cuisine/book-your-order');

    }
    
    openCollection(id: any, name) {
        this.searchService.setType('collection');
        this.searchService.setValues({
            type: 'collection',
            tagId: id,
            tagName: name
        });
        this.router.navigateByUrl('search-cuisine/book-your-order');

    }
    removeExtraHyphens(inputString: string): string {
        return inputString.replace(/-+/g, '-');
    }
    openChefDetail(id: any, name) {
        if (name != null) {
            // name = name.replace(/[ ,]+/g, '-');
            name = name.replace(/ /g, '-');
            if (name.endsWith('-')) {
                name = name.slice(0, -1);
              }
              
              let ChefName = this.removeExtraHyphens(name)
            this.router.navigateByUrl('/chefdetail/' + ChefName);
        } else {
            this.apiService.postAPI(this.apiService.BASE_URL + 'user/getChefBasicProfile', {
                chef_id: id
            }).then((result) => {
                if (result.status) {
                    if (result.result != null) {
                        let name = result.result.name;
                        // name = name.replace(/[ ,]+/g, '-');
                        name = name.replace(/ /g, '-');
                        this.router.navigateByUrl('/chefdetail/' + name);
                    }
                }
            });
        }
        // this.utilService.setItem('chefId', id);


        // this.router.navigateByUrl('/chefdetail/' + id);
    }
    openChefItem(id: any) {
        console.log(id,"id");
        
            localStorage.setItem("itemId",id);
    }

    searchPage() {
        // this.searchService.setValues(null);
        // this.router.navigate(['search-page'], {
        //   state: {
        //     type: 'items',
        //     keyword: this.searchedText,
        //     items: this.items
        //   }
        // });

        this.searchService.setType('items');
        this.searchService.setValues({
            type: 'items',
            keyword: this.searchedText,
            items: this.items
        });
        this.router.navigateByUrl('search-cuisine/book-your-order');
    }

    subscribe_email = '';


    addSubscription() {
        if (this.subscribe_email == '') {
            this.toaster.error('Please enter your email address to subscribe to We The Chefs.');
            return;
        }
        if (!this.utilService.validateEmail(this.subscribe_email)) {
            this.toaster.error('Please enter valid email');
            return;
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/subscribeUser', {
            email: this.subscribe_email
        }).then((result) => {
            this.subscribe_email = '';
            this.toaster.success('You have successfully subscribed to We The Chefs.');
        });
    }

    // for faq's
    test_active = 'carousel-item active carousel-banner';
    test_inactive = 'carousel-item inactive carousel-banner';

    // getTestCorouselClass(index){
    //   for(let i=0;i<this.testimonials.length;i++){
    //     this.testimonials[i].class=this.test_inactive;
    //   }
    //   this.testimonials[index].class=this.test_active;
    //   return this.testimonials[index].class;
    // }

    getCMS() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/getAllCMS', {
            names: ['HOME']
        }).then((result) => {
            if (result.status) {
                // console.log("content:-" + JSON.stringify(result));
                this.cms = JSON.parse(result.result[0].content);
                document.body.classList.remove('stop-scrolling');
                // console.log("cms:-" + JSON.stringify(this.cms));
                this.getAllHomeCuisinesByIds();
                this.getAllPopularCuisinesByIds();
                this.getS04Chef2TagByIds();
                this.getS04Chef1TagByIds();
                if (this.cms.S5 != undefined) {
                    // let chefs=[];
                    // for(let i=0;i<this.cms.S5.chefs.length;i++){
                    //   chefs.push({
                    //     chef_id:this.cms.S5.chefs[i].chef_id
                    //   })
                    // }
                    this.getHomeChefs({
                        chefs: this.cms.S5.chefs
                    });
                    // this.homeChefs[this.cms.S5.chefs].selectDishes[index].cart_item = item1;
                    // this.homeChefs[chefIndex].selectDishes[index].in_cart = true;
                    // this.cartselectDishes = this.homeChefs[chefIndex].selectDishes;
                    for(let i=0;i<this.cms.S5.chefs.length;i++){
                    //   chefs.push({
                    //     chef_id:this.cms.S5.chefs[i].chef_id
                    //   })
                    // this.homeChefs.filter(x => x.id)
                    // this.cms.S5.chefs.filter(x => x.chef_id == this.cms.S5.chefs)[0].selectedDishes = this?.selectedDishes;
                    // console.log(this.cms.S5.chefs,"Devil");
                    // console.log(this.cartselectDishes,"1122 Devil");
                    // console.log(this?.selectedDishes,"Deepakj");
                    
                }
            }
                this.testimonials = [];
                this.mobTestimonials = [];
                if (this.cms.testimonials != undefined) {
                    for (let i = 0; i < this.cms.testimonials.length; i++) {
                        let test = [];
                        test.push(this.cms.testimonials[i]);
                        if ((i + 1) != this.cms.testimonials.length) {
                            test.push(this.cms.testimonials[i + 1]);
                        }
                        let style = 'carousel-item inactive carousel-banner';
                        if (i == 0) {
                            style = 'carousel-item active carousel-banner';
                        }
                        this.testimonials.push({
                            class: style,
                            test: test
                        });
                        i++;
                    }
                    for (let i = 0; i < this.cms.testimonials.length; i++) {
                        let style = 'carousel-item inactive carousel-banner';
                        if (i == 0) {
                            style = 'carousel-item active carousel-banner';
                        }
                        this.mobTestimonials.push({
                            class: style,
                            test: this.cms.testimonials[i]
                        });
                    }
                    // console.log('testimonials:-' + JSON.stringify(this.testimonials, null, 4));
                    this.getS16Image()
                }
            } else {
                alert(result.message);
            }
        }, (error) => {
            //console.log('error:-' + JSON.stringify(error));
        });
    }

    openChristmas(i) {
       
        if (this.cms.S1_BANNERS[i].event_id != null && this.cms.S1_BANNERS[i].event_id != undefined && this.cms.S1_BANNERS[i].event_id != '') {
        
            // already comment before changes from here start
            // this.router.navigate(
            // this.router.navigateByUrl(
            //     '/become-a-chef/' +  this.cms.S1_BANNERS[i].event_id,
            //     ['/event-detail'],
            //     {queryParams: {event_id: this.cms.S1_BANNERS[i].event_id}}
            // );
            // already comment before changes end
            window.location.href = this.cms.S1_BANNERS[i].event_id
            // window.location.href = "https://wethechefs.in/blog/" + this.cms.S1_BANNERS[i].event_id

        
        }
    }

    pressEvent(i) {
        if (this.cms.S7.banner1[i].tag1 != null && this.cms.S7.banner1[i].tag1 != undefined && this.cms.S7.banner1[i].tag1 != '') {
        
            // already comment before changes from here start
            // this.router.navigate(
            // this.router.navigateByUrl(
            //     '/become-a-chef/' +  this.cms.S1_BANNERS[i].event_id,
            //     ['/event-detail'],
            //     {queryParams: {event_id: this.cms.S1_BANNERS[i].event_id}}
            // );
            // already comment before changes end
            window.location.href = this.cms.S7.banner1[i].tag1

        
        }
    }
    openOrderNow(i){
        window.location.href = i
    }
    // openChristmas(i) {
       
    //     // if (this.cms.S1_BANNERS[i].event_id != null && this.cms.S1_BANNERS[i].event_id != undefined && this.cms.S1_BANNERS[i].event_id != '') {
        
    //         window.location.href = "https://wethechefs.in/blog/" + i

        
    //     // }
    // }

    insta = [];

    instafeeds() {
        // this.apiService.getAPI('https://v1.nocodeapi.com/shravanv_/instagram/afHdLgbsaGVQxAuJ').then((result) => {
        //   console.log(result);
        //   this.insta = result.data;
        //   localStorage.setItem('insta', JSON.stringify(this.insta));
        // })
        localStorage.getItem('insta');
        this.insta = JSON.parse(localStorage.getItem('insta'));
        console.log(this.insta);
    }
    
    VideoPopup() {
        const modalRef = this.modalService.open(VideoPopupComponent, {
            size: '',
            windowClass: "videoPopup",
            centered: true,
            backdrop: 'static',
        });
        modalRef.closed.subscribe((result) => {
          console.log("closed")
        })
        // modalRef.componentInstance.message = "https://www.youtube.com/embed/SFgppU2J8To";
        modalRef.componentInstance.message = this.s5Video;
        // modalRef.componentInstance.orignFrom = 'customer';
      }

    instafeednew() {
        var feed = new Instafeed({
            get: 'user',
            userId: 'shravanv_',
            clientId: 'xxxxxxxxx',
            accessToken: 'Z',
            template: '<a href="{{link}}"><img src="{{image}}" /><p>Likes {{likes}}</p><p>Comments {{comments}}</p></a>',
            limit: 1
        });
        feed.run();
    }
    // remove(item: any) {
    //     if (this.utilService.isUserLoggedIn()) {
    //         this.apiService.postAPI(this.apiService.BASE_URL + 'cart/addToCart', {
    //             user_id: this.utilService.getUserID(),
    //             item_id: item.id,
    //             quantity: item.cart_item.quantity - 1,
    //             price: (item.base_price * (item.cart_item.quantity - 1))
    //         }).then((result) => {
    //             this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    //             if (result.status) {
    //                 this.getUserCartItems();
    //             }
    //         }, (error) => {
    //             console.log('error');
    //         });
    //     } else {
    //         this.utilService.deleteFromCart(item);
    //         this.getUserCartItems();
    //         this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    //     }
    // }
    // addItem(item: any) {
    //     if (this.utilService.isUserLoggedIn()) {
          
    //         this.apiService.postAPI(this.apiService.BASE_URL + 'cart/addToCart', {
    //             user_id: this.utilService.getUserID(),
    //             item_id: item.id,
    //             quantity: item.cart_item.quantity + 1,
    //             price: (item.base_price * (item.cart_item.quantity + 1))
    //         }).then((result) => {
    //             this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    //             if (result.status) {
    //                 this.getUserCartItems();
    //             }
    //         }, (error) => {
    //             console.log('error');
    //         });
    //     } else {
    //         item['type'] = 'item';
    //         // this.utilService.addToLocalCart(item);
    //         this.utilService.addToLocalCart(this.modalService, item.id, item.name, item.chef_id, item.price, 'item');
    //         this.getUserCartItems();
    //         this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    //     }
    // }
    // addToCart(index, item_id, price) {
    //     if (!this.utilService.isUserLoggedIn()) {
    //         // console.log('items:-' + JSON.stringify(this.items[index], null, 4));

    //         this.utilService.addToLocalCart(this.modalService, item_id, this.items[index].name, this.items[index].chef_id, price, 'item');
    //         this.getUserCartItems();
    //         this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    //     } else {
    //         if (this.cartItems.length > 0) {

    //             let checkEvent = this.checkEventInCart(this.cartItems);

    //             for (let i = 0; i < this.cartItems.length; i++) {
    //                 let itemMatches = false;
    //                 for (let j = 0; j < this.items.length; j++) {
    //                     if (this.cartItems[i].item_id == this.items[j].id) {
    //                         itemMatches = true;
    //                         break;
    //                     }
    //                 }
    //                 if (!itemMatches) {
    //                     //show delete items from cart
    //                     const modalRef = this.modalService.open(ConfirmationModalComponent, {size: ''});
    //                     modalRef.closed.subscribe((result) => {
    //                         if (result.confirm) {
    //                             this.apiService.deleteAPI(this.apiService.BASE_URL + 'cart/deleteCartByUserId/' + this.utilService.getUserID()).then((result) => {
    //                                 if (result.status) {
    //                                     // this.ngOnInit();
    //                                     this.addCartAPI(item_id, price, index);
    //                                 }
    //                             }, (error) => {
    //                                 console.log('error');
    //                             });
    //                         }
    //                     });
    //                     modalRef.componentInstance.message = 'Please choose food from a single chef to proceed! We are working on the multi-chef feature and will inform you when live. Click to proceed.';
    //                     return;
    //                 }
    //             }
    //         }

    //         this.addCartAPI(item_id, price, index);
    //     }
    // }

    // addCartAPI(item_id, price, index) {
    //     this.apiService.postAPI(this.apiService.BASE_URL + 'cart/addToCart', {
    //         user_id: this.utilService.getUserID(),
    //         item_id: item_id,
    //         quantity: 1,
    //         price: price,
    //         // commission:this.commission,
    //         // chef_price:this.utilService.getChefOrderPrice(price,this.commission)
    //     }).then((result) => {
    //         if (result.status) {
    //             this.items[index]['in_cart'] = true;
    //             this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
    //             this.getUserCartItems();
    //         } else {
    //             alert(result.message);
    //         }
    //     }, (error) => {
    //         console.log('error');
    //     });
    // }

    // removeFromCart(index, item_id) {
    //     if (!this.utilService.isUserLoggedIn()) {
    //         // alert("please login to continue");
    //         // return;

    //         this.utilService.removeFromCart(this.items[index]);
    //         this.items[index]['in_cart'] = false;

    //     } else {
    //         this.apiService.postAPI(this.apiService.BASE_URL + 'cart/deleteCart', {
    //             user_id: this.utilService.getUserID(),
    //             item_id: item_id
    //         }).then((result) => {
    //             if (result.status) {
    //                 this.items[index]['in_cart'] = false;
    //             } else {
    //                 alert(result.message);
    //             }
    //         }, (error) => {
    //             console.log('error');
    //         });
    //     }
    // }

    // private getUserCartItems() {
    //     this.cartItems = [];
    //     this.quantity = 0;
    //     this.totalPrice = 0;
    //     if (this.utilService.isUserLoggedIn()) {
    //         // console.log("cart items");
    //         this.apiService.getAPI(this.apiService.BASE_URL + 'cart/getCartItemDetailByUserId/' + this.utilService.getUserID()).then((result) => {
    //             if (result.status) {
    //                 this.cartItems = result.result;
    //                 this.quantity = 0;
    //                 let totalPrice = 0;
    //                 for (let i = 0; i < this.cartItems.length; i++) {
    //                     totalPrice += Number(this.cartItems[i].price);
    //                     this.quantity += this.cartItems[i].quantity;
    //                 }
    //                 this.totalPrice = totalPrice;
    //                 for (let i = 0; i < this.items.length; i++) {
    //                     this.items[i].in_cart = false;
    //                 }
    //                 for (let i = 0; i < this.items.length; i++) {
    //                     for (let j = 0; j < result.result.length; j++) {
    //                         if (this.items[i].id == result.result[j].item_id) {
    //                             this.items[i].in_cart = true;
    //                             this.items[i]['cart_item'] = result.result[j];
    //                         }
    //                     }
    //                 }
    //             } else {
    //                 for (let i = 0; i < this.items.length; i++) {
    //                     this.items[i].in_cart = false;
    //                 }
    //             }
    //         }, (error) => {
    //             console.log('error');
    //         });
    //     } else {
    //         this.cartItems = this.utilService.getLocalCart();
    //         // console.log('get local cart:-' + JSON.stringify(this.utilService.getLocalCart(), null, 4));
    //         // console.log('cart item update:-' + this.cartItems.length);
    //         if (this.cartItems.length != 0) {
    //             if (this.checkEventInCart(this.cartItems)) {
    //                 this.setAllItemsOutOfCart();
    //             } else {
    //                 this.setAllItemsOutOfCart();
    //                 for (let i = 0; i < this.items.length; i++) {
    //                     for (let j = 0; j < this.cartItems.length; j++) {
    //                         if (this.items[i].id == this.cartItems[j].id) {
    //                             this.items[i].in_cart = true;
    //                             this.items[i]['cart_item'] = this.cartItems[j];
    //                         }
    //                     }
    //                 }

    //                 // console.log('items:-' + JSON.stringify(this.items, null, 4));
    //             }
    //             this.quantity = 0;
    //             for (let i = 0; i < this.cartItems.length; i++) {
    //                 this.quantity += this.cartItems[i].quantity;
    //             }
    //         } else {
    //             this.setAllItemsOutOfCart();
    //         }
    //     }
    // }

    // checkEventInCart(cart) {
    //     for (let i = 0; i < cart.length; i++) {
    //         if (cart[i].type == 'event') {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // setAllItemsOutOfCart() {
    //     for (let i = 0; i < this.items.length; i++) {
    //         this.items[i].in_cart = false;
    //     }
    // }
    // checkOut() {

    //     // console.log('totalPrice:-' + this.totalPrice);
    //     // console.log('min_order_val:-' + this.min_order_val);

    //     if (this.utilService.isUserLoggedIn()) {
    //         if (this.cartItems.length > 0) {
    //             if (this.totalPrice >= this.min_order_val) {
    //                 this.router.navigate(['/checkout']);
    //             } else {
    //                 this.toaster.error('Minimum order amount must be greater than ' + this.min_order_val);
    //             }
    //         }
    //     } else {
    //         this.eventService.emit(new EmitEvent(Events.OPEN_NAVBAR_LOGIN, ''));
    //     }
    // }
    // checkUserPlacesOrder() {
    //     this.apiService.postAPI(this.apiService.BASE_URL + 'order/checkUserPlacesOrder', {
    //         user_id: this.utilService.getUserID(),
    //         chef_id: this.chef_id
    //     }).then((result) => {
    //         if (result.status) {
    //             this.addNewReview();
    //         } else {
    //             alert('Hi! Have your ordered delicious food from the selected home chef yet? To add your review, please place your order, enjoy your meal and review our home chefs\' food story. ');
    //         }
    //     });
    // }
    // getCPKM() {
    //     this.apiService.getAPI(this.apiService.BASE_URL + 'cart/getCPKM').then((result) => {
    //         if (result.status) {
    //             this.commission = result.result.commission;
    //         }
    //     }, (error) => {
    //         //console.log("error");
    //     });
    // }
    data: any = [{
        "parentName": "Q. Where can I find homemade food near me?",
        "childProperties":
          [
            { "propertyName": "Looking for homemade food near me in Delhi, Gurgaon, Noida, or Bangalore? We've got you covered! Our home food website connects you with certified home chefs who prepare delicious meals right in their kitchens. With just a few clicks, you can find the best homemade food near me." },
          ]
      }, {
        "parentName": "Q. How can I order home cooked food delivery near me?",
        "childProperties":
          [
            { "propertyName": "Ordering home cooked food delivery near me in Gurgaon, Delhi, Noida, and Bangalore has never been easier! Simply search for homemade food near me on our home food website, select your location, and browse through a wide range of tasty home made dishes prepared by our talented home chefs. Enjoy the convenience of getting fresh homely food delivered straight to your doorstep." },
          ]
      }, {
        "parentName": "Q. Can I find vegetarian/vegan/gluten-free homemade food delivery near me?",
        "childProperties":
          [
            { "propertyName": "If you prefer vegetarian, vegan, or gluten-free options, we have a variety of homemade food choices available to cater to your dietary preferences. Just specify your requirements in the search filters and explore the delicious homemade food near me in Delhi, Gurgaon, Noida, or Bangalore." },
          ]
      },
      {
        "parentName": "Q. Do you offer home cooked meal subscriptions or packages?",
        "childProperties":
          [
            { "propertyName": "Yes, we offer home cooked & gourmet food subscriptions and packages for your convenience. Enjoy the taste of homemade food regularly by subscribing to weekly or monthly meal plans tailored to your needs. Our home chefs will prepare a variety of dishes to keep your taste buds satisfied." },
          ]
      },
      {
        "parentName": "Q. Do I need to pre-order homely food or can I get it on-demand?",
        "childProperties":
          [
            { "propertyName": "Whether you need homely & gourmet food on-demand or prefer to pre-order, we've got you covered. You can choose from options that are available for immediate delivery or plan ahead by placing your order in advance. Enjoy the flexibility of getting homemade food near me in Delhi, Gurgaon, Noida, or Bangalore whenever you need it." },
          ]
      },
      {
        "parentName": "Q. Can I schedule regular home made food delivery near me?",
        "childProperties":
          [
            { "propertyName": "Want to schedule regular home made food delivery in Delhi, Gurgaon, Noida, or Bangalore? Our home food website allows you to set up recurring orders so you can enjoy a constant supply of delicious homemade meals delivered to your doorstep. Save time and enjoy the convenience of regular deliveries." },
          ]
      },
      {
        "parentName": "Q. How can I ensure that the home food I order is safe and prepared hygienically?",
        "childProperties":
          [
            { "propertyName": "We understand the importance of food safety and hygiene. Rest assured, all the home chefs on our platform follow strict hygiene standards to ensure the food you order is safe and prepared with utmost care. We maintain a high level of quality control to deliver the best homemade food to your table." },
          ]
      },
      {
        "parentName": "Q. Can I customize my home food delivery near me?",
        "childProperties":
          [
            { "propertyName": "Customize your home food delivery based on your preferences. Whether you want to add extra spice, request specific ingredients, or have dietary preferences, you can mention them while placing your order. Our home chefs will strive to accommodate your customization requests and make your meal perfect for you." },
          ]
      },
      {
        "parentName": "Q. What payment methods are accepted for ordering homemade food?",
        "childProperties":
          [
            { "propertyName": "We accept various payment methods to make it convenient for you to order homemade food. You can pay online using credit/debit cards, mobile wallets, or choose cash-on-delivery options, ensuring a seamless and secure transaction." },
          ]
      },
      {
        "parentName": "Q. Can I request special instructions or dietary preferences when ordering home cooked food?",
        "childProperties":
          [
            { "propertyName": "Absolutely! We understand that everyone's dietary needs vary. When ordering home cooked food from Delhi, Gurgaon, Noida, or Bangalore, you can add special instructions or mention your dietary preferences, such as low-carb, low-sugar, or any other specific requirement. Our home chefs will do their best to cater to your needs." },
          ]
      },
      {
        "parentName": "Q. How do I place an order for home food delivery near me?",
        "childProperties":
          [
            { "propertyName": "Placing an order for home food delivery near me in Delhi, Gurgaon, Noida, or Bangalore is simple. Just enter your location, browse through the available meal options, and select the dishes you crave. Add them to your cart, review your order, and proceed to checkout for a hassle-free gourmet food ordering experience." },
          ]
      },
      {
        "parentName": "Q. How do I track my home food delivery?",
        "childProperties":
          [
            { "propertyName": "Tracking your home food delivery is easy. Once you've placed your order, you will receive updates about its progress, including when it is being prepared, dispatched, and estimated delivery time. You can also track the delivery in real-time to know exactly when your delicious homemade & gourmet food will arrive." },
          ]
      },
      {
        "parentName": "Q. Can I cancel or modify my home cooked food order after it has been placed?",
        "childProperties":
          [
            { "propertyName": "While we understand that plans can change, we request that you check the specific cancellation and modification policies for each order. Depending on the home chefs and the stage of preparation, you may be able to cancel or modify your order. Reach out to our customer support for assistance." },
          ]
      },
      {
        "parentName": "Q. Is there a minimum order requirement for homemade food delivery near me?",
        "childProperties":
          [
            { "propertyName": "The minimum order requirement for homemade food delivery may vary depending on the home chefs and location. You can find the minimum order details mentioned on each home chefs profile or while placing your order. Enjoy a delicious homemade meal without worrying about exceeding any minimum limits." },
          ]
      },
      {
        "parentName": "Q. Can I request specific ingredients or dietary substitutions for my homemade food order?",
        "childProperties":
          [
            { "propertyName": "Yes, you can request specific ingredients or dietary substitutions when placing your homemade food order. Whether you have allergies, preferences, or restrictions, our home chefs are happy to accommodate your requests and ensure your meal suits your needs." },
          ]
      },
      {
        "parentName": "Q. What is the estimated delivery time for homemade food near me?",
        "childProperties":
          [
            { "propertyName": "The estimated time for homemade food delivery near me in Delhi, Gurgaon, Noida, or Bangalore will vary depending on factors like distance, order volume, and preparation time. When placing your order, you will receive an estimated delivery time, allowing you to plan your meal accordingly." },
          ]
      },
      {
        "parentName": "Q. Can I request a sample or try a small portion before placing a larger home cooked food order?",
        "childProperties":
          [
            { "propertyName": "Interested in trying our homemade food before placing a larger order? While we don't offer sample sizes for every dish, some of our home chefs may provide sampler options or small portion sizes for you to get a taste before committing to larger quantities. Check the available options while ordering." },
          ]
      },
      {
        "parentName": "Q. Are there any options for low-sodium, low-sugar, or other dietary-restricted homemade meals near me?",
        "childProperties":
          [
            { "propertyName": "We offer a range of dietary-restricted homemade meals. From low-sodium to low-sugar options and more, our home chefs cater to various dietary needs. Simply filter your search preferences to find suitable home made food that meet your specific dietary requirements." },
          ]
      },
      {
        "parentName": "Q. Can I find homemade food catering services for special events or occasions near me?",
        "childProperties":
          [
            { "propertyName": "Hosting a special event or occasion? Our home food website also offers homemade food catering services near me in Delhi, Gurgaon, Noida, or Bangalore. Whether it's a birthday party, corporate gathering, engagement, anniversary or any other celebration, our home chefs can provide delicious homemade food customized to your event's requirements. Contact us for more information." },
          ]
      },
      {
        "parentName": "Q. Is there a minimum order amount for home food delivery near me?",
        "childProperties":
          [
            { "propertyName": "The minimum order amount for home food delivery near me in Delhi, Gurgaon, Noida, or Bangalore may vary depending on the home chefs and location. To find out the applicable minimum order requirement, check the details listed on each home chefs profile or while placing your order." },
          ]
      },
      {
        "parentName": "Q. Can I request special packaging or eco-friendly containers for my home cooked food delivery?",
        "childProperties":
          [
            { "propertyName": "Yes, you can request special packaging or eco-friendly containers for your home cooked food delivery. We strive to minimize our environmental impact by offering eco-friendly options. Just mention your preference while placing your order, and our home chefs will package your meal accordingly." },
          ]
      },
      {
        "parentName": "Q. Can I order homemade food for a specific date and time in advance, such as for a dinner party or family gathering?",
        "childProperties":
          [
            { "propertyName": "Planning a dinner party or family gathering and need homemade food? Absolutely! You can order homemade food for a specific date and time in advance. Simply select the desired delivery date and time while placing your order, and our home chefs will ensure your homemade food is delivered on time." },
          ]
      },
      {
        "parentName": "Q. Are there any homemade food options suitable for individuals with specific dietary restrictions, such as diabetic-friendly or low-carb meals?",
        "childProperties":
          [
            { "propertyName": "We understand that individuals have specific dietary restrictions, such as diabetic-friendly or low-carb meals. Our platform provides options suitable for individuals with various dietary needs. You can filter your search based on your specific requirements and explore the available homemade food options catering to your dietary restrictions." },
          ]
      },
    ];
    
      toggleAccordian(event, index) {
        const element = event.target;
        element.classList.toggle("active");
        if (this.data[index].isActive) {
          this.data[index].isActive = false;
        } else {
          this.data[index].isActive = true;
        }
        const panel = element.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      }
}

declare var Instafeed: any;
const URLS = [
    './assets/images/chicken-tikka.png',
    './assets/images/cuisine2.png',
    './assets/images/cuisine3.png'
];

