import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {UtilService} from "../../services/util.service";
import {APIService} from "../../services/api.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-journey-customer',
  templateUrl: './journey-customer.component.html',
  styleUrls: ['./journey-customer.component.scss']
})
export class JourneyCustomerComponent implements OnInit {

  validName = true;
  validNameText = '';
  validEmail = true;
  validEmailText = '';
  validPhone = true;
  validPhoneText = '';

  name = '';
  email = '';
  phone = '';

  constructor(
    public activeModal: NgbActiveModal,
    public utilService: UtilService,
    private apiService: APIService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  validateUser() {
    let isValid = true;
    if (this.name == '') {
      isValid = false;
      this.validName = false;
      this.validNameText = 'Please enter name';
    } else {
      this.validName = true;
    }
    if (this.email == '') {
      isValid = false;
      this.validEmail = false;
      this.validEmailText = 'Please enter email';
    } else {
      this.validEmail = true;
    }
    if (!this.utilService.validateEmail(this.email)) {
      isValid = false;
      this.validEmail = false;
      this.validEmailText = 'Please enter valid email address';
    } else {
      this.validEmail = true;
    }
    if (this.phone == '') {
      isValid = false;
      this.validPhone = false;
      this.validPhoneText = 'Please enter phone number';
    } else {
      this.validPhone = true;
    }
    // console.log("length:-"+this.phone.toString().length);
    if (this.phone.toString().length == 12) {
      // this.phone=this.phone.toString().replace("91","");
      this.phone = this.phone.toString().substring(2);
    }
    // console.log("Phone:-" + this.phone);

    if (this.phone.toString().length != 10) {
      isValid = false;
      this.validPhone = false;
      this.validPhoneText = 'Please enter correct phone number';
    } else {
      this.validPhone = true;
    }
    return isValid;
  }

  Submit() {
    if (this.validateUser()) {
      this.apiService.postAPI(this.apiService.BASE_URL + "common/saveRandomUser", {
        name: this.name,
        email: this.email,
        phone: this.phone
      }).then((result) => {
        if (result.status) {
          this.toaster.success("Thanks for sharing your information");
          this.name = '';
          this.email = '';
          this.phone = '';
          this.activeModal.close();
        } else {
          this.toaster.error(result.message);
        }
      }, (error) => {
        // console.log("error:-" + error.message);
        this.toaster.error("Something went wrong");
      })
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
