import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './allpages/checkout/checkout.component';
import { HomeComponent } from './allpages/home/home.component';
import { TestComponent } from './allpages/test/test.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '' },
  {
    path: 'home',
    loadChildren: () => import('./allpages/home/home.module')
      .then(m => m.HomeModule)
  },

  { path: 'checkout', component: CheckoutComponent },
  { path: 'test', component: TestComponent },

  {
    path: 'our-story',
    loadChildren: () => import('./allpages/our-story/our-story.module')
      .then(m => m.OurStoryModule)
  },
  {
    path: 'become-a-chef',
    loadChildren: () => import('./allpages/become-a-chef/become-a-chef.module')
      .then(m => m.BecomeAChefModule)
  },
  {
    path: 'search-cuisine/book-your-order',
    loadChildren: () => import('./allpages/search-page/search-page.module')
      .then(m => m.SearchPageComponentModule)
  },
  { path: 'search-cuisine/book-your-order/Delhi', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/Gurgaon', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/Noida', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/noida', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/Bangalore', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/bangalore', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/DLF-Phase-2', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/DLF-Cyber-City', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/Sushant-Lok', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/Golf-Course-Road', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/MG-Road', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/Nirvana-Country', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/Sector-54', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/Sector-42', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/Palam-Vihar', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/gurgaon/South-City', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/Golf-Links', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/Vasant-Vihar', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/Greater-Kailash', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/Sainik-Farms', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/New-Friends-Colony', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/Defence-Colony', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/Vasant-Kunj', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/South-Extension', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/Green-Park', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/delhi/Dwarka', loadChildren: () => import('./allpages/searchpagelocation/searchpagelocation.module').then(m => m.SearchpagelocationModule) },
  { path: 'search-cuisine/book-your-order/:location/:cusisne_name', loadChildren: () => import('./allpages/searchpagecuisinelocation/searchpagecuisinelocation.module').then(m => m.SearchpagecuisinelocationModule) },
  // { path: 'search-cuisine/book-your-order/delhi/:cusisne_name', loadChildren: () => import('./allpages/searchpagecuisinelocation/searchpagecuisinelocation.module').then(m => m.SearchpagecuisinelocationModule) },
  {
    path: 'search-cuisine/book-your-order/:cusisne_name',
    loadChildren: () => import('./allpages/search-ssr/search-ssr.module')
      .then(m => m.SearchSsrModule)
  },
  // {
  //   path: 'search-cuisine/book-your-order',
  //   loadChildren: () => import('./allpages/search-ssr/search-ssr.module')
  //     .then(m => m.SearchSsrModule)
  // },
  
  {
    path: 'chefdetail/:chef_id',
    loadChildren: () => import('./allpages/chefdetail/chefdetail.module')
      .then(m => m.ChefdetailModule)
  },
  {
    path: 'chefdetail',
    loadChildren: () => import('./allpages/chefdetail/chefdetail.module')
      .then(m => m.ChefdetailModule)
  },
  // {
  //   path: 'blog',
  //   loadChildren: () => import('./allpages/blog/blog.module')
  //     .then(m => m.BlogModule)
  // },
  {
    path: 'customer-profile',
    loadChildren: () => import('./allpages/customer-profile/customer-profile.module')
      .then(m => m.CustomerProfileModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./allpages/privacy-policy/privacy-policy.module')
      .then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./allpages/contact-us/contact.module')
      .then(m => m.ContactUsModule)
  },
  {
    path: 'event-detail',
    loadChildren: () => import('./allpages/event-detail/event-detail.module')
      .then(m => m.EventDetailModule)
  },
  {
    path: 'CheckoutComponent',
    loadChildren: () => import('./allpages/checkout/checkout.module')
      .then(m => m.CheckoutModule)
  },
  {
    path: 'holi-promotion',
    loadChildren: () => import('./allpages/promotion-page/promotion-page.module')
      .then(m => m.PromotionPageModule)
  },
  { path: 'zappetite', loadChildren: () => import('./allpages/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: 'thank-you', loadChildren: () => import('./allpages/thank-you-page/thank-you-page.module').then(m => m.ThankYouPageModule) },
  { path: 'order-detail', loadChildren: () => import('./allpages/order-detail/order-detail.module').then(m => m.OrderDetailModule) },
  { path: 'chefdetail/:chef_id/:Location', loadChildren: () => import('./allpages/chefdetaillocation/chefdetaillocation.module').then(m => m.ChefdetaillocationModule) },
  { path: 'chefdetail/:chef_id/:Location/:menu_id', loadChildren: () => import('./allpages/chefdetaillocation/chefdetaillocation.module').then(m => m.ChefdetaillocationModule) },
  
 
  // { path: 'chefdetail/:chef_id/:Gurgaon', loadChildren: () => import('./allpages/chefdetaillocation/chefdetaillocation.module').then(m => m.ChefdetaillocationModule) },
  // { path: 'chefdetail/:chef_id/:Bangalore', loadChildren: () => import('./allpages/chefdetaillocation/chefdetaillocation.module').then(m => m.ChefdetaillocationModule) },
  // { path: 'chefdetails', loadChildren: () => import('./allpages/chefdetaillocation/chefdetaillocation.module').then(m => m.ChefdetaillocationModule) },
 
 
  // { path: 'searchSsr', loadChildren: () => import('./allpages/search-ssr/search-ssr.module').then(m => m.SearchSsrModule) },
  // { path: '**', component: HomeComponent },
  // { path: 'event-detail/:id', component: EventDetailComponent },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {useHash: true})],
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
