import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-social-media-popup',
  templateUrl: './social-media-popup.component.html',
  styleUrls: ['./social-media-popup.component.scss']
})
export class SocialMediaPopupComponent implements OnInit {
location;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
// console.log(window.location.href,"Deepak");
this.location = window.location.href
  }

  close() {
    this.activeModal.close({
      confirm:false
    });
  }

  confirmed() {
    this.activeModal.close({
      confirm:true
    });
  }
}