<div class="modal-body">

  <div class="">
    <div class="row w-100 d-flex">
      <h5 class="ml-3 font-weight-bolder">WRITE A REVIEW</h5>
      <button (click)="close()" aria-label="Close" class="close ml-auto" style="outline: none; margin: -10px;" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div class="form-group">
    <label style="margin: -10px auto auto auto;">Give Overall Rating</label>
    <ngb-rating [(rate)]="currentRate" [max]="5" style="font-size: 25px; margin: 10px 10px 0px 10px;"></ngb-rating>
  </div>
  <div class="form-group">
    <label>Remark</label>
    <input [(ngModel)]="remark" class="form-control" placeholder="Enter remark" type="text">
  </div>
  <div class="form-group">
    <label>Review</label>
    <textarea [(ngModel)]="review" class="form-control" placeholder="Enter review" type="text"></textarea>
  </div>
  <div class="d-flex">
    <p class="my_checkbox" style="">
      <label><input type="checkbox" [(ngModel)]="anonymous" /> Anonymous User</label>
    </p>
  </div>


</div>
<div class="modal-footer">
  <button (click)="saveRating()" class="btn btn-primary font-weight-bolder" type="button">Save</button>
</div>
