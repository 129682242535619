<div class="modal-header bg-white p-1" style="">
  <div class="row w-100">
    <!-- <h6 class="pl-4 pt-2 font-weight-bolder">{{header}}</h6> -->
  </div>
  <button (click)="closeModal()" aria-label="Close" class="close" style="outline: none;" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body profiletutor p-0 text-dark" tabindex="0">
  <div class="background" style="">
    <div class="row">
      <div class="col-12 ml-auto align-content-center">
        <div class="card">
          <div class="card-body">
            <div>
              <input #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
                     [(ngModel)]="profile_location" class="form-control input"
                     ngx-google-places-autocomplete placeholder="Search Location" type="text"/>
            </div>

            <div #gmap
                 style="width:100%;height:100%;min-height:300px;margin-top: 15px; background-color: aliceblue; border-radius: 10px;"></div>
            <br>
            <br>

            <div class="d-flex justify-content-center">
              <button (click)="saveLocation()" class="btn btn-primary">Save</button> &nbsp; &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
