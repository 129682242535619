import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhoneverficationComponent } from '../phoneverfication/phoneverfication.component';
import { APIService } from "../../../services/api.service";
import { UtilService } from "../../../services/util.service";
import { TandcComponent } from '../../tandc/tandc.component';
import { ToastrService } from "ngx-toastr";
import { CouponPageComponent } from '../../../modals/coupon-page/coupon-page.component';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  imchef: boolean = true;
  imcustomer: boolean = false;
  @Input() type;
  @Input() orignFrom;

  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  password = '';

  terms = false;

  validFirstName = true;
  validFirstNameText = '';
  validLastName = true;
  validLastNameText = '';
  validEmail = true;
  validEmailText = '';
  validPassword = true;
  validPasswordText = '';
  validPhone = true;
  validPhoneText = '';
  validTerms = true;
  validTermsText = '';
  validRegion = true;
  validRegionText= ''
city;
  disableSignup = false;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private apiService: APIService,
    private utilService: UtilService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit(): void {
    if (this.orignFrom == 'customer') {
      this.openimcustomer()
    } else {
      this.openimchef()
    }
    // this.checkFirstTimeDiscountPopup();
  }
  selectedState(){
    console.log(this.city,"city");
}
  openimchef() {
    this.imchef = true;
    this.imcustomer = false;
    this.type = 'chef';
  }

  openimcustomer() {
    this.imchef = false;
    this.imcustomer = true;
    this.type = 'customer';
  }

  validateChef() {
    let isValid = true;
    if (this.firstName == '') {
      isValid = false;
      this.validFirstName = false;
      this.validFirstNameText = 'Please enter first name';
    } else {
      this.validFirstName = true;
    }
    if (this.lastName == '') {
      isValid = false;
      this.validLastName = false;
      this.validLastNameText = 'Please enter last name';
    } else {
      this.validLastName = true;
    }
    if (this.type != 'customer') {
      if (this.email == '') {
        isValid = false;
        this.validEmail = false;
        this.validEmailText = 'Please enter email';
      } else {
        this.validEmail = true;
      }
      if (!this.utilService.validateEmail(this.email)) {
        isValid = false;
        this.validEmail = false;
        this.validEmailText = 'Please enter valid email address';
      } else {
        this.validEmail = true;
      }
  }else{
    this.validEmail = true;
  }
  if (this.type != 'customer') {
    if (this.password == '') {
      isValid = false;
      this.validPassword = false;
      this.validPasswordText = 'Password must be greater than 8 characters and have a special charcter (*&%@!#)';
    } else {
      this.validPassword = true;
    }
    if (this.password.toString().length < 8) {
      isValid = false;
      this.validPassword = false;
      this.validPasswordText = 'Password must be greater than 8 characters and have a special charcter (*&%@!#)';
    } 
  }
  else {
      this.validPassword = true;
    }
    if (this.phone == '') {
      isValid = false;
      this.validPhone = false;
      this.validPhoneText = 'Please enter phone number';
    } else {
      this.validPhone = true;
    }
    if (this.type != 'customer') {
      console.log(this.type,"chef");
      
    if (!this.city) {
      isValid = false;
      this.validRegion = false;
      this.validRegionText = 'Please select region';
    } else {
      this.validRegion = true;
    }
  }else {
    console.log(this.type,"customer");
    this.validRegion = true;
  }
    // console.log("length:-"+this.phone.toString().length);
    if (this.phone.toString().length == 12) {
      // this.phone=this.phone.toString().replace("91","");
      this.phone = this.phone.toString().substring(2);
    }
    // console.log("Phone:-" + this.phone);

    if (this.phone.toString().length != 10) {
      isValid = false;
      this.validPhone = false;
      this.validPhoneText = 'Please enter correct phone number';
    } else {
      this.validPhone = true;
    }
    if (!this.terms) {
      isValid = false;
      this.validTerms = false;
      this.validTermsText = 'Please select terms and conditions';
    } else {
      this.validTerms = true;
    }

    return isValid;
  }

  singupUser() {

    this.disableSignup = true;
    if (!this.validateChef()) {
      this.disableSignup = false;
      return;
    }
    let userType = "CUSTOMER";
    if (this.type != 'customer') {
      userType = "CHEF";
    }

    this.email = this.email.toLowerCase()

    let postData = {
      type: userType,
      email: this.email,
      phone: this.phone
    }

    this.apiService.postAPI(this.apiService.BASE_URL + "user/getUserByEmailPhone", postData).then((result) => {
      if (result.status) {
        if(this.type == 'customer'){
          if (result.result.phoneUser != null) {
            this.disableSignup = false;
            this.toaster.error("Phone number already exist");
            return
          }
        }else{
        if (result.result.emailUser != null) {
          this.disableSignup = false;
          this.toaster.error("Email already exist");
          return
        }
        if (result.result.phoneUser != null) {
          this.disableSignup = false;
          this.toaster.error("Phone number already exist");
          return
        }
      }
        // this.signupCognito()
        this.signupAPI()
      } else {
        this.disableSignup = false;
        this.toaster.error(result.message);
      }
    }, (error) => {
      this.disableSignup = false;
      console.log("something went wrong");
    })
  }

  signupCognito() {
    if (this.type == 'customer') {
      this.apiService.signUp(this.firstName + " " + this.lastName, this.email.toLowerCase(), this.password, this.phone).then((result) => {
        // console.log('result:-' + JSON.stringify(result));
        // this.utilService.setItem(this.utilService.COGNITO_PROFILE, JSON.stringify(result));
        this.signupAPI();
      }, (error) => {
        // console.log('error:-' + JSON.stringify(error));
        alert(error.message);
      });
    } else {
      this.apiService.chefSignUp(this.firstName + " " + this.lastName, this.email.toLowerCase(), this.password, this.phone).then((result) => {
        // console.log('result:-' + JSON.stringify(result));
        // this.utilService.setItem(this.utilService.COGNITO_PROFILE, JSON.stringify(result));
        this.signupAPI();
      }, (error) => {
        // console.log('error:-' + JSON.stringify(error));
        alert(error.message);
      });
    }
  }
  openCoupon() {
    const modalRef = this.modalService.open(CouponPageComponent, { size: '', windowClass: 'mobileClass', centered: true });
    modalRef.dismissed.subscribe((result) => {
      console.log("dismissed")
    })
  }
  signupAPI() {
    if (this.type == 'customer') {
      this.validRegion = true;
      this.validEmail = true;
      this.validPassword =true;
      let postData = {
        "name": this.firstName + " " + this.lastName,
        "password": this.phone,
        "phone": this.phone,
        "type": '0'
      }
      this.apiService.postAPI(this.apiService.BASE_URL + "user/register", postData).then((result) => {
        if (result.status) {
          // this.utilService.setItem(this.utilService.USER_LOGIN, '1');
          //CUSTOMER,CHEF,ADMIN
          this.utilService.setItem(this.utilService.SIGNUP_USER_TYPE, "CUSTOMER");
          this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(result.result.user));
          this.openPhoneVerificationModal()
          // this.openDiscountPopup();
          // this.activeModal.close(SignupComponent);
          // this.activeModal.close(AuthmodalComponent);
        } else {
          alert(result.message);
        }
      }, (error) => {
        console.log("something went wrong");
      })
    } else {
      let postData = {
        "user_name": this.firstName + " " + this.lastName,
        "user_email": this.email,
        "user_password": this.password,
        "user_phone": this.phone,
        "user_minimum_order_value": '0',
        "region":this.city
      }
      this.apiService.postAPI(this.apiService.BASE_URL + "user/updateChefProfile", postData).then((result) => {
        if (result.status) {
          this.utilService.setItem(this.utilService.SIGNUP_USER_TYPE, "CHEF");
          this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(result.result.profile));
          this.openPhoneVerificationModal()
          // this.openDiscountPopup();
          // alert('your account has been created! please wait for confirmation from admin to login into portal.');
          // this.activeModal.close(SignupComponent);
          // this.activeModal.close(AuthmodalComponent);
        } else {
          alert(result.message);
          this.disableSignup = false;
        }
      }, (error) => {
        this.disableSignup = false;
        console.log("something went wrong");
      })
    }
  }

  openPhoneVerificationModal() {
    const modalRef = this.modalService.open(PhoneverficationComponent, { size: '' });
    modalRef.componentInstance.reset_password = "0";

    this.activeModal.close();
  }
handleClose(){
  if(localStorage.getItem('hasSeenDiscountPopup') == 'true'){
    this.activeModal.close();
    localStorage.setItem('hasSeenDiscountPopup', 'false');
  }else {
    this.openDiscountPopup();
  }
  
  // this.checkFirstTimeDiscountPopup();
}
  openTerms() {
    const modalRef = this.modalService.open(TandcComponent, { size: 'xl' });
    modalRef.componentInstance.name = 'World';
  }
  checkFirstTimeDiscountPopup(): void {
    const hasSeenDiscount = localStorage.getItem('hasSeenDiscountPopup');
    if (!hasSeenDiscount) {
      this.openDiscountPopup();
      // this.openDiscountPopup()
  // this.activeModal.close();
    }
    // this.activeModal.close();
  }

  openDiscountPopup(): void {
    const modalRef = this.modalService.open(CouponPageComponent, { size: '', windowClass: 'mobileClass', centered: true });
    modalRef.closed.subscribe(() => {
      localStorage.setItem('hasSeenDiscountPopup', 'true');
    });
  }
  openprivacypolicy() {
    this.activeModal.close(SignupComponent)
    this.router.navigate(['/privacy-policy'])
  }
}
