import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from "../../../services/util.service";
import {APIService} from "../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {EmitEvent, Events, EventService} from "../../../services/event.service";
import {Router} from "@angular/router";
import {InfomationComponent} from "../../../modals/infomation/infomation.component";
import {ChefInfoModalComponent} from "../../../modals/chef-info-modal/chef-info-modal.component";

@Component({
  selector: 'app-phoneverfication',
  templateUrl: './phoneverfication.component.html',
  styleUrls: ['./phoneverfication.component.scss']
})
export class PhoneverficationComponent implements OnInit {

  otp;
  resend_disabled = true;
  @Input() reset_password='0';

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private apiService: APIService,
    public utilService: UtilService,
    private eventService: EventService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.sendOTPTodevice();
  }

  timeLeft: number = 120000;
  interval;

  setResendEnableTimer() {
    this.timeLeft = 120000;
    this.interval = setInterval(() => {
      // console.log('timeleft:-' + this.timeLeft);
      if (this.timeLeft > 0) {
        this.timeLeft = this.timeLeft - 1000;
        this.resend_disabled = true;
      } else {
        this.clearTimer();
        this.resend_disabled = false;
      }
    }, 1000);
  }

  clearTimer() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  }

  sendOTPTodevice() {
    console.log("user Profile:-" + JSON.stringify(this.utilService.getUserProfile()));
    let profile = this.utilService.getUserProfile();
    if (profile.phone != '') {

      let userType = "";
      // if (this.utilService.getItem(this.utilService.SIGNUP_USER_TYPE) == 'CUSTOMER') {
      //   userType = 'customer';
      // } else {
      //   userType = 'chef';
      // }
      if (this.utilService.getUserProfile().type==0) {
        userType = 'customer';
      } else {
        userType = 'chef';
      }

      this.apiService.postAPI(this.apiService.BASE_URL + "user/generateOTP", {
        id: this.utilService.getUserID(),
        type: userType,
        reset_password:this.reset_password
      }).then((result) => {
        if (result.status) {
          this.toaster.success("OTP sent to phone");
          this.setResendEnableTimer();
        }
      }, (error) => {
        console.log("something went wrong");
      })
    }
  }

  submitOTP() {
    // console.log("submit otp");
    if (this.otp.toString().length == 0) {
      this.toaster.error("please enter otp");
      return;
    }

    let userType = "";
    // if (this.utilService.getItem(this.utilService.SIGNUP_USER_TYPE) == 'CUSTOMER') {
    //   userType = 'customer';
    // } else {
    //   userType = 'chef';
    // }

    if (this.utilService.getUserProfile().type==0) {
      userType = 'customer';
    } else {
      userType = 'chef';
    }

    this.apiService.postAPI(this.apiService.BASE_URL + "user/verifyOTP", {
      id: this.utilService.getUserID(),
      type: userType,
      otp: this.otp
    }).then((result) => {
      if (result.status) {
        if(userType=='customer'){
          this.utilService.setItem(this.utilService.USER_LOGIN, '1');
          this.eventService.emit(new EmitEvent(Events.USER_SIGNUP, ''));
          this.modalService.dismissAll()
        }else{
          const modalRef = this.modalService.open(ChefInfoModalComponent, {size: ''});
          modalRef.closed.subscribe((result) => {
            this.modalService.dismissAll()
          })
          // modalRef.dismissed.subscribe((result) => {
          //   this.modalService.dismissAll()
          // })
        }

      } else {
        this.toaster.error(result.message);
      }
    }, (error) => {
      console.log("something went wrong");
    })

  }

  closeModal() {
    this.activeModal.close({
      status: false
    })
  }
}
