<!-- <p>signup works!</p> -->
<div class="modal-header p-1 pr-3 pt-2" style="border-bottom: none; margin-bottom: -10px;">
  <!-- <h4 class="modal-title">Hi there!</h4> -->
  <button (click)="handleClose()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-4 pt-0">
  <h6 class="mt-0 pt-0 font-weight-bolder">Welcome! We would love to know more about you.</h6>
  <p class="text-primary cursorpointer" (click)="activeModal.dismiss('Cross click')">Back to Login</p>


  <div *ngIf="orignFrom=='customer'" class="d-flex p-0 m-0">
    <button (click)="openimcustomer()" [ngClass]="{'active': imcustomer}"
      class="btn tabs_btn btn-block rounded-0 text-uppercase">I’m a customer
    </button>

    <button (click)="openimchef()" [ngClass]="{'active': imchef}"
      class="btn tabs_btn btn-block rounded-0 text-uppercase">I’m a chef
    </button>
  </div>

  <div class="mt-1">
    <label>First Name</label>
    <input [(ngModel)]="firstName" class="form-control" placeholder="Enter your first name" required type="text">
    <div style="text-align: left">
      <p *ngIf="!validFirstName" class="error-label">{{validFirstNameText}}</p>
    </div>

    <label>Last Name</label>
    <input [(ngModel)]="lastName" class="form-control" placeholder="Enter your Last name" required type="text">
    <div style="text-align: left">
      <p *ngIf="!validLastName" class="error-label">{{validLastNameText}}</p>
    </div>
   <div *ngIf="type=='chef'"> 
    <label>Email</label>
    <input [(ngModel)]="email" class="form-control" placeholder="Enter your Email" required type="text">
    <div style="text-align: left">
      <p *ngIf="!validEmail" class="error-label">{{validEmailText}}</p>
    </div>
  </div>
  <label>Phone number</label>
    <input [(ngModel)]="phone" class="form-control" placeholder="Enter your Phone number" required type="number">
    <div style="text-align: left">
      <p *ngIf="!validPhone" class="error-label">{{validPhoneText}}</p>
    </div>
    <div *ngIf="type=='chef'">
    <label>Password</label>
    <input [(ngModel)]="password" class="form-control" placeholder="Enter your Password" required type="password">
    <div style="text-align: left">
      <p *ngIf="!validPassword" class="error-label">{{validPasswordText}}</p>
    </div>
  </div>
    <div *ngIf="type=='chef'" style="margin: 12px 0 6px 0px;">
    <label>Select Region</label>
    <select name="Please select" class="form-control" id="" [(ngModel)]="city" (change)="selectedState()" style="cursor: pointer; color: #495057b8;border: 2px solid #B8B8B8;">
      <option value="Delhi">Delhi/NCR</option>
      <option value="Bangalore">Bangalore</option>
     </select>
    <div style="text-align: left">
      <p *ngIf="!validRegion" class="error-label">{{validRegionText}}</p>
    </div>
  </div>
  </div>

  <div *ngIf="false">
    <p *ngIf="type!='chef'" class="text-center">or</p>

    <button *ngIf="type!='chef'" class="btn btn-block" style="border: solid 1px black; font-size: 13px;">
      <i aria-hidden="true" class="fa fa-google"></i> &nbsp;Sign up with Google
    </button>
    <button *ngIf="type!='chef'" class="btn btn-block" style="border: solid 1px black; font-size: 13px;">
      <i aria-hidden="true" class="fa fa-facebook"></i> &nbsp;Sign up with Facebook
    </button>
  </div>

  <br>

  <p class="my_checkbox">
    <label><input [(ngModel)]="terms" type="checkbox" /><span (click)="terms=true">By signing up you agree to
        platform’s</span>
      <span class="text-primary" style="cursor: pointer;"> <span (click)="openTerms()"> Terms of Service </span> <span
          style="color: grey;">and </span> <span (click)="openprivacypolicy()">Privacy Policy</span> </span></label>
  </p>

  <!-- <p style="font-size: 12px;"><input [(ngModel)]="terms" style="margin: 5px;" type="checkbox">
    <span (click)="terms=true">By signing up you agree to platform’s</span>
    <span class="text-primary" style="cursor: pointer;"> <span (click)="openTerms()"> Terms of Service </span> <span
        style="color: grey;">and </span> <span (click)="openprivacypolicy()">Privacy Policy</span> </span>
  </p> -->
  <div style="text-align: left">
    <p *ngIf="!validTerms" class="error-label">{{validTermsText}}</p>
  </div>

  <br>
  <br>

  <button (click)="singupUser()" [disabled]="disableSignup" *ngIf="type=='chef'"
    class="btn btn-primary font-weight-bolder btn-block text-uppercase">
    Become a chef
  </button>
  <button (click)="singupUser()" [disabled]="disableSignup" *ngIf="type=='customer'"
    class="btn btn-primary font-weight-bolder btn-block text-uppercase">
    Signup
  </button>

</div>