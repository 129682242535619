<div class="card m-0">
  <!-- <div class="card-header">
    <span style="float: right;" class="ft-x font-medium-5" (click)="close()"></span>
    <h4 class="card-title">Confirmation</h4>
  </div> -->
  <button (click)="close()" class="close" data-dismiss="modal" style="margin: 10px; position: absolute; top: 0%; right: 0%;" type="button">&times;
  </button>
  <div class="card-content">
    <div class="card-body">
    <h4 class="modal-title font-weight-bolder">{{title}}</h4>

    <br>

      <p style="font-size: 15px;" class="card-title">{{message}}</p>
      <div class="">
        <br>
        <div style="text-align: center;display: flex;">
          <button type="button" class="btn btn-danger btn-raised mr-1" (click)="confirmed()">YES</button>
          <button type="button" class="btn btn-primary btn-raised mr-1" (click)="close()">NO</button>
        </div>
      </div>
    </div>
  </div>
</div>
