import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cuisineFilter',
  pure: false
})
export class CuisineFilterPipe implements PipeTransform {

  transform(items: any, element:string, value:any) {
    if(items != null && items != undefined && items.length > 0 && value != undefined && value.length > 0)
    {
      if(items != undefined )
      {
        if (value.length > 0) 
        {
          return this.filterCusines(items, value, element);
        }
        else
        {
          return items;
        }
      }
    }
    else
    {
      return items;
    }
    return items;
  }

  filterCusines(mainItems, cuisineitems, element)
  {
    let finalItems = [];
    mainItems.filter((mainItem:any) => {
      mainItem[element].split(',').forEach(cuisine => {
        if(cuisineitems.filter(x => cuisine.indexOf(x) != -1).length > 0)
        {
          finalItems.push(mainItem);
        }         
      });
    });
    return finalItems.filter(function(item, index) { return finalItems.indexOf(item) >= index;});
  }

}
