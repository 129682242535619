<div class="card m-0">
    <div class="card-header">
      <span style="float: right;" class="ft-x font-medium-5 cursorpointer" (click)="close()"><i class="fa fa-close"></i></span>
      <h4 class="card-title">Share Options</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <!-- <p style="font-size: 15px;" class="card-title"></p> -->
        <div class="social-div">  
            <a class="text-dec" href="https://www.facebook.com/sharer/sharer.php?u={{location}}" target="_blank" title="Facebook"><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
            
            <a class="text-dec" href="https://www.linkedin.com/shareArticle?mini=true&amp;url={{location}}" target="_blank" title="LinkedIn"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
            
            <a class="text-dec" href="mailto:?subject=Sent%20from%20Website&amp;body={{location}}" target="_blank" title="Mail"><i class="fa fa-envelope" aria-hidden="true"></i></a>
            
            <a class="text-dec" href="https://twitter.com/intent/tweet?text={{location}}" target="_blank" title="Twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a>
            
            <a class="text-dec"  href="https://api.whatsapp.com/send?text={{location}}" target="_blank" title="Whatsapp"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                        
</div>
<!-- <div class="social-menu">
    <ul>
        <li><a class="text-dec" href="https://www.facebook.com/sharer/sharer.php?u={{location}}" target="_blank" title="Facebook"><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
        <li><a class="text-dec" href="https://www.linkedin.com/shareArticle?mini=true&amp;url={{location}}" target="_blank" title="LinkedIn"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
        <li><a class="text-dec" href="mailto:?subject=Sent%20from%20Website&amp;body={{location}}" target="_blank" title="Mail"><i class="fa fa-envelope" aria-hidden="true"></i></a></li>
        <li><a class="text-dec" href="https://twitter.com/intent/tweet?text={{location}}" target="_blank" title="Twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
        <li><a class="text-dec"  href="https://api.whatsapp.com/send?text={{location}}" target="_blank" title="Whatsapp"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
    </ul>
</div> -->
        <!-- <div class="">
          <br>
          <div style="text-align: center;display: flex;">
            <button type="button" class="btn btn-danger btn-raised mr-1" (click)="confirmed()">OK</button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  