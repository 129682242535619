import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-chef-info-modal',
  templateUrl: './chef-info-modal.component.html',
  styleUrls: ['./chef-info-modal.component.scss']
})
export class ChefInfoModalComponent implements OnInit {


  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close({
      confirm:false
    });
  }

  confirmed() {
    this.activeModal.close({
      confirm:true
    });
  }
}
