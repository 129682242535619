import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ForgotPasswordComponent } from '../../../modals/forgot-password/forgot-password.component';
import { GoogleLoginComponent } from '../google-login/google-login.component';
@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    NgbModule,
    // BrowserModule
  ],
  declarations: [LoginComponent, ForgotPasswordComponent,GoogleLoginComponent],
  exports:[GoogleLoginComponent]
})
export class LoginModule { }