import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'foodFilter'
})
export class FoodFilterPipe implements PipeTransform {

  transform(items: any, element:string, value:any, filtertype : string) {
    if(items != null && items != undefined && items.length > 0 && value != undefined)
    {
      var tempItems = [];
      items.forEach(y => {
        if(y.item.filter(x => x[element].toUpperCase() == value).length > 0)
        {
          tempItems.push(y);
        }
      });
      return tempItems;
    }
    else
    {
      return items;
    }
    return items;
  }

}
