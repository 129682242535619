import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {APIService} from "../../services/api.service";
import {UtilService} from "../../services/util.service";
import {EventService} from "../../services/event.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  otp;
  resend_disabled = true;
  userProfile;

  submitted = false;
  phone;
  validPhone = true;
  validPhoneText = "";

  verified = false;

  new_password = '';
  confirm_password = '';

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private apiService: APIService,
    public utilService: UtilService,
    private eventService: EventService,
    private toaster: ToastrService
  ) {
  }

  ngOnInit(): void {

  }

  submitPhone() {
    if (this.phone == undefined || this.phone.toString().length != 10) {
      this.toaster.error('Please enter valid phone');
      return;
    }
    this.apiService.postAPI(this.apiService.BASE_URL + 'user/generateResetPassword', {
      phone: this.phone,
      type: 'customer'
    }).then((result) => {
      if (result.status) {
        this.toaster.success('OTP sent to phone');
        this.submitted = true;
        this.userProfile = result.result;
        this.setResendEnableTimer();
      } else {
        this.toaster.error(result.message);
      }
    }, (error) => {
      // console.log('something went wrong');
    })
  }

  timeLeft: number = 120000;
  interval;

  setResendEnableTimer() {
    this.timeLeft = 120000;
    this.interval = setInterval(() => {
      // console.log('timeleft:-' + this.timeLeft);
      if (this.timeLeft > 0) {
        this.timeLeft = this.timeLeft - 1000;
        this.resend_disabled = true;
      } else {
        this.clearTimer();
        this.resend_disabled = false;
      }
    }, 1000);
  }

  clearTimer() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  }

  submitOTP() {
    if (this.otp == undefined || this.otp.toString().length == 0) {
      this.toaster.error("please enter otp");
      return;
    }

    let userType = "customer";

    this.apiService.postAPI(this.apiService.BASE_URL + "user/verifyResetOTP", {
      id: this.userProfile.id,
      type: userType,
      otp: this.otp
    }).then((result) => {
      if (result.status) {
        this.verified = true;
      } else {
        this.toaster.error(result.message);
      }
    }, (error) => {
      // console.log("something went wrong");
    })
  }

  resetPassword() {
    if (this.new_password.length==0) {
      this.toaster.error("please enter new password");
      return;
    }
    if (this.new_password.length<8) {
      this.toaster.error("password must be 8 or more characters");
      return;
    }
    if (this.confirm_password.length==0) {
      this.toaster.error("please enter confirm password");
      return;
    }
    if (this.confirm_password.length<8) {
      this.toaster.error("password must be 8 or more characters");
      return;
    }

    if(this.new_password.toString()!=this.confirm_password.toString()){
      this.toaster.error("Please enter same new and confirm password");
      return;
    }

    let userType = "customer";

    this.apiService.postAPI(this.apiService.BASE_URL + "user/resetPassword", {
      id: this.userProfile.id,
      type: userType,
      password: this.new_password
    }).then((result) => {
      if (result.status) {
        this.toaster.success("Your password reset successfully! Please login to continue")
        this.activeModal.close();
      } else {
        this.toaster.error(result.message);
      }
    }, (error) => {
      // console.log("something went wrong");
    })
  }

  closeModal() {
    this.activeModal.close({
      status: false
    })
  }
}
