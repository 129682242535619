import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-infomation',
  templateUrl: './infomation.component.html',
  styleUrls: ['./infomation.component.scss']
})
export class InfomationComponent implements OnInit {

  @Input() message;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close({
      confirm:false
    });
  }

  confirmed() {
    this.activeModal.close({
      confirm:true
    });
  }
}
