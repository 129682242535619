import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFilter'
})
export class PriceFilterPipe implements PipeTransform {

  transform(items: any, element:string, value:any) {
    if(items != null && items != undefined && items.length > 0)
    {
      if(items != undefined )
      {
        if (value > 0) 
        {
          return items.filter(x => x[element] >= value)
        }
        else
        {
          return items;
        }
      }
    }
    else
    {
      return items;
    }
    return items;
  }
}
