<div class="card m-0">
  <div class="card-header">
    <span style="float: right;" class="ft-x font-medium-5" (click)="close()"></span>
    <h4 class="card-title">We The Chefs</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <p style="font-size: 15px;" class="card-title">{{message}}</p>
      <div class="">
        <br>
        <div style="text-align: center;display: flex;">
          <button type="button" class="btn btn-danger btn-raised mr-1" (click)="confirmed()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>
