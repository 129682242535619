<!-- <p>cart works!</p> -->
<div class="cart-popup" style="background-color: #F6F6F7;;">
  <div class="modal-header d-flex">
    <h3
      class="text-primary cursorpointer"
      style="font-family: Jakob; font-weight: 600; font-size: 20px; position: absolute;">We The Chefs</h3>
    <button (click)="dismiss()" aria-label="Close" class="close" type="button"><span aria-hidden="true">&times;</span>
    </button>

  </div>

  <br>
  <!-- Modal body -->
  <div class="modal-body p-0" style="max-height: 80vh; overflow-y: auto;">
    <div class="col-md-12 cart">
      <div class="title">
        <div class="row" style="margin-top:-20px;">
          <p *ngIf="cartItems.length == 0">Your cart is empty. Please browse our menus and plan a food experience to
            proceed.</p>
          <div class="col-auto">
            <h5><b><i class="fa fa-shopping-basket pr-2"></i>Cart</b></h5>
          </div>
          <div class="col align-self-center text-right"><b><h5><span
            *ngIf="cartItems.length !== 0">{{cart_quantity}}</span> DISHES</h5></b></div>
        </div>
      </div>
      <div *ngFor="let item of cartItems;let i=index" class="row bg-white" style="margin-bottom:10px;">
        <div class="row main align-items-center">

          <div class="col">
            <div *ngIf="utilService.isUserLoggedIn()&&item.itemDetail!=null" class="row heading-top">{{item.itemDetail.name}}</div>
            <div *ngIf="utilService.isUserLoggedIn()&&item.eventDetail!=null"
                 class="row heading-top">{{item.eventDetail.name}}</div>
            <div *ngIf="!utilService.isUserLoggedIn()" class="row heading-top">{{item.name}}</div>
            <div class="row heading-bot">
              <!--              <pre>{{item.qty}} {{item.quantity_type}} <span-->
              <!--                *ngIf="item.calories!=''&&item.calories!='0'">- {{item.calories}}kl</span></pre>-->
              <!--              <pre>{{item.qty}} {{item.quantity_type}}</pre>-->
            </div>
          </div>
          <div class="col">
            <div class="row">
              <!--              <span class="close"><a href="#">&#10005;</a></span>-->
            </div>
            <div class="row float-right plus-minus"><a (click)="remove(item)">-</a><a
              class="border">{{item.quantity}}</a><a
              (click)="addItem(item)">+</a></div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-auto">
          <h5>TOTAL</h5>
        </div>
        <div class="col align-self-center text-right text-muted"><h1><i class="fa fa-inr"
                                                                        style="font-size: 20px;"></i> {{totalPrice}}
        </h1></div>
      </div>

      <div class="row">
        <div class="col-auto m-0 p-0">
          <button (click)="clearCart()" class="btn btn-outline-primary">Clear Cart</button>
        </div>
        <div class="col align-self-center text-right m-0 p-0">
          <button (click)="checkout()" class="btn btn-primary text-white">CHECKOUT</button>
        </div>
      </div>


    </div>
  </div>

</div>
