import { NgModule } from '@angular/core';
import { DeliveryFilterPipe } from './filters/delivery-filter.pipe';
import { FoodFilterPipe } from './filters/food-filter.pipe';
import { UniqueItemPipe } from './filters/unique-item.pipe';
import { CuisineFilterPipe } from './filters/cuisine-filter.pipe';
import { ServingFilterPipe } from './filters/serving-filter.pipe';
import { OrderByPipe } from './filters/order-by.pipe';
import { RatingFilterPipe } from './filters/rating-filter.pipe';
import { PriceFilterPipe } from './filters/price-filter.pipe';
import { DietFilterPipe } from './filters/diet-filter.pipe';
import { MealFilterPipe } from './filters/meal-filter.pipe';

@NgModule({
  declarations: [
    DeliveryFilterPipe,
    FoodFilterPipe,
    UniqueItemPipe,
    CuisineFilterPipe,
    ServingFilterPipe,
    OrderByPipe,
    RatingFilterPipe,
    PriceFilterPipe,
    DietFilterPipe,
    MealFilterPipe
    ],
    imports : [],
    exports: [
      DeliveryFilterPipe,
      FoodFilterPipe,
      UniqueItemPipe,
      CuisineFilterPipe,
      ServingFilterPipe,
      OrderByPipe,
      RatingFilterPipe,
      PriceFilterPipe,
      DietFilterPipe,
      MealFilterPipe
    ],
  providers: []
})
export class SharedModule {}