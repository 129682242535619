import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor() { }
  private invoiceNumber: string;

  setInvoiceNumber(invoiceNumber: string) {
    // this.invoiceNumber = invoiceNumber;
    localStorage.setItem('invoice_number', invoiceNumber);
  }

  getInvoiceNumber() {
    return localStorage.getItem('invoice_number');
  }
}
