<!-- <p>cart works!</p> -->
<div class="cart-popup" style="background-color: #F6F6F7;;">
  <div class="modal-header d-flex">
    <h3
      class="text-primary cursorpointer"
      routerLink="/home" style="font-family: Jakob; font-weight: 600; font-size: 20px; position: absolute;">We The Chefs</h3>
    <button (click)="dismiss()" aria-label="Close" class="close" type="button"><span aria-hidden="true">&times;</span>
    </button>

  </div>

  <br>
  <!-- Modal body -->
  <div *ngIf="orderDetail!=null" class="modal-body p-0">
    <div class="col-md-12 cart">
      <div class="title">
        <div class="row p-0 m-0" style="margin-top:-20px;">
          <div class="col-auto p-0">
            <h5><b><i class="fa fa-shopping-basket pr-2"></i>Order Detail</b></h5>
          </div>
          <div class="col align-self-center text-right"><b>
            <h5>{{cart_quantity}} DISHES</h5>
          </b></div>
        </div>
      </div>
      <div class="title">
        <div class="row" style="">
          <div class="col-6 p-0 pl-2 text-left">
            <p><b><img src="assets/images/chef_default_icon.png"
                       style="width: 15px;height: 15px;padding-right: 5px;">{{orderDetail.chef_detail.name}}</b></p>
          </div>
          <div class="col-6 text-right"><b>
            <p>{{utilService.getOrderDateTimeByTime(orderDetail.delivery_at)}}</p>
          </b></div>
        </div>
      </div>
      <div *ngFor="let item of orderDetail.orderItems" class="row bg-white" style="margin-bottom:10px;">
        <div class="row main align-items-center">

          <div class="col">
            <div class="row heading-top">{{item.name}}</div>
            <div class="row heading-bot">
<!--              <pre>{{item.qty}} {{item.quantity_type}}</pre>-->
              <!--              <pre>{{item.qty}} {{item.quantity_type}} <span *ngIf="item.calories!=0">- {{item.calories}}kl</span></pre>-->
            </div>
          </div>
          <div class="col">
            <!--            <div class="row"></div>-->
            <div class="row float-right plus-minus"><a class="border" href="#">{{item.quantity}}</a></div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-auto">
          <h6>ITEM TOTAL</h6>
        </div>
        <div class="col-auto ml-auto align-self-end text-right">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{orderDetail.base_price.toFixed(2)}}</p></h6>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <h6>DELIVERY CHARGES</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{orderDetail.delivery_charges.toFixed(2)}}</p></h6>
        </div>
      </div>

      <div class="row">
        <div class="col-auto">
          <h6>GST ON DELIVERY</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{orderDetail.delivery_service_tax.toFixed(2)}}</p></h6>
        </div>
      </div>
      <div *ngIf="!splitGST" class="row">
        <div class="col-auto">
          <h6>IGST</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{orderDetail.tax.toFixed(2)}}</p></h6>
        </div>
      </div>
      <div *ngIf="splitGST" class="row">
        <div class="col-auto">
          <h6>SGST</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{(orderDetail.tax / 2).toFixed(2)}}</p></h6>
        </div>
      </div>
      <div *ngIf="splitGST" class="row">
        <div class="col-auto">
          <h6>CGST</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{(orderDetail.tax / 2).toFixed(2)}}</p></h6>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <h6>TOTAL</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{orderDetail.total_price.toFixed(2)}}</p></h6>
        </div>
      </div>

      <div class="row">
        <div class="col">

        </div>
        <div class="col align-self-center text-right">
          <button (click)="dismiss()" class="btn btn-primary text-white">CLOSE</button>
        </div>
      </div>


    </div>
  </div>
  <div *ngIf="eventDetail!=null" class="modal-body p-0">
    <div class="col-md-12 cart">
      <div class="title">
        <div class="row p-0 m-0" style="margin-top:-20px;">
          <div class="col-auto p-0">
            <h5><b><i class="fa fa-shopping-basket pr-2"></i>Order Detail</b></h5>
          </div>
          <div class="col align-self-center text-right"><b>
            <h5>{{cart_quantity}} DISHES</h5>
          </b></div>
        </div>
      </div>
      <div class="title">
        <div class="row" style="">
          <div class="col-6 p-0 pl-2 text-left">
            <p><b><img src="assets/images/chef_default_icon.png"
                       style="width: 15px;height: 15px;padding-right: 5px;">{{eventDetail.chef_location.name}}</b></p>
          </div>
          <div class="col-6 text-right"><b>
            <p>{{utilService.getDateTimeByTime(eventDetail.delivery_at)}}</p>
          </b></div>
        </div>
      </div>
      <div class="row bg-white" style="margin-bottom:10px;">
        <div class="row main align-items-center">

          <div class="col">
            <div class="row heading-top">{{eventDetail.chef_location.name}}</div>
            <div class="row heading-bot">
<!--              <pre>{{item.qty}} {{item.quantity_type}}</pre>-->
              <!--              <pre>{{item.qty}} {{item.quantity_type}} <span *ngIf="item.calories!=0">- {{item.calories}}kl</span></pre>-->
            </div>
          </div>
          <div class="col">
            <!--            <div class="row"></div>-->
            <div class="row float-right plus-minus"><a class="border" href="#">{{cart_quantity}}</a></div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-auto">
          <h6>ITEM TOTAL</h6>
        </div>
        <div class="col-auto ml-auto align-self-end text-right">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{eventDetail.base_price.toFixed(2)}}</p></h6>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <h6>DELIVERY CHARGES</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{eventDetail.delivery_charges.toFixed(2)}}</p></h6>
        </div>
      </div>

      <div class="row">
        <div class="col-auto">
          <h6>GST ON DELIVERY</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{eventDetail.delivery_service_tax.toFixed(2)}}</p></h6>
        </div>
      </div>
      <div *ngIf="!splitGST" class="row">
        <div class="col-auto">
          <h6>IGST</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{eventDetail.tax.toFixed(2)}}</p></h6>
        </div>
      </div>
      <div *ngIf="splitGST" class="row">
        <div class="col-auto">
          <h6>SGST</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{(eventDetail.tax / 2).toFixed(2)}}</p></h6>
        </div>
      </div>
      <div *ngIf="splitGST" class="row">
        <div class="col-auto">
          <h6>CGST</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{(eventDetail.tax / 2).toFixed(2)}}</p></h6>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <h6>TOTAL</h6>
        </div>
        <div class="col-auto ml-auto align-self-center text-right text-muted">
          <h6 class="d-flex"><i class="fa fa-inr" style="width: fit-content; margin-top: 3px; font-size: 13px;"></i> <p style="width: 60px; text-align: end; color: black;"> {{eventDetail.total_price.toFixed(2)}}</p></h6>
        </div>
      </div>

      <div class="row">
        <div class="col">

        </div>
        <div class="col align-self-center text-right">
          <button (click)="dismiss()" class="btn btn-primary text-white">CLOSE</button>
        </div>
      </div>


    </div>
  </div>

</div>
